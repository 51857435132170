import React, { useEffect, useLayoutEffect, useState, useCallback } from 'react'
import { StyleSheet, View, Image, Dimensions, TouchableOpacity, ImageBackground } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { Text, Button } from 'app/src/components/basics'
import { styles as basicStyles, ThankYouImageFunc } from 'app/src/components/advanced/activist/basic'

type Props = {
  selectedMenu: number
  text: string
  selected: (v: number) => void
}

export const SelectedMenu: React.FC<Props> = props => {
  return (
    <TouchableOpacity onPress={() => props.selected(props.selectedMenu)}>
      <LinearGradient style={basicStyles.selectedMenu} colors={['#2CA4DE', '#96C843']} start={[0.0, 0.0]} end={[1.0, 0]}>
        <Text type={'large'} style={basicStyles.myPageHovered}>
          {props.text}
        </Text>
      </LinearGradient>
    </TouchableOpacity>
  )
}
