import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { StyleSheet, View, Image, Dimensions, TouchableOpacity, ImageBackground, Linking } from 'react-native'
import { Text, Button } from 'app/src/components/basics'
import * as ImagePicker from 'expo-image-picker'
import { Card, Title, Paragraph } from 'react-native-paper'
import axios from 'axios'
import { Hoverable, Pressable } from 'react-native-web-hover'
import { styles as basicStyles, ThankYouImageFunc } from 'app/src/components/advanced/activist/basic'
import { TextInput } from 'app/src/components/basics/text-input'
import { db } from 'app/src/libs/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import Autocomplete from 'react-native-autocomplete-input';
import { bankArray } from "app/src/components/advanced/banksArray"
import { Formik } from 'formik';
import * as Yup from "yup";




const { width, height } = Dimensions.get('window')

interface Props {
  activistId: string
  bankCode: string
  stripeAccountId: string
  bankName: string
  branchName: string
  bankBranchCode: string
  bankAccountNumber: number
  bankAccountType: string
  setBankCode: (bankCode: string) => void
  setBankBranchCode: (bankBranchCode: string) => void
  setBankAccountNumber: (bankAccountNumber: number) => void
  setBankAccountType: (bankAccountType: string) => void
  setBankName: (v: string) => void
  setBranchName: (v: string) => void

}

export const BankAccountCard = forwardRef((props: Props, ref) => {



  const [bankNames, setBankNames] = useState<string[]>([]);

  const [bankCodes, setBankCodes] = useState<string[]>([]);
  const [filteredBankCodes, setFilteredBankCodes] = useState([]);


  const [branchNames, setBranchNames] = useState<string[]>([])
  const [branchCodes, setBranchCodes] = useState<string[]>([])
  const [filterdBranchCodes, setFilterdBranchCodes] = useState([])


  const isBankName = (val) => {




    if (bankCodes[bankNames.indexOf(val)]) {


      return true



    } else {


      return false
    }









  }

  const isBranchName = (val) => {




    if (branchNames[branchNames.indexOf(val)]) {


      return true



    } else {


      return false
    }









  }


  const validation = () =>
    Yup.object().shape({
      // email: Yup.string()
      //   .email("メールアドレスの形式で入力してください")
      //   .required("必須項目です"),
      bankName: Yup.string()
        .required("必須項目です")
        .test("isBankName", "お取り扱いのない銀行です。", val => isBankName(val))
      ,
      branchName: Yup.string()
        .required("必須項目です")
        .test("isBranchName", "お取り扱いのない支店です。", val => isBranchName(val))
      ,

      bankAccountNumber: Yup.string()
        .required("必須項目です"),

    });






  useEffect(() => {
    let bn: string[] = []
    for (let i = 0; i < bankArray.length; i++) {
      bn.push(bankArray[i]["正式銀行名"])
    }
    const newArr = Array.from(new Set(bn));
    setBankNames(newArr)
    let bc: string[] = []

    for (let i = 0; i < bankArray.length; i++) {
      bc.push(('0000' + bankArray[i]["銀行コード"]).slice(-4))
    }

    const newArr2 = Array.from(new Set(bc));
    setBankCodes(newArr2)


  }, []);





  const goToStripe = async (id: string) => {
    const url = `https://dashboard.stripe.com/${id}/dashboard`

    const supported = await Linking.canOpenURL(url)

    if (supported) {
      Linking.openURL(url)
    }
  }

  const saveBankInfo = async () => {





    const result = await save()

    if (result != false) {


      alert('内容を保存しました。')





    }


  }



  const save = async () => {




    const ref = doc(db, 'activists', props.activistId)


    if (!props.bankCode || !props.bankBranchCode) {


      const bankResult = bankCode(props.bankName)

      if (bankResult == false) {

        alert("申し訳ございません。そちらはお取り扱いのない銀行です。")

        return false;

      }


      const branchResult = branchCode(props.branchName)

      if (!branchResult) {


        alert("申し訳ございません。そちらはお取り扱いのない支店です。")

        return false


      }



    } else {

      await updateDoc(ref, {
        bankName: props.bankName,
        bankBranchName: props.branchName,
        bankAccountNumber: Number(props.bankAccountNumber),
        bankAccountType: props.bankAccountType,
        bankBranchCode: props.bankBranchCode,
        bankCode: props.bankCode,
      })


    }






  }









  const bankCode = (item) => {


    const text = bankCodes[bankNames.indexOf(item)]
    props.setBankName(item)
    props.setBankCode(text)

    var brArray = bankArray.filter(function (bank) {

      return bank["正式銀行名"] === bankNames[bankNames.indexOf(item)]
    });



    if (brArray.length) {


      let brn: string[] = []
      let brc: string[] = []
      for (let i = 0; i < brArray.length; i++) {
        brn.push(bankArray[i]["支店名"])
        brc.push(bankArray[i]["支店コード"])
      }
      setBranchNames(brn)
      setBranchCodes(brc)
      setFilteredBankCodes([]);

    } else {



      return false;



    }

  }


  const branchCode = (item) => {




    const text: string = branchCodes[branchNames.indexOf(item)]

    if (text) {

      props.setBranchName(item)

      props.setBankBranchCode(('000' + text).slice(-3))
      setFilterdBranchCodes([])

    } else {


      return false


    }




  }




  return (
    <Formik
      initialValues={{
        branchName: props.branchName,
        bankName: props.bankName,
        bankAccountNumber: props.bankAccountNumber
      }}
      enableReinitialize={true}
      validationSchema={validation()}
      onSubmit={() => saveBankInfo()}
    >
      {({ setFieldValue, handleChange, handleBlur, handleSubmit, values, errors }) => (
        <View style={basicStyles.myPageCard}>

          <View style={styles.mypageItems}>
            {/* test */}


            <View>

              <View>






                <View style={styles.cardContainer}>
                  <Text style={basicStyles.cardTitle}>支援金送金先</Text>

                </View>
                <Text style={basicStyles.explanation}>Shopifyや他社サイトで発生した支援は銀行振込となりますので、送金先をご指定ください</Text>
              </View>

              <View style={basicStyles.sectionContainer}>
                <TextInput
                  label={'金融機関名'}
                  // onChangeText={findBank}
                  onChangeText={async (e: any) => {
                    props.setBankName(e)

                    if (e) {

                      var result = bankNames.filter((s: any) => s.includes(e))

                      setFilteredBankCodes(result)


                    } else {
                      // If the query is null then return blank
                      setFilteredBankCodes([])
                    }


                    setFieldValue("bankName", e)

                    props.setBranchName("")
                    setFieldValue("branchName", "")
                    props.setBranchName("")
                    props.setBankBranchCode("")







                  }}
                  onBlur={handleBlur('bankName')}
                  error={errors.bankName ? errors : ""}
                  value={props.bankName}
                  multiple={7}
                  code={bankCode}
                  filtered={filteredBankCodes} type={'bankName'} />
              </View>




              <View style={basicStyles.sectionContainer}>

                <TextInput
                  label={'支店名'}
                  // onChangeText={findBranch}

                  onChangeText={async (e: any) => {


                    props.setBranchName(e)
                    if (e) {

                      var result = branchNames.filter((s: any) => s.includes(e));

                      setFilterdBranchCodes(result)
                    } else {
                      setFilterdBranchCodes([]);
                    }

                    setFieldValue("branchName", e)







                  }}
                  onBlur={handleBlur('branchName')}


                  value={props.branchName}
                  multiple={7}
                  code={branchCode}
                  filtered={filterdBranchCodes}
                  error={errors.branchName ? errors : ""}
                  type={"branchName"}




                />
              </View>

              <View>
                <View style={styles.bankList}>
                  <View style={styles.checkBoxContainer}>
                    <View style={styles.inputContainer}>
                      {props.bankAccountType === '普通' ? (
                        <Pressable>
                          {({ hovered }) => (
                            <TouchableOpacity
                              style={hovered ? styles.hovered : styles.notHovered}
                              onPress={() => props.setBankAccountType('普通')}
                            >
                              <View style={styles.selectedCheck}></View>
                            </TouchableOpacity>
                          )}
                        </Pressable>
                      ) : (
                        <Pressable>
                          {({ hovered }) => (
                            <TouchableOpacity
                              style={hovered ? styles.hovered : styles.notHovered}
                              onPress={() => props.setBankAccountType('普通')}
                            ></TouchableOpacity>
                          )}
                        </Pressable>
                      )}
                    </View>

                    <View>
                      <Text style={styles.bankAccountTypeText}>普通</Text>
                    </View>
                  </View>

                  <View style={styles.checkBoxContainer}>
                    <View style={styles.inputContainer}>
                      {props.bankAccountType === '当座' ? (
                        <Pressable>
                          {({ hovered }) => (
                            <TouchableOpacity
                              style={hovered ? styles.hovered : styles.notHovered}
                              onPress={() => props.setBankAccountType('当座')}
                            >
                              <View style={styles.selectedCheck}></View>
                            </TouchableOpacity>
                          )}
                        </Pressable>
                      ) : (
                        <Pressable>
                          {({ hovered }) => (
                            <TouchableOpacity
                              style={hovered ? styles.hovered : styles.notHovered}
                              onPress={() => props.setBankAccountType('当座')}
                            ></TouchableOpacity>
                          )}
                        </Pressable>
                      )}
                    </View>
                    <View>
                      <Text style={styles.bankAccountTypeText}>当座</Text>
                    </View>
                  </View>

                  <View style={styles.checkBoxContainer}>
                    <View style={styles.inputContainer}>
                      {props.bankAccountType === '定期' ? (
                        <Pressable>
                          {({ hovered }) => (
                            <TouchableOpacity
                              style={hovered ? styles.hovered : styles.notHovered}
                              onPress={() => props.setBankAccountType('定期')}
                            >
                              <View style={styles.selectedCheck}></View>
                            </TouchableOpacity>
                          )}
                        </Pressable>
                      ) : (
                        <Pressable>
                          {({ hovered }) => (
                            <TouchableOpacity
                              style={hovered ? styles.hovered : styles.notHovered}
                              onPress={() => props.setBankAccountType('定期')}
                            ></TouchableOpacity>
                          )}
                        </Pressable>
                      )}
                    </View>

                    <View>
                      <Text style={styles.bankAccountTypeText}>定期</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={basicStyles.sectionContainer}>
                <TextInput
                  multiple={2}
                  label={'口座番号'}
                  // onChangeText={v => {
                  //   props.setBankAccountNumber(isNaN(v) ? props.bankAccountNumber : v)
                  // }}
                  // value={props.bankAccountNumber}
                  onChangeText={async (e: any) => {

                    var reg = new RegExp(/^[0-9]*$/);
                    var res = reg.test(e);

                    if(res){



                      props.setBankAccountNumber(e)
                      props.setBankAccountNumber(isNaN(e) ? props.bankAccountNumber : e)



                      setFieldValue("bankAccountNumber", e)


                    }









                  }}
                  error={errors.bankAccountNumber ? errors : ""}
                  onBlur={handleBlur('bankAccountNumber')}
                  value={String(values.bankAccountNumber)}
                  type={'bankAccountNumber'} />
              </View>
            </View>
            <View style={styles.line}></View>
            <View>

              <View>
                <View style={styles.cardContainer}>
                  <Text style={basicStyles.cardTitle}>Stripeアカウント</Text>

                </View>
                <Text style={basicStyles.explanation}>
                  meguのサービスサイト内で発生した支援はStripe経由で送金されますので、アカウントを最新の状態に保ってください
                </Text>
              </View>

              <Pressable>
                {({ hovered }) => (
                  <Button buttonType={hovered ? 3 : 1} style={styles.goToStripeBtn} onPress={() => goToStripe(props.stripeAccountId)}>
                    Stripeに行く
                  </Button>
                )}
              </Pressable>
            </View>
          </View>
          <Pressable>
            {({ hovered }) => (
              <Button buttonType={hovered ? 3 : 1} style={basicStyles.saveBtn} onPress={handleSubmit}>
                保存する
              </Button>
            )}
          </Pressable>
        </View>

      )}
    </Formik>
  )
})

const styles = StyleSheet.create({
  isAutoStyle: {

    backgroundColor: "red"
  },
  isNotAutoStyle: {

    backgroundColor: "blue"
  },
  container: {
    flexDirection: 'row',
    backgroundColor: '#F4F4F4',
    paddingBottom: height * 0.2,
  },
  titleContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    width: '95%',
  },
  bankList: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 18,
  },
  saveBtn: {
    right: 0,
    position: 'absolute',
    bottom: 0,
  },
  bell: {
    height: width * 0.02,
    width: height * 0.03,
  },
  rightIcon: {
    flexDirection: 'row',
    marginEnd: width * 0.1,
  },
  mypageContent: {
    width: width * 0.85,
    height: 600,
    paddingRight: 50,
    backgroundColor: 'white',
  },
  mypageItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
  },
  announceLogo: {
    width: width * 0.2,
  },
  headerItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerFourItems: {
    flexDirection: 'row',
  },
  selectedMenu: {
    backgroundColor: 'white',
    padding: 50,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notSelectedMenu: {
    padding: 50,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    position: 'absolute',
    left: -(width * 0.147),
    right: 0,
    top: 0,
    bottom: 0,
    height: 3,
    marginTop: 31,
    width: width / 10,
  },

  logout: {
    marginTop: height * 0.07,
  },
  backgroundImageContent: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  backgroundImage: {
    width: '110%',
    height: '100%',
  },
  logoContainer: {
    backgroundColor: '#DDDDDD',
    width: '30%',
    height: '30%',
  },
  itemText: {
    fontSize: 150,
    margin: 2
  },
  bankButton: {
    textAlign: 'center',
    border: '1px solid #DDDDDD;',
    borderRadius: 50,
    padding: 10,
    margin: 5,
    fontSize: 12,
  },
  hoveredBankButton: {
    textAlign: 'center',
    border: '1px solid #999999;',
    borderRadius: 50,
    padding: 10,
    margin: 5,
    fontSize: 12,
  },
  accountTypeButton: {
    textAlign: 'center',
    border: '1px solid #DDDDDD;',
    borderRadius: 50,
    padding: 10,
    margin: 5,
    width: 90,
  },
  cardContainer: {

    marginBottom: 14,
  },
  selectedText: {
    maxWidth: 450,
  },
  sectionTitle: {
    marginLeft: 30,
  },
  line: {
    width: 1,
    height: 400,
    backgroundColor: '#ddd',
    marginLeft: 30,



    marginRight: 30,
  },
  goToStripeText: {
    width: 400,
  },
  goToStripeBtn: {
    marginRight: 50,
    marginTop: 25,
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputContainer: {
    marginRight: 10,
  },
  bankAccountTypeText: {
    marginRight: 25,
  },
  inputStyle: {
    borderColor: '#DDDDDD',
    borderWidth: 1,
    borderRadius: 50,
  },
  selectedCheck: {
    width: 12,
    height: 12,
    backgroundColor: '#046FB8',
    borderRadius: 50,
    margin: 'auto',
  },
  textField: {
    marginLeft: 30,
    borderWidth: 1,
    width: 283,
    height: 40,
    borderColor: '#ddd',
    marginTop: 6,
  },
  notHovered: {
    borderColor: '#DDDDDD',
    borderWidth: 1,
    borderRadius: 50,
    width: 20,
    height: 20,
  },
  hovered: {
    borderColor: '#0470B9',
    borderWidth: 1,
    borderRadius: 50,
    width: 20,
    height: 20,
  },
})
