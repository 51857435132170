import React, { useState, useEffect } from 'react'
import { StyleSheet, View, Dimensions } from 'react-native'
import { BasicInfoCard } from '../../../advanced/activist/basicInfo/InfoCard'
import { CreateStripeAccountCard } from '../../../advanced/activist/basicInfo/CreateStripeAccountCard'
import { CompleteApply } from 'app/src/components/advanced/activist/basicInfo/CompleteApply'
import { goToHome } from 'app/src/libs/screen-transition'
import { useNavigation } from 'app/src/hooks/use-navigation'
import { fetchActivistInfo, auth } from 'app/src/libs/firebase'
import { onAuthStateChanged, applyActionCode } from 'firebase/auth'
import Progress from 'app/src/components/advanced/activist/basicInfo/Progress'

type ContainerProps = {}

const {  height } = Dimensions.get('window')

export const ActivistbasicInfoScreen: React.FC<ContainerProps> = () => {
  const [activistId, setActivistId] = useState<string>('')
  const [imageUrl, setImageUrl] = useState<string>('')
  const [activistName, setActivistName] = useState<string>('')
  const [postalCode, setPostalCode] = useState<string>('')
  const [town, setTown] = useState<string>('')
  const [building, setBuilding] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [kakuin, setKakuin] = useState<string>('')
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [stripeAccountId, setStripeAccountId] = useState('')
  const [isSetUp, setIsSetUp] = useState(false)
  const [establishmentDate, setEstablishmentDate] = useState<string>('')
  const [representative, setRepresentative] = useState<string>('')
  const [activistNameFurigana, setActivistNameFurigana] = useState<string>('')
  const [proofImage,setProofImage] = useState<string>('')
  const [email,setEmail] = useState<string>('')
  const [activistNumber,setActivistNumber] = useState<string>('')
  const [activistAdmittedDate, setActivistAdmittedDate] = useState<string>('')
  const [proviso,setProviso] = useState<string>('')


  const navigation = useNavigation()

  useEffect(() => {
    const setup = async () => {
      onAuthStateChanged(auth, async activist => {
        if (activist) {
          const selectedActivistId = localStorage.getItem("selectedActivistId")
          const id = selectedActivistId?selectedActivistId:activist.uid
            const info = await fetchActivistInfo(id)

            setIsSetUp(info?.isSetUp)

            setStripeAccountId(info?.stripeAccountId)
            setActivistId(id)



            if (window.document) {


              if (typeof window !== 'undefined') {





                const searchParams = new URLSearchParams(window.location.search)

                const oobCode = searchParams.get("oobCode")
                if (oobCode) {


                  await applyActionCode(auth, oobCode)





                }


              }



            }





        } else {
          goToHome(navigation)
        }
      })
    }

    setup()
  }, [])

  return (
    <View>
      <View style={styles.container}>

        {(() => {
          if (!isSetUp) {
            return (
              <View>
                <Progress status={1} />
                <BasicInfoCard
                  activistId={activistId}
                  imageUrl={imageUrl}
                  activistName={activistName}
                  postalCode={postalCode}
                  town={town}
                  phoneNumber={phoneNumber}
                  kakuin={kakuin}
                  setKakuin={setKakuin}
                  setImageUrl={setImageUrl}
                  setActivistName={setActivistName}
                  setPostalCode={setPostalCode}
                  setTown={setTown}
                  setBuilding={setBuilding}
                  building={building}
                  setPhoneNumber={setPhoneNumber}
                  isApproved={isApproved}
                  stripeAccountId={''}
                  title={'支援金受付開始申請'}
                  isSetUp={isSetUp}
                  setIsSetUp={setIsSetUp}
                  establishmentDate={establishmentDate}
                  representative={representative}
                  setEstablishmentDate={setEstablishmentDate}
                  setRepresentative={setRepresentative}
                  setActivistNameFurigana={setActivistNameFurigana}
                  activistNameFurigana={activistNameFurigana}
                  proofImage={proofImage}
                  setProofImage={setProofImage}
                  email={email}
                  setEmail={setEmail}
                  originalUid={activistId}
                  setActivistId={setActivistId}
                  activistAdmittedDate={activistAdmittedDate}
                  setActivistAdmittedDate={setActivistAdmittedDate}
                  activistNumber={activistNumber}
                  setActivistNumber={setActivistNumber}
                  proviso={proviso}
                  setProviso={setProviso}
                  relatedActivists={[]}
                  setRelatedActivists={function (v: { label: string; value: string }[]): void {
                    throw new Error('Function not implemented.')
                  } }

                  />


              </View>

            )
          } else {
            if (stripeAccountId) {
              return <View>
                <Progress status={3} />

                <CompleteApply activistId={activistId} /></View>
            } else {
              return <View>
                <Progress status={2} />

                <CreateStripeAccountCard /></View>
            }
          }
        })()}
        {/* <Footer /> */}

      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
  },
  container: {
    top: height * 0.1,
    margin: 'auto',
  },
})
