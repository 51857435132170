import React, { useState, useEffect, useCallback } from 'react'
import { StyleSheet, View, Image, TouchableOpacity, Dimensions, ActivityIndicator } from 'react-native'
import moment from 'moment'
import { images } from 'app/src/libs/images'
import { Text } from 'app/src/components/basics/text'
import { Hoverable } from 'react-native-web-hover'
import { goToActivistDeposit, goToActivistMypage, goToBasicInfo, goToHome } from 'app/src/libs/screen-transition'
import { useNavigation } from 'app/src/hooks/use-navigation'

import { ActivistMenu } from 'app/src/components/advanced/activist/menu'
import {  fetchActivistInfo, fetchDeposit, fetchDonationInfo, fetchTotalDonators, getDownload } from 'app/src/libs/firebase'
import { Button } from 'app/src/components/basics'
import { ActivistHeaderMenu } from 'app/src/components/advanced/activist/headerMenu'
import { styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import Svg, { Circle, Path } from 'react-native-svg'
import { db, auth } from 'app/src/libs/firebase'
import {
  doc,
  collection,
  getDoc,
  query,
  orderBy,
  getDocs,
  where,
  limit,
} from 'firebase/firestore'
import { primaryColor } from 'app/src/constants/colors'


import { onAuthStateChanged } from 'firebase/auth'
import { Loader } from 'app/src/components/basics/loader'
import styled from "styled-components";

const ArrowStyledSVG = styled.svg`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.color}; /* defines the background color of the image */
  mask: url(${({ theme }) => theme.image}) no-repeat center / contain;
  -webkit-mask: url((${({ theme }) => theme.image}) no-repeat center / contain;

`;




type ContainerProps = {}

const { width, height } = Dimensions.get('window')


export const DonatedScreen: React.FC<ContainerProps> = () => {

  const [activistInfo, setActivistInfo] = useState<any>()
  const [activistId, setActivistId] = useState<string>('')
  const [totalDeposit, setTotalDeposit] = useState<number>(0)
  const [donationsList, setDonationsList] = useState<any>([])
  const [meguDeposit, setMeguDeposit] = useState<number>(0)
  const [otherDeposit, setOtherDeposit] = useState<number>(0)
  const [shopifyDeposit, setShopifyDeposit] = useState<number>(0)
  const [thisMonthTotalDonation, setThisMonthTotalDonation] = useState<number>(0)
  const [totalDonators, setTotalDonators] = useState<number>(0)
  const [startDate, setStartDate] = useState<number>(0)
  const [lastDate, setLastDate] = useState<number>(0)
  const [value, setValue] = useState<number>(0)
  const [date, setDate] = useState<any>(new Date())
  const [show, setShow] = useState<boolean>(false)
  const [fullname, setFullname] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [donatorId, setDonatorId] = useState<string>('')
  const [donatorNickName, setDonatorNickName] = useState<string>('')
  const [donatorImageUrl, setDonatorImageUrl] = useState<string>('')
  const [isPopup, setIsPopup] = useState<boolean>(false)
  const d = new Date()
  const navigation = useNavigation()
  const [yearMonth, setYearMonth] = useState<string>(`${d.getFullYear()}年${d.getMonth() + 1}月`)
  const [lastTime, setLastTime] = useState<number>(0)
  const [amountSortType, setAmountSortType] = useState<number | null>(null)
  const [createdAtSortType, setCreatedAtSortType] = useState<number | null>(null)
  const [donationSortType, setDonationSortType] = useState<number | null>(null)
  const [size, setSize] = useState<number>(0)
  const [imageUrl, setImageUrl] = useState<string>('')
  const [isRest, setIsRest] = useState<boolean>(true)
  const [totalSize, setTotalSize] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isCsvLoading,setIsCsvLoading] = useState<boolean>(false)
  const [year,setYear] = useState<string>(`${date.getFullYear()}`)
  const [month,setMonth] = useState<string>(`${date.getMonth()+1}`)
  const [meguGolfDeposit,setMeguGolfDeposit] = useState<number>(0)

  async function _fetchActivistInfo(activistId: string) {
    const activistInfo = await fetchActivistInfo(activistId)
    if (activistInfo?.isSetUp) {
      setActivistInfo(activistInfo)
      setImageUrl(activistInfo.imageUrl)
    } else {
      goToBasicInfo(navigation)
    }
  }

  const alertIsRequested = () => {
    setIsPopup(false)
    localStorage.removeItem('isRequested')
  }

  const openModal = async (id: string, createdAt) => {
    const date = new Date(createdAt)

    const ref = doc(db, 'activists', activistId, 'donated', `${date.getFullYear()}${date.getMonth() + 1}`, 'd', id)
    const result = await getDoc(ref)
    const data = result.data()



    const uid = data?.uid

    const userRef = doc(db, 'users', uid)

    const userResult = await getDoc(userRef)
    const userData = userResult.data()



    setDonatorId(uid)

    let fullName = userData?.lastName + userData?.firstName
    setFullname(fullName)
    setAddress(userData?.prefecture + userData?.town)
    setPhoneNumber(userData?.phoneNumber)
    setDonatorNickName(userData?.uniqueId)

    setDonatorImageUrl(userData?.imageUrl)


    setShow(true)
  }

  const onIncrement = useCallback(() => {
    setValue(prev => changeMonth(prev + 1))
  }, [])
  const onDecrement = useCallback(async () => {
    setValue(prev => changeMonth(prev - 1))
  }, [])

  async function changeMonth(value: number) {

    setCreatedAtSortType(null)
    setAmountSortType(null)
    setDonationSortType(null)

    setIsLoading(true)
    const uid = String(auth.currentUser?.uid)


    const year = String(new Date(moment(date).add(value, 'M')).getFullYear())
    const month = String(new Date(moment(date).add('M', value)).getMonth() + 1)

    setYear(year)
    setMonth(month)

    setValue(value)
    const text = year + '年' + month + '月'

    setDate(new Date(moment(date).add(value, 'M')))
    const s = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0).getTime()
    setStartDate(s)
    const lastMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 0, 0)
    const e = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, lastMonth.getDate(), 23, 59, 59).getTime()

    console.log('e', e)

    setLastDate(e)
    setYearMonth(text)

    const yearAndMonth = year + month

    const depositResult = await fetchDeposit(uid, yearAndMonth)

    setTotalDeposit(depositResult.totalDeposit)

    setMeguDeposit(depositResult.meguDeposit)
    setMeguGolfDeposit(depositResult.meguGolfDeposit)
    setThisMonthTotalDonation(depositResult.thisMonthTotalDonation)
    const totalDonatorsResult = await fetchTotalDonators(uid, yearAndMonth)

    setTotalDonators(totalDonatorsResult)

    const donationResult = await fetchDonationInfo(
      uid,
      String(new Date(moment(date).add(value, 'M')).getFullYear()),
      String(new Date(moment(date).add('M', value)).getMonth() + 1),
      1,
    )


    setSize(donationResult.donationsList.length)

    setLastTime(donationResult.lastTime)
    setTotalSize(donationResult.totalSize)
    setDonationsList(donationResult.donationsList)

    if (donationResult.donationsList.length) {
      setIsRest(true)
    }

    setIsLoading(false)

  }

  async function _fetchDonationInfo(activistId: string) {
    const start = new Date(d.getFullYear(), d.getMonth(), 1, 0, 0, 0).getTime()
    const end = new Date(d.getFullYear(), d.getMonth() + 1, 1, 0, 0).getTime()

    const yearAndMonth = String(d.getFullYear()) + String(d.getMonth() + 1)

    const depositResult = await fetchDeposit(activistId, yearAndMonth)

    setTotalDeposit(depositResult.totalDeposit)
    setMeguDeposit(depositResult.meguDeposit)
    setMeguGolfDeposit(depositResult.meguGolfDeposit)
    setThisMonthTotalDonation(depositResult.thisMonthTotalDonation)

    const totalDonatorsResult = await fetchTotalDonators(activistId, yearAndMonth)
    setTotalDonators(totalDonatorsResult)

    const donationResult = await fetchDonationInfo(activistId, String(d.getFullYear()), String(d.getMonth() + 1), start, end, 1)

    setSize(donationResult.donationsList.length)
    setLastTime(donationResult.lastTime)
    setActivistInfo(activistInfo)
    setTotalSize(donationResult.totalSize)
    setDonationsList(donationResult.donationsList)
  }

  const goToPDF = async (uid: string, downloadedTimestamp: number) => {



    const userRef = doc(db, 'donations', uid,"issued",`${year}${month}`,"activists",activistId)

    const docSnap = await getDoc(userRef)

    const data = docSnap.data()
    if(!data) return

    const url = data.url

    window.open(url, '_blank')
  }

  const getMoreDonations = async () => {
    const rawLastDate = new Date(date.getFullYear(), date.getMonth(), 0)
    const lastDate = new Date(rawLastDate.getFullYear(), rawLastDate.getMonth(), rawLastDate.getDate(), 23, 59, 59).getTime()
    const moreRef = collection(db, 'activists', activistId, 'donated', `${date.getFullYear()}${date.getMonth() + 1}`, 'd')
    const sResult = await getDocs(
      query(moreRef, where('createdAt', '>=', lastDate), where('createdAt', '<', lastTime), orderBy('createdAt', 'desc')),
    )

    let s = sResult.size

    if (s > 0) {
      let list: any = [...donationsList]

      const ref = await getDocs(
        query(moreRef, where('createdAt', '>=', lastDate), where('createdAt', '<', lastTime), orderBy('createdAt', 'desc'), limit(10)),
      )

      const donationsDocs = ref.docs
      for (let i = 0; i < donationsDocs.length; i++) {
        let data = donationsDocs[i].data()

        const userRef = doc(db, 'users', data.uid)

        const result = await getDoc(userRef)
        if (result.exists()) {
          const userData = result.data()
          const imageUrl = userData?.imageUrl
          const nickname = userData?.uniqueId

          data['nickname'] = nickname
          data['imageUrl'] = imageUrl
        } else {
          data['nickname'] = ''
          data['imageUrl'] = ''
        }

        data['id'] = donationsDocs[i].id

        list.push(data)

        if (i === donationsDocs.length - 1) {
          setLastTime(data.createdAt)
        }
      }

      setDonationsList(list)
    } else {
      setIsRest(false)
    }
  }

  const sortType = async () => {

    setAmountSortType(null)
    setCreatedAtSortType(null)
    if (donationSortType) {
      let array = [
        ...donationsList.sort(function (a, b) {
          return a.createdAt == b.type ? 0 : a.type < b.type ? 1 : -1

        }),
      ]
      setDonationsList(array)
      setDonationSortType(0)
    } else {
      let array = [
        ...donationsList.sort(function (a, b) {
          // return a.createdAt == b.type ? 0 : a.type < b.type ? 1 : -1
          return a.type == b.type ? 0 : a.type > b.type ? 1 : -1
        }),
      ]
      setDonationsList(array)
      setDonationSortType(1)
    }




  }



  const sortCreatedAt = async () => {

    setAmountSortType(null)
    setDonationSortType(null)
    if (createdAtSortType) {
      let array = [
        ...donationsList.sort(function (a, b) {
          return a.createdAt == b.createdAt ? 0 : a.createdAt < b.createdAt ? 1 : -1

        }),
      ]
      setDonationsList(array)
      setCreatedAtSortType(0)
    } else {
      let array = [
        ...donationsList.sort(function (a, b) {
          // return a.createdAt == b.createdAt ? 0 : a.createdAt < b.createdAt ? 1 : -1
          return a.createdAt == b.createdAt ? 0 : a.createdAt > b.createdAt ? 1 : -1
        }),
      ]
      setDonationsList(array)
      setCreatedAtSortType(1)
    }

  }

  const sortAmount = async () => {

    setCreatedAtSortType(null)
    setDonationSortType(null)


    if (amountSortType) {
      let array = [
        ...donationsList.sort(function (a, b) {
          return a.amount == b.amount ? 0 : a.amount < b.amount ? 1 : -1
          // return a.amount == b.amount ? 0 : a.amount > b.amount ? 1 : -1
        }),
      ]
      setDonationsList(array)
      setAmountSortType(0)
    } else {
      let array = [
        ...donationsList.sort(function (a, b) {

          return a.amount == b.amount ? 0 : a.amount > b.amount ? 1 : -1
        }),
      ]
      setDonationsList(array)
      setAmountSortType(1)
    }




  }

  const padding = (v)=>{


    return ('00' + (v)).slice(-2);

  }

  const onPressCsv = async()=>{

    setIsCsvLoading(true)

      //  //文字列型で二次元配列のデータ
       const data = [["支援者名","金額","寄付種別","日付","住所"]]
      const date = new Date()


        const monthDoc = await getDoc(doc(db, 'activists', activistId, 'donated', `${year}${month}`))

        if(monthDoc.exists()){

          const moreRef = collection(db, 'activists', activistId, 'donated', `${year}${month}`,"d")

          const ref = await getDocs(
            query(moreRef,orderBy('createdAt', 'desc')),
          )

      for(let i = 0;i<ref.docs.length;i++){

        const docSnap = ref.docs[i]
        const docData = docSnap.data()
        const uid = docData.uid
        const result = await getDoc(doc(db,"users",uid))
        const userData = result.data()

        if(!userData) return

        const firstName = userData.firstName
        const lastName  = userData.lastName
        const amount    = docData.amount
        const donationType = docData.type
        const createdAt = docData.createdAt
        const address = `${userData.prefecture?userData.prefecture:""}${userData.town?userData.town:""}${userData.building?userData.building:""}`
        const date = new Date(createdAt)
        const dateTitle = `${date.getFullYear()}/${padding(date.getMonth()+1)}/${padding(date.getDate())}`
        let name = lastName+firstName

        if(!name){
          name = "unknown"
        }
        data.push([name,amount,donationType,dateTitle,address])


      }


        }






      let csv_string  = "";
      for (let d of data) {

        csv_string += d.join(",");
        csv_string += '\r\n';
      }


      //ファイル名の指定

      const paddingMonthTitle = padding(date.getMonth()+1);
      const paddingDateTitle = padding(date.getDate())


      let file_name   = `${date.getFullYear()}-${paddingMonthTitle}-${paddingDateTitle}`

      //CSVのバイナリデータを作る
      const BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const csvBuffer = new TextEncoder().encode(csv_string);
      const blobData = [BOM, csvBuffer];
      const blob = new Blob(blobData, {type: 'text/csv;charset=utf-8;'});
      let uri         = URL.createObjectURL(blob);

      //リンクタグを作る
      let link        = document.createElement("a");
      link.download   = file_name;
      link.href       = uri;

      //作ったリンクタグをクリックさせる
      document.body.appendChild(link);
      link.click();

      //クリックしたら即リンクタグを消す
      document.body.removeChild(link);

      setIsCsvLoading(false)




  }





  useEffect(() => {
    const setup = async () => {
      const result = localStorage.getItem('isRequested')

      if (result) {
        setIsPopup(true)
        setTimeout(alertIsRequested, 10000)
      }

      onAuthStateChanged(auth, async activist => {
        if (activist) {

          const selectedActivistId = localStorage.getItem("selectedActivistId")
          const id = selectedActivistId?selectedActivistId:activist.uid
          setActivistId(id)

            _fetchActivistInfo(id)
            await _fetchDonationInfo(id)

            setIsLoading(false)

        } else {
          goToHome(navigation)
        }
      })
    }
    setup()
  }, [])


  const goToWithDraw = async (n: number) => {
    const ref = doc(db, 'activists', activistId)
    const result = await getDoc(ref)

    const data = result.data()
    if(!data){
      return
    }


    if(!data.stripeAccountId){

      alert('送金するためにはStripeでアカウントを作成する必要がありますので、お問合せください。')

      // localStorage.setItem("mypage", "3")

      // goToActivistMypage(navigation)

      return


    }

    if (
      data.activistName &&
      data.bankName &&
      data.bankCode &&
      data.bankBranchName &&
      data.bankBranchCode &&
      data.bankAccountNumber &&
      data.bankAccountType
    ) {
      if (n >= 10000) {
        goToActivistDeposit(navigation)
      }
    } else {
      alert('口座情報を入力してください')

      localStorage.setItem("mypage", "3")

      goToActivistMypage(navigation)
    }
  }

  return (
    <View>
      <View>
        <View style={basicStyles.container}>
          <View style={basicStyles.bodyContainer}>
            <ActivistMenu pageTitle={'donations'} />
            <View>
              <ActivistHeaderMenu activistId={activistId} imageUrl={imageUrl} title={'支援一覧'} />


              <View
                  style={{
                    position:"absolute",
                    right:400,
                    top:40,
                    alignItems:"center",
                    width:300,
                    zIndex:101

                  }}
                  >


                    {isCsvLoading?<ActivityIndicator  size="large" color={primaryColor} />:       <Button onPress={()=>onPressCsv()}>CSVをダウンロードする</Button>}


                  </View>

              <View style={basicStyles.scrollContainer}>
                <POP isPopup={isPopup} />





                <View style={basicStyles.cardContainer}>
                  <View style={basicStyles.myPageCard}>


                    <View>
                      <View style={styles.monthContainer}>
                        <View style={styles.monthTextCotainer}>
                          <Text style={basicStyles.cardTitle}>今月の支援金</Text>
                        </View>

                        <View style={styles.monthChangeContainer}>
                          <View>
                            <TouchableOpacity onPress={() => onDecrement()}>
                              <Image source={images['btnLeft']} style={styles.left} />
                            </TouchableOpacity>
                          </View>
                          <View>
                            <Text style={styles.yearMonthText}>{yearMonth}</Text>
                          </View>
                          {yearMonth === `${d.getFullYear()}年${d.getMonth() + 1}月` ? (
                            <span />
                          ) : (
                            <View>
                              <TouchableOpacity onPress={() => onIncrement()}>
                                <Image source={images['btnRight']} style={styles.left} />
                              </TouchableOpacity>
                            </View>
                          )}
                        </View>
                      </View>

                      <View style={styles.monthlyContainer}>
                        <View style={styles.monthDonationContainer}>

                          <Text style={basicStyles.sectionTitle}>合計寄付者</Text>
                          <Text style={styles.totalDonationAmountText}>{totalDonators.toLocaleString()}</Text>
                          <Text style={styles.ninText}>人</Text>
                        </View>
                        <View style={styles.line}></View>
                        <View style={styles.monthDonationContainer}>
                          <Text style={basicStyles.sectionTitle}>合計金額</Text>
                          <Text style={styles.totalDonationAmountText}>{thisMonthTotalDonation.toLocaleString()}</Text>
                          <Text style={styles.yenText}>円</Text>
                        </View>

                        <View style={styles.line}></View>

                        <View style={styles.viaShopifyContainer}>
                          <View style={styles.viaMeguChildContainer}>
                            <Text style={basicStyles.sectionTitle}>megu経由</Text>
                            <Text style={styles.totalDonationAmountText}>{meguDeposit.toLocaleString()}</Text>
                            <Text style={styles.yenText}>円</Text>
                          </View>

                          <View style={styles.viaOtherChildContainer}>
                            <Text style={basicStyles.sectionTitle}>GOLF経由</Text>
                            <Text style={styles.totalDonationAmountText}>{meguGolfDeposit.toLocaleString()}</Text>
                            <Text style={styles.yenText}>円</Text>
                          </View>

                        </View>

                        <View style={styles.line}></View>

                        <View style={styles.accumulationBigContainer}>


                          <View style={styles.accumulationContainer}>
                            <Text style={basicStyles.sectionTitle}>支援金残高</Text>
                            <Text type={'xlarge'} style={styles.accumulationAmount}>
                              {totalDeposit.toLocaleString()}
                            </Text>
                            <Text>円</Text>
                          </View>
                          <View style={styles.accumulationText}>
                            <Text>支援金残高が1万円を超えると出金申請が可能です</Text>
                          </View>

                          {totalDeposit < 10000 ? (
                            <Button buttonType={20} onPress={() => alert("支援金残高が１万円を超えないと出金できません")}>
                              出金を依頼する
                            </Button>
                          ) : (
                            <Hoverable>
                              {({ hovered }) => (
                                <Button buttonType={hovered ? 3 : 1} onPress={() => goToWithDraw(totalDeposit)}>
                                  出金を依頼する
                                </Button>
                              )}
                            </Hoverable>
                          )}

                        </View>
                      </View>
                    </View>
                  </View>



                  <View>
                    <View style={styles.contents}>
                      <View style={basicStyles.myPageCard}>

                        {isLoading ?

                          <View style={styles.emptyContainer}>
                            <Loader />
                          </View>
                          : <View>
                            {size ? (
                              <View>
                                <table >
                                  <th style={{
                                    fontWeight: 'bold',
                                    fontSize: 18,
                                    textAlign: "left",
                                  }}>支援者一覧</th>
                                  <th
                                    style={{
                                      fontWeight: 'bold',
                                      textAlign: "left",
                                    }}
                                  >
                                    <TouchableOpacity
                                      onPress={() => sortType()}
                                      style={styles.listTextContainer}
                                    >
                                      <Text>支援種別</Text>
                                      <View
                                        style={styles.arrowContainer}
                                      >
                                        <ArrowStyledSVG theme={{ color: (donationSortType === 1 || donationSortType === null) ? "#C4C4C4" : "#0470B9", image: images["upperArrowSvg"] }} />
                                        <ArrowStyledSVG theme={{ color: (donationSortType === 0 || donationSortType === null) ? "#C4C4C4" : "#0470B9", image: images["downArrowSvg"] }} />


                                      </View>

                                    </TouchableOpacity>
                                  </th>

                                  <th
                                  >
                                    <TouchableOpacity
                                      onPress={() => sortCreatedAt()}
                                      style={styles.listTextContainer}
                                    >

                                      <Text>決済日時</Text>
                                      <View
                                        style={styles.arrowContainer}
                                      >
                                        <ArrowStyledSVG theme={{ color: (createdAtSortType === 1 || createdAtSortType === null) ? "#C4C4C4" : "#0470B9", image: images["upperArrowSvg"] }} />
                                        <ArrowStyledSVG theme={{ color: (createdAtSortType === 0 || createdAtSortType === null) ? "#C4C4C4" : "#0470B9", image: images["downArrowSvg"] }} />


                                      </View>

                                    </TouchableOpacity>
                                  </th>
                                  <th
                                    style={{
                                      fontWeight: 'bold',
                                      textAlign: "left",
                                    }}
                                  >
                                    <TouchableOpacity
                                      onPress={() => sortAmount()}
                                      style={styles.listTextContainer}
                                    >
                                      <Text>金額
                                      </Text>
                                      <View
                                        style={styles.arrowContainer}
                                      >
                                        <ArrowStyledSVG theme={{ color: (amountSortType === 0 || amountSortType === null) ? "#C4C4C4" : "#0470B9", image: images["upperArrowSvg"] }} />
                                        <ArrowStyledSVG theme={{ color: (amountSortType === 1 || amountSortType === null) ? "#C4C4C4" : "#0470B9", image: images["downArrowSvg"] }} />


                                      </View>



                                    </TouchableOpacity>
                                  </th>
                                  {donationsList.map((data: any) => {
                                    const isDownloaded = data.isDownloaded
                                    const type = data.type
                                    const createdAt = data.createdAt
                                    const amount = data.amount
                                    const imageUrl = data.imageUrl
                                    const nickname = data.nickname
                                    const id = data.id
                                    const uid = data.uid
                                    const downloadedTimestamp = data.downloadedTimestamp
                                    const tdStyle = {
                                      paddingTop: 15,
                                      paddingBottom: 15,
                                    }
                                    return (
                                      <tr >
                                        <td style={tdStyle}>
                                          <View style={styles.iconContainer}>
                                            {imageUrl ? <View>
                                              <Image source={imageUrl} style={styles.donatorIcon} />
                                            </View> : <Image source={images["defaultUserIcon"]} style={styles.donatorIcon} />}
                                            <View>
                                              <Text style={styles.nickNameStyle}>{nickname}</Text>
                                            </View>
                                            <View style={styles.downloadContainer}>
                                              <View style={styles.donationItemContainer}>
                                                {data.transferId ? <View style={styles.collectedContainer}>
                                                  <Text style={styles.notCollectedText}>収納済</Text>
                                                </View> : <View style={styles.notCollectedContainer}>
                                                  <Text style={styles.notCollectedText}>未収納</Text>
                                                </View>}
                                              </View>


                                              {isDownloaded && <View style={styles.isNotDownloadContainer}>
                                                <View style={styles.donationItemContainer}>

                                                  <View style={styles.isDownloadedBadgeContainer}>
                                                    <Text style={styles.isDownloadedBadgeText}>領収書発行済</Text>
                                                  </View>
                                                </View>
                                                <View style={styles.donationItemContainer}>
                                                  <TouchableOpacity onPress={() => openModal(id, createdAt)}>
                                                    <Hoverable>
                                                      {({ hovered }) => (

                                                        <View style={hovered ? styles.hoveredButton : styles.whiteBtn}>
                                                          <Text>詳細を見る</Text>
                                                        </View>
                                                      )}
                                                    </Hoverable>
                                                  </TouchableOpacity>
                                                </View>

                                                <TouchableOpacity onPress={() => goToPDF(uid, downloadedTimestamp)}>
                                                  <Hoverable>
                                                    {({ hovered }) => (
                                                      <View style={hovered ? styles.hoveredButton : styles.whiteBtn}>
                                                        <Text>発行済の領収書を見る</Text>
                                                      </View>
                                                    )}
                                                  </Hoverable>
                                                </TouchableOpacity>

                                              </View>}


                                            </View>
                                          </View>
                                        </td>
                                        <td style={tdStyle}>
                                          <View>
                                            <Text style={styles.typeText}>
                                              {(() => {
                                                if (type === 'purchase') {
                                                  return '売買'
                                                } else if (type === 'monthly') {
                                                  return '月額支援'
                                                } else if (type === 'direct') {
                                                  return '直接支援'
                                                } else if (type === 'golf') {
                                                  return 'ゴルフ'
                                                }
                                              })()}
                                            </Text>
                                          </View>
                                        </td>
                                        <td style={tdStyle}>
                                          <View>
                                            <Text style={styles.dateText}>
                                              {String(new Date(createdAt).getFullYear())}/{String(new Date(createdAt).getMonth() + 1)}/{String(new Date(createdAt).getDate())}
                                            </Text>
                                          </View>
                                        </td>
                                        <td style={tdStyle}>
                                          <View>
                                            <Text style={styles.amountText}>{amount.toLocaleString()}円</Text>
                                          </View>
                                        </td>
                                      </tr>
                                    )
                                  })}






                                </table>

                                {totalSize != donationsList.length ? (
                                  <TouchableOpacity onPress={() => getMoreDonations()}>
                                    <Text style={styles.moreDonation}>さらに読み込む</Text>
                                  </TouchableOpacity>
                                ) : (
                                  <View style={styles.emptyMore}></View>
                                )}

                              </View>
                            ) : (
                              <View style={styles.emptyContainer}>
                                <Text type={'empty'} style={styles.empty}>
                                  支援はまだありません
                                </Text>
                              </View>
                            )}
                          </View>}

                      </View>

                    </View>
                  </View>
                </View>
              </View>



            </View>

            <Modal
              show={show}
              setShow={setShow}
              phoneNumber={phoneNumber}
              address={address}
              fullname={fullname}
              donatorNickName={donatorNickName}
              donatorImageUrl={donatorImageUrl}
            />

          </View>
        </View>
      </View>
    </View>
  )
}

function POP(props) {
  return (
    <div>
      {props.isPopup ? (
        <div
          style={{
            position: 'fixed',
            zIndex: 100,
            left: '25%',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
            height: '15%',
            display: 'block',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            background: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <div
            style={{
              zIndex: 100,
              padding: '1em',
              color: 'white',
            }}
          >
            <p>出金依頼を受付ました。通常２，３営業日以内に着金いたします。 今しばらくお待ち下さい。</p>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

function Modal({ show, setShow, fullname, address, phoneNumber, donatorNickName, donatorImageUrl }) {
  if (show) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000

        }}
      >
        <div
          style={{
            zIndex: 2,
            width: '60%',
            background: '#fff',
            height: 150,
            paddingTop: 20
          }}
        >
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 40,
            }}
          >
            <View style={styles.donatorItemContainer}>
              <View style={styles.donatorImageContainer}>
                {donatorImageUrl ? <Image style={styles.modalIcon} source={{ uri: donatorImageUrl }} /> : <Image style={styles.modalIcon} source={{ uri: images["defaultUserIcon"] }} />}



              </View>
              <View >
                <View>
                  <Text style={styles.modalNickName} numberOfLines={2}>
                    {donatorNickName}

                  </Text>
                </View>


              </View>
              <View style={styles.receiptTextContainer}>
                <View style={styles.isDownloadedBadgeContainer}>
                  <Text style={styles.isDownloadedBadgeText}>領収書発行済</Text>
                </View>
              </View>
            </View>
            <div>
              <TouchableOpacity onPress={() => setShow(false)}>
                <Hoverable>
                  {({ hovered }) => (
                    <View style={styles.closeContainer}>
                      {(() => {
                        if (hovered) {
                          return (
                            <Svg height="40" width="40">
                              <Circle cx="20" cy="20" r="19.5" fill="white" fill-opacity="0.6" stroke="#40A9FF" />
                              <Path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20.1421 21.5563L26.5061 27.9203L27.9203 26.5061L21.5563 20.1421L27.9203 13.7781L26.5061 12.3639L20.1421 18.7279L13.7782 12.3639L12.364 13.7781L18.7279 20.1421L12.364 26.5061L13.7782 27.9203L20.1421 21.5563Z"
                                fill="#40A9FF"
                              ></Path>
                            </Svg>
                          )
                        } else {
                          return (
                            <Svg height="40" width="40">
                              <Circle cx="20" cy="20" r="19.5" fill="white" fill-opacity="0.6" stroke="#DDDDDD" />
                              <Path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20.1421 21.5563L26.5061 27.9203L27.9203 26.5061L21.5563 20.1421L27.9203 13.7781L26.5061 12.3639L20.1421 18.7279L13.7782 12.3639L12.364 13.7781L18.7279 20.1421L12.364 26.5061L13.7782 27.9203L20.1421 21.5563Z"
                                fill="#999999"
                              ></Path>
                            </Svg>
                          )
                        }
                      })()}
                    </View>
                  )}
                </Hoverable>
              </TouchableOpacity>
            </div>
          </div>

          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 40
            }}
          >
            <View style={styles.donatorItemContainer}>
              <View style={styles.donatorImageContainer}>
                <View style={styles.modalIcon} />


              </View>
              <View style={{ width: 50 }}>
                <View>
                  <Text style={styles.modalNickName} numberOfLines={2}>
                    {fullname}

                  </Text>
                </View>


              </View>
              <View style={styles.receiptTextContainer}>


                <Text style={styles.addressText}>
                  {address}
                </Text>
              </View>

              <View style={styles.receiptTextContainer}>


                <Text>
                  {phoneNumber}

                </Text>
              </View>
            </View>
            <div>

            </div>
          </div>


        </div>
      </div>
    )
  } else {
    return null
  }
}

const styles = StyleSheet.create({
  receiptTextContainer: {
    marginLeft: 30,
    flexWrap: "wrap",
    maxWidth: 200,
    lineHeight: 14
  },
  arrowContainer: {
    marginLeft: 4
  },
  addressText: {
    lineHeight: 14

  },
  listTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center"

  },
  donatorItemContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',

  },
  donatorImageContainer: {
    marginRight: 20,
  },
  container: {
    flexDirection: 'row',
  },
  empty: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 150,
  },
  emptyContainer: {
    height: 300,
  },
  donationHistory: {
    marginLeft: 30,
  },
  buttonLabel: { fontWeight: 'bold' },
  buttonInner: {
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonRoot: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    margin: 10,
    padding: 20,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4,
  },
  buttonHovered: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },

  buttonFocused: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    elevation: 10,
  },
  buttonPressed: {
    backgroundColor: 'red',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.5,
    shadowRadius: 12.35,
    elevation: 19,
  },
  modalHeader: {
    justifyContent: 'space-between',
    backgroundColor: 'red',
  },
  isDownloadedBadgeContainer: {
    backgroundColor: '#0470B9',
    color: 'white',
    textAlign: 'center',
    height: 30,
    width: 90,
    justifyContent: 'center',
  },
  isDownloadedBadgeText: {
    color: 'white',
    margin: 'auto',
  },
  menu: {
    width: width * 0.2,
    height: height * 0.8,
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  logo: {
    height: 80,
    width: 50,
    marginBottom: 50,
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  menuItem: {
    marginBottom: 20,
  },
  header: {
    width: '70%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contents: {

    marginTop: 20,
  },
  monthlyCard: {
    width: width - 160,
    marginTop: height * 0.02,
    height: 360,
    zIndex: 0,
    backgroundColor: 'white',
  },
  rightIcon: {},
  card_header: {
    flexDirection: 'row',
  },
  monthPreviousButton: {
    alignItems: 'center',
    paddingTop: 30,
    marginTop: -30,
    marginLeft: width * 0.02,
    marginRight: width * 0.01,
  },
  monthLaterButton: {
    alignItems: 'center',
    paddingTop: 30,
    marginTop: -30,
    marginLeft: width * 0.02,
    marginRight: width * 0.01,
  },
  areaIcon: {
    height: width * 0.1,
    width: width * 0.1,
    marginTop: height * 0.01,
    marginBottom: height * 0.01,
    marginLeft: width * 0.05,
  },
  totaleDonate: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#F4F4F4',
    width: '100%',
  },
  area: {
    flex: 1,
    flexDirection: 'row',
  },
  item: {

    // marginVertical: 8,
    // marginHorizontal: 16,
    paddingTop: 14,
    paddingBottom: 14,
    borderTopWidth: 1,
    borderTopColor: '#DDDDDD',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 32,
  },
  listHeader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  donatorIcon: {
    height: 60,
    width: 60,
    borderRadius: 30,
  },
  modalIcon: {

    height: 60,
    width: 60,
    borderRadius: 30,
    marginLeft: 20,
    marginTop: 10

  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  left: {
    height: width * 0.01,
    width: height * 0.01,
  },
  right: {
    height: width * 0.01,
    width: height * 0.01,
  },
  monthContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  monthTextCotainer: {
    marginRight: 120,
  },
  monthChangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  sectionText: {
    fontSize: 12,
    marginRight: 16,
  },

  monthDonationContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 150,
  },

  viaContainer: {
    marginTop: 150,
  },

  viaShopifyContainer: {
    marginTop: 80,
    paddingBottom: 45,
  },
  viaOtherContainer: {
    marginTop: 150,
    paddingBottom: 45,
  },
  viaChildContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  viaMeguChildContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 45,
  },
  viaOtherChildContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 45,
  },
  line: {
    width: 1,
    height: 240,
    backgroundColor: '#ddd',
    marginTop: 40,
  },
  totalDonationAmountText: {
    fontSize: 24,
    marginRight: 4,
  },
  ninText: {
    fontSize: 12,
  },
  yenText: {
    fontSize: 12,
  },
  yearMonthText: {
    paddingLeft: 40,
    paddingRight: 40,
    color: '#999999',
    fonFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 16,
  },
  downloadContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: width * 0.3,
    alignItems: 'center',
  },
  isNotDownloadContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: width * 0.3,
    alignItems: 'center',
  },
  donationItemContainer: {

    marginRight: 60
  },
  whiteBtn: {
    borderColor: '#DDDDDD',
    borderWidth: 2,
    borderRadius: 50,
    padding: 10,
  },
  hoveredButton: {
    borderColor: '#999999',
    borderWidth: 2,
    borderRadius: 50,
    padding: 10,
  },
  notCollectedText: {
    color: 'white',
    textAlign: 'center',
  },
  notCollectedContainer: {
    backgroundColor: '#BDBDBD',
    textAlign: 'center',
    height: 30,
    width: 90,
    justifyContent: 'center',
  },
  collectedContainer: {
    backgroundColor: '#00913A',
    textAlign: 'center',
    height: 30,
    width: 90,
    justifyContent: 'center',
  },
  userNameText: {
    marginRight: 20,
    fontSize: 'bold',
  },
  donationHistoryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  donationTitleStyle: {
    marginTop: 20,
  },
  accumulationBigContainer: {
    paddingTop: 40,
  },
  accumulationContainer: {
    flexDirection: 'row',
    display: 'flex',
    marginBottom: 37,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 40,
  },
  accumulationAmount: {
    fontSize: 24,

    marginRight: 6,
  },
  accumulationText: {
    marginBottom: 36,
    width: 150,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  modalNickName: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  modalText: {
    marginRight: 30,
  },
  amountText: {
    fontWeight: 'bold',

  },
  dateText: {
    color: '#999999',
    // marginLeft: 10,
  },
  typeText: {
    color: '#999999',

  },
  listCard: {
    width: width * 0.75,
  },
  listCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    // marginTop: 32,
    marginBottom: 12
  },
  threeTitles: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: 300,
    marginRight: 30,
  },
  moreDonation: {
    textAlign: 'center',
    color: '#999999',
    marginTop: 20,
    width: "100%",

  },
  emptyMore: {
    marginBottom: 20,
  },
  rightItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    // width: 320,
    alignItems: 'center',
  },
  monthlyContainer: {
    justifyContent: 'space-around',
    flexDirection: 'row',
    paddingBottom: 20,
  },
  nickNameStyle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: 20,
    marginRight: 50,
    width: 80,
  },
  closeContainer: { marginTop: 20, marginRight: 20 },
})
