import React, { useEffect } from 'react'
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native'
import { Text } from 'app/src/components/basics/text'
import { images } from 'app/src/libs/images'
import { useNavigation } from 'app/src/hooks/use-navigation'
import { goToActivistMypage, goToDonations, goToReportScreen } from 'app/src/libs/screen-transition'
import { Hoverable } from 'react-native-web-hover'
import { styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import Select from 'react-select';
import  { useState } from 'react'
import {  onAuthStateChanged } from 'firebase/auth'
import {   auth } from 'app/src/libs/firebase'
import { getDocs,collection } from 'firebase/firestore'
import { db } from 'app/src/libs/firebase'
import { fetchActivistInfo } from 'app/src/libs/firebase'







type ContainerProps = {
  title: string
  imageUrl: string
  activistId:string
  number?:number
}


export const ActivistHeaderMenu: React.FC<ContainerProps> = (props: ContainerProps) => {
  const navigation = useNavigation()

  const [activistId, setActivistId] = useState<string>('')
  const [selectedOption, setSelectedOption] = useState<{ label:string;value:string}>()
  const [relatedActivists,setRelatedActivists] = useState<{ label:string;value:string}[]>([])


  useEffect(()=>{
    const fetchMyPage = async () => {
      await onAuthStateChanged(auth, user => {
        if (user) {


        //会員登録後、メール認証したか
          const selectedActivistId = localStorage.getItem("selectedActivistId")
          const id = selectedActivistId?selectedActivistId:user.uid
          setActivistId(id)
          const setUp = async()=>{

            const result = await getDocs(collection(db, 'related-activists', user.uid,"activists"))
            const docs = result.docs


            if(id != user.uid){
              const activistInfo = await fetchActivistInfo(user.uid)

              const activistName = activistInfo.activistName
              relatedActivists.push({
                label:activistName,
                value:user.uid,

              })


            }



            docs.forEach((docSnap)=>{

              const activistName = docSnap.data().activistName
              relatedActivists.push({
                label:activistName,
                value:docSnap.id,

              })

              setRelatedActivists(relatedActivists)
            })






          }
          setUp()



        }
      })
    }
    fetchMyPage()
  },[props.number])




  return (
    <View style={styles.header}>
      <View style={styles.titleContainer}>
        <Text type={'quickTitle'}>{props.title}</Text>
      </View>
      <View style={styles.rightIcon}>
        {!relatedActivists.length? <></>:     <View style={styles.relatedContainer}>
  <Text>アカウントを切り替え</Text>
<Select
        defaultValue={selectedOption}
      onChange={(e)=>{
        if(!e) return



        setSelectedOption({
          label:e.label,
          value:e.value,

        })
        localStorage.setItem("selectedActivistId",e.value)
        setActivistId(e.value)
        location.reload()
      }}

      isMulti={false}
        options={relatedActivists}
      />
</View>}

        {/* <Image resizeMode="contain" style={styles.bell} source={images['bell']} /> */}

        <Hoverable>
          {({ hovered }) => (
            <View style={hovered ? basicStyles.imageOpacity : {}}>
              <TouchableOpacity onPress={() => goToActivistMypage(navigation)}>
                {props.imageUrl ? (
                  <Image resizeMode="contain" style={styles.activistDefaultIcon} source={{ uri: props.imageUrl }} />
                ) : (
                  <Image resizeMode="contain" style={styles.activistDefaultIcon} source={images['userIconDefault']} />
                )}
              </TouchableOpacity>
            </View>
          )}
        </Hoverable>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  bell: {
    height: 24,
    width: 24,
  },
  relatedContainer:{
    position:"absolute",
    width:300,
    right:80,
    zIndex:100,
    top:-10


  },
  rightIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  activistDefaultIcon: {
    width: 50,
    height: 50,
    marginLeft: 32,
    borderRadius: 25
  },
  activistContainer: {
    backgroundColor: '#C4C4C4',
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 40,
    marginBottom: 35,
    marginLeft: 30,
    height: 50,
    minWidth: 1220,
    zIndex:100,


  },
  menu: {
    width: 160,
    minHeight: 900,
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    marginRight: 20,
  },
  logo: {
    height: 80,
    width: 50,
    marginBottom: 50,
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  menuItem: {
    marginBottom: 20,
  },
  titleContainer: { marginTop: 'auto', marginBottom: 'auto' },
})
