import React, { useEffect, useState, useCallback } from 'react'
import {
  StyleSheet,
  View,
  Dimensions,
  ImageBackground,
  Image,
  TextInput as RNTextInput,
  TouchableOpacityBase,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native'
import { Pressable } from 'react-native-web-hover'
import { styles as basicStyles, styles, ThankYouImageFunc } from 'app/src/components/advanced/activist/basic'
import { Checkbox as RNCheckbox } from 'react-native-paper'

type Props = {
  isValue: boolean
  isSetValue: (v: boolean) => void
}

export const CheckBox = (props: Props) => {
  return (
    <Pressable>
      {({ hovered }) => (
        <RNCheckbox
          status={props.isValue ? 'checked' : 'unchecked'}
          onPress={() => {
            props.isSetValue(!props.isValue)
          }}
          color="#0470B9"
          uncheckedColor={hovered ? '#40A9FF' : '#ddd'}
        />
      )}
    </Pressable>
  )
}
