// import { verifyEmail } from './../../functions/src/modules/verify-email';
import { initializeApp } from 'firebase/app';

import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { doc, setDoc, where, getDoc, getFirestore, query, orderBy, limit, collection, getDocs, updateDoc, increment, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL, uploadBytes, uploadString, deleteObject } from 'firebase/storage'
import imageCompression from 'browser-image-compression'

// test
console.log("APP_ENV",process.env.APP_ENV)

console.log("FIREBASE_PROJECT_ID",process.env.FIREBASE_PROJECT_ID)












export const app = initializeApp({
  projectId: process.env.FIREBASE_PROJECT_ID,
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: `${process.env.FIREBASE_MESSAGING_SENDER_ID}`,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENTID


});



export const functions = getFunctions(app);

// if (process.env.APP_ENV != 'production') {
//   connectFunctionsEmulator(getFunctions(), 'localhost', 5001)
// }

functions.region = "asia-northeast1" // これを追加

export const auth = getAuth(app);


export const db = getFirestore(app);

export const storage = getStorage(app)


export const getRef = (fileName: string) => {

  return ref(storage, fileName)
}


export const getFunctionsRef = () => {

  functions.region = "asia-northeast1"
  return functions
}


export const verifyEmail = async (uid: any) => {



  const result = await httpsCallable(functions, 'verifyEmail')({
    uid: uid,

  })


}


export const uploadStorage = async (storageRef, file) => {


  const task = await uploadBytes(storageRef, file)

  return task


}


export const getCompressImageFile = async (file) => {
  const options = {
    maxSizeMB: 0.8, //最大ファイルサイズ
    maxWidthOrHeight: 600, //最大縦横値
  }
  return await imageCompression(file, options)
}


export const uploadCloudiary = async (file: string, height: number, width: number) => {

  const result = await httpsCallable(functions, 'test')({
    height: height,
    width: width,
    file: file

  })

  console.log("result", result)

}


export const uploadStringStorage = async (storageRef, value, type) => {

  const task = await uploadString(storageRef, value, type)

  return task
}


export const deleteStorage = async (ref) => {

  await deleteObject(ref)

}


export const getDownload = async (ref) => {

  const url = await getDownloadURL(ref)
  return url

}

// ユーザー情報の取得
export const fetchUserInfo = async (uid: string) => {


  const ref = doc(db, 'users', uid)
  const docSnap = await getDoc(ref);
  const docResult = { id: docSnap.id, ...docSnap.data() }
  return docResult

}

export const fetchActivistInfo = async (activistId: string) => {



  const ref = doc(db, 'activists', activistId)
  const docSnap = await getDoc(ref);
  const data = docSnap.data()
  const docResult = {
    id: data?.id,
    activistName: data?.activistName,
    address: data?.address,
    author: data?.author,
    bankAccountNumber: data?.bankAccountNumber,
    bankAccountType: data?.bankAccountType,
    bankBranchCode: data?.bankBranchCode,
    bankBranchName: data?.bankBranchName,
    bankCode: data?.bankCode,
    bankName: data?.bankName,
    createdAt: data?.createdAt,
    email: data?.email,
    establishmentDate: data?.establishmentDate,
    town: data?.town,
    howWeDo: data?.howWeDo,
    howWeDoImages: data?.howWeDoImages,
    imageUrl: data?.imageUrl,
    isApproved: data?.isApproved,
    isAuthor: data?.isAuthor,
    isSetUp: data?.isSetUp,
    isThankYouMessage: data?.isThankYouMessage,
    kakuin: data?.kakuin,
    meguDeposit: data?.meguDeposit,
    otherDeposit: data?.otherDeposit,
    pdfYears: data?.pdfYears,
    phoneNumber: data?.phoneNumber,
    postalCode: data?.postalCode,
    preSendingReportIdsList: data?.preSendingReportIdsList,
    representative: data?.representative,
    building: data?.building,
    selectedAreas: data?.selectedAreas,
    shopifyDeposit: data?.shopifyDeposit,
    stripeAccountId: data?.stripeAccountId,
    thankYouMessage: data?.thankYouMessage,
    thumbnail: data?.thumbnail,
    totalDeposit: data?.totalDeposit,
    whatWeDo: data?.whatWeDo,
    whatWeDoImages: data?.whatWeDoImages,
    doImages: data?.doImages,
    activistNameFurigana: data?.activistNameFurigana,
    proofImage:data?.proofImage,
    activistNumber:data?.activistNumber,
    activistAdmittedDate:data?.activistAdmittedDate,
    proviso:data?.proviso





  }

  return docResult

}

export const fetchDeposit = async (activistId: string, yearMonth: string) => {


  console.log("yearMonth",yearMonth)
  const donatedRef = collection(db, 'activists', activistId, 'donated', yearMonth,"d")
  const donatedResult = await getDocs(donatedRef)





  let meguDeposit = 0
  let meguGolfDeposit = 0


  donatedResult.docs.map(async(docSnap)=>{

    const data = docSnap.data()
    if(!data) return

    console.log("data",data)
    const type = data.type

    if(type == "golf"){

      meguGolfDeposit += data.amount
    }else{

      meguDeposit += data.amount
    }


  })




  const thisMonthTotalDonation =   meguDeposit + meguGolfDeposit




  const ref = doc(db, 'activists', activistId)

  const docSnap = await getDoc(ref);


  const data = docSnap.data()


  const totalDeposit = data?.totalDeposit


  const obj = {
    thisMonthTotalDonation: thisMonthTotalDonation,
    meguDeposit: meguDeposit,
    meguGolfDeposit:meguGolfDeposit,
    totalDeposit: totalDeposit,

  }

  return obj






}



export const fetchTotalDonators = async (activistId: string, yearMonth: string) => {

  const shopifyDonatorsRef = doc(db, 'activists', activistId, 'shopify-donators', yearMonth)
  const meguDonatorsRef = doc(db, 'activists', activistId, 'megu-donators', yearMonth)


  const shopifyDonatorsResult = await getDoc(shopifyDonatorsRef)
  const meguDonatorsResult = await getDoc(meguDonatorsRef)


  let shopifyDonatorsNum = 0


  if (shopifyDonatorsResult.exists()) {

    let array = shopifyDonatorsResult.data()?.donatorsList

    shopifyDonatorsNum = array.length


  }


  let meguDonatorsNum = 0

  if (meguDonatorsResult.exists()) {

    let array = meguDonatorsResult.data()?.donatorsList
    meguDonatorsNum = array.length

  }




  return shopifyDonatorsNum + meguDonatorsNum



}

export const fetchDonationInfo = async (activistId: string, year: string, month: string, currentSize: number) => {


  const yearMonth = year + month

  let totalSize = 0


  const donatedRef = collection(db, 'activists', activistId, 'donated', yearMonth, 'd')
  console.log("activistId",activistId)


  if (currentSize) {


    const result = await getDocs(donatedRef)

    console.log("result.docs.length",result.docs.length)
    if (result.docs.length) {
      totalSize = result.docs.length
    }

  }

  const sizeResult = await getDocs(query(donatedRef, orderBy('createdAt', 'desc')))
  const size = sizeResult.size
  const donationsResult = await getDocs(query(donatedRef, orderBy('createdAt', 'desc'), limit(10)))
  const donationsDocs = donationsResult.docs
  let lastTime = 0
  let donationsList: any = []

  for (let i = 0; i < donationsDocs.length; i++) {

    let data = donationsDocs[i].data()



    if (data.uid) {

      const userRef = doc(db, 'users', data.uid)
      const result = await getDoc(userRef)
      if (result.exists()) {
        const userData = result.data()
        const imageUrl = userData?.imageUrl
        const nickname = userData?.uniqueId

        data['nickname'] = nickname
        data["imageUrl"] = imageUrl


      } else {


        data['nickname'] = ""
        data["imageUrl"] = ""

      }

      data["id"] = donationsDocs[i].id

      donationsList.push(data)
      if (i === donationsDocs.length - 1) {
        lastTime = data.createdAt
      }

    } else {

      continue
    }
  }

  return {
    donationsList: donationsList,
    lastTime: lastTime,
    size: size,
    totalSize: totalSize
  }





}


export const createUser = async (uid: string) => {

  const areasListCol = collection(db, 'areasList')
  const areasListResult = await getDocs(areasListCol)
  const areasList = areasListResult.docs[0].data().areasList
  const accumulations: any = {}
  let rate: number[] = []
  for (let i = 0; i < areasList.length; i++) {

    accumulations[areasList[i]] = 0

    rate.push(Math.round(i / areasList.length * 100))




  }



  const obj = {
    accumulations: accumulations,
    rate: rate
  }


  const portfoliosRef = doc(db, 'portfolios', uid)
  await setDoc(portfoliosRef, obj)

  const date = new Date()
  const year = String(date.getFullYear())
  const month = String(date.getMonth() + 1)
  const yearMonth = year + month

  const donationHistoryRef = doc(db, 'portfolios', uid, 'donationHistory', yearMonth)

  await setDoc(donationHistoryRef, { donationHistory: accumulations })


  const donationsRef = doc(db, 'donations', uid)
  await setDoc(donationsRef, { totalDonation: 0 })




}


// export const fetchOrders = async (uid:string) =>{
//   const doc = await firebase
//               .firestore()
//               .collection(`billing/v1/users/${uid}/orders`)
//               .where("type","==","order")
//               .get()
//               .then(qs =>
//                 qs.docs.map(doc=>{
//                   return {id:doc.id,...doc.data()}
//                 })
//                 )
//   return doc
// }

export const isPreSendingResult = async (activistId: string) => {

  const activistRef = doc(db, "activists", activistId)
  const activistResult = await getDoc(activistRef)
  const activistData = activistResult.data()
  const preSendingReportIdsList = activistData?.preSendingReportIdsList
  if (preSendingReportIdsList.length) {

    return true

  } else {

    return false

  }




}


export const fetchReports = async (activistId: string) => {

  const reportsRef = collection(db, "activists", activistId, 'reports')

  let doc = await getDocs(reportsRef).then((qs) => {

    qs.docs.map(d => {

      return { id: d.id, ...d.data() }
    })
  })


  return doc
}

// export const fetchActivistReport = async (reportId:string) =>{
//   const doc = await firebase
//               .firestore()
//               .collection("reports")
//               .doc(reportId)
//               .get()
//   return doc.data()
// }

export const fetchReport = async (activistId: string, reportId: string) => {



  const draftResult = await getDoc(doc(db, 'activists', activistId, 'reports', reportId))
  return draftResult.data()

}




export const fetchAreaActivists = async (area: string) => {



  const reportsRef = collection(db, "activists")

  const q = query(reportsRef, where("selectedAreas", "in", [area]))



  let doc = await getDocs(q).then((qs) => {

    qs.docs.map(d => {

      return { id: d.id, ...d.data() }
    })
  })


  return doc


}



// export const fetchShopDetail = async (shopId:string) => {


//   const doc = await firebase
//   .firestore()
//   .collection("shops")
//   .doc(shopId)
//   .get()
//   .then(doc => ({ id: doc.id, ...doc.data() }))
// return doc


// }

// ユーザークレジットカード情報の取得
export const fetchUserCreditCards = async (uid: string) => {

  const creditCardRef = collection(db, 'billing', 'v1', 'users', uid, 'paymentMethodList')
  const creditCards = await getDocs(creditCardRef).then((qs) => {
    qs.docs.map(doc => {

      return { id: doc.id, ...doc.data().card }

    })
  })


  return creditCards
}

// ニックネーム存在確認
// export const isExistsNickname = async (nickname: string, uid?: string): Promise<boolean> => {
//   const doc = await firebase
//     .firestore()
//     .collection(`users`)
//     .where('nickname', '==', nickname)
//     .get()

//   if (uid) {
//     return !doc.docs.every(v => v.id === uid)
//   } else {
//     return !doc.empty
//   }
// }

export const firebaseAuthError = (err = {}) => {
  console.warn(err)
  // @ts-ignore
  switch (err.code) {
    case 'auth/user-not-found':
      return 'ユーザーが登録されていません。'
    case 'auth/invalid-email':
      return '不正なメールアドレスです。'
    case 'auth/email-already-in-use':
      return 'すでに登録されているメールアドレスです。'
    case 'auth/weak-password':
      return 'パスワードは最低６文字必要です。'
    case 'auth/wrong-password':
      return 'パスワードが違うか、パスワードが登録されていないアカウントです。'
    case 'auth/credential-already-in-use':
      return 'このアカウントはすでに登録済です。'
    default:
      return 'エラーが発生しました。'
  }
}

export const sendMailForContact = async data => {


  const sendMailForContactOnCall = httpsCallable(functions, 'sendMailForContact')
  await sendMailForContactOnCall(data).then(res => {
    console.log('res', res)
  })
}




export const sendMailForCompletedRegistration = async data => {
  const sendMailForCompletedRegistration = httpsCallable(functions, 'sendMailForCompletedRegistration')
  await sendMailForCompletedRegistration(data).then(res => {
    console.log('res', res)
  })
}


export const forgetPassword = async (email, url) => {

  const timeoutMillisec = 8000
  return new Promise(async (resolve, reject) => {
    try {
      await httpsCallable(functions, 'forgetPassword', { timeout: timeoutMillisec })({ email, url })
      resolve({ success: true })
    } catch (err: any) {

      console.log('forgetPassword err:', err?.details?.code)

    }
  })
}


// 顧客情報の作成
export const stripeCreateCustomer = async () => {
  const timeoutMillisec = 8000
  return new Promise(async (resolve, reject) => {
    try {
      await httpsCallable(functions, 'stripeCreateCustomer', { timeout: timeoutMillisec })()
      resolve({ success: true })
    } catch (err: any) {
      console.log('stripeCreateCustomer err:', err?.details?.code)
      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}


export const stripePayouts = async (amount: number, stripeAccountId: string) => {
  const timeoutMillisec = 8000
  return new Promise(async (resolve, reject) => {
    try {
      await httpsCallable(functions, 'stripePayouts', { timeout: timeoutMillisec })({
        amount: amount,
        stripeAccountId: stripeAccountId
      })
      resolve({ success: true })
    } catch (err) {
      console.log('stripeCreateCustomer err:', err?.details?.code)
      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}

// 顧客のシークレットキー作成
export const stripeSetupIntent = async (): Promise<{
  success: boolean
  data: string
}> => {
  const timeoutMillisec = 8000
  return new Promise(async (resolve, reject) => {
    try {
      const intent = await httpsCallable(functions, 'stripeSetupIntent', { timeout: timeoutMillisec })()
      resolve({ success: true, data: intent.data })
    } catch (err) {
      console.log('stripeSetupIntent err:', err?.details?.code)
      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}


export const stripeDirectDonate = async (
  paymentMethod,
  amount,
  uid,
  activistId
): Promise<{
  success: boolean
  data: string
  orderId: string
}> => {
  const timeoutMillisec = 8000
  return new Promise(async (resolve, reject) => {
    try {
      const orderId = generateOrderId('stripe-card', getNewOrderId())
      const date = new Date()
      const transfer_group = uid + date.getTime()
      const year = String(new Date().getFullYear())
      const month = String((new Date()).getMonth() + 1)
      const yearMonth = `${year + month}`


      const intent = await httpsCallable(functions, 'stripeDirectDonate', { timeout: timeoutMillisec })({
        paymentMethod,
        amount: amount,
        orderId,
        transfer_group,
        uid: uid
      })





      const portfoliosRef = doc(db, "portfolios", uid)
      let portfolioResult = await getDoc(portfoliosRef)
      let portfolioData = portfolioResult.data()
      let accumulations = portfolioData?.accumulations


      const activistsRef = doc(db, 'activists', activistId)

      let activistResult = await getDoc(activistsRef)
      let activistData = activistResult?.data()
      let activistAreas = activistData?.selectedAreas
      const totalAmount = amount


      for (let i = 0; 0 < activistAreas.length; i++) {

        accumulations[activistAreas[i]] += amount


        amount = Math.round(totalAmount / activistAreas.length * (i - 1))



      }


      await updateDoc(portfoliosRef, {
        accumulations: portfolioData?.accumulations,
        donationHistory: portfolioData?.donationHistory

      })

      const donationsRef = doc(db, 'donations', uid)

      await updateDoc(donationsRef, { totalDonation: increment(totalAmount) })

      const donatingRef = collection(db, 'donations', uid, 'donating', year + month, 'd')
      await addDoc(donatingRef, {
        amount: totalAmount,
        createdAt: date.getTime(),
        activistId: activistId,
        year: String(date.getFullYear()),
        isDownloaded: false
      })



      let oridinalDeposit = activistData?.deposit


      let newDeposit = oridinalDeposit + Math.floor(totalAmount * 0.9)

      const activistRef = doc(db, 'activists', activistId)

      await updateDoc(activistRef, {
        deposit: newDeposit,
        meguAmount: Math.floor(totalAmount * 0.9)
      })


      const item = {
        "uid": uid,
        "chargeId": intent.data.charges.data[0].id,
        "amount": Math.round(totalAmount * 0.9),
        "date": date.getTime(),
        "transferGroup": intent.data.transfer_group,
        "transferId": "",
        "type": "direct",
        "isDownloaded": false,
        "activistId": activistId

      }

      const activistDonatedRef = collection(db, 'activists', activistId, 'donated')

      await addDoc(activistDonatedRef, item)

      const userRef = doc(db, 'users', uid)

      const userResult = await getDoc(userRef)
      const user = userResult?.data()
      const userEmail = user?.email







      await httpsCallable(functions, 'sendMailForCompletedDirectDonate', { timeout: timeoutMillisec })({
        email: userEmail,
        activistId: activistId,
        amount: totalAmount,
        uid: uid
      })





      resolve({ success: true, data: intent.data, orderId })
    } catch (err) {
      console.log('stripePaymentIntent err:', err?.details?.code)
      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}


export const stripeCreateMonthlyDonator = async (
  uid,
  paymentMethodId,
  priceId
): Promise<{
  success: boolean
}> => {
  const timeoutMillisec = 8000
  return new Promise(async (resolve, reject) => {
    try {



      const intent = await httpsCallable(functions, 'stripeCreateMonthlyDonator', { timeout: timeoutMillisec })({
        uid,
        paymentMethodId,
        priceId

      })

      resolve({ success: true })

    } catch (err) {
      console.log('stripePaymentIntent err:', err?.details?.code)
      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}







export const stripeTransfer = async (
  activistId,
  accountId,
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve, reject) => {
    try {

      const timeoutMillisec = 8000



      await httpsCallable(functions, 'stripeTransfer', { timeout: timeoutMillisec })({
        activistId,
        accountId,
      })



      resolve({
        success: true,
      })
    } catch (err) {
      console.log('stripeTransfer err:', err?.details?.code)


      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}



export const createPdf = async (): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve, reject) => {
    try {

      const timeoutMillisec = 8000



      await httpsCallable(functions, 'createPdf', { timeout: timeoutMillisec })({})



      resolve({
        success: true,
      })
    } catch (err) {
      console.log('createActivist err:', err?.details?.code)


      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}

export const createActivist = async (
  os,
  email,
  activistId
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve, reject) => {
    try {

      const timeoutMillisec = 8000



      await httpsCallable(functions, 'createActivist', { timeout: timeoutMillisec })({
        os,
        email,
        activistId
      })



      resolve({
        success: true,
      })
    } catch (err) {
      console.log('createActivist err:', err?.details?.code)


      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}


export const createRelatedActivist = async (
  os,
  email,
  activistId,
  activistName,
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve, reject) => {
    try {

      const timeoutMillisec = 8000



      await httpsCallable(functions, 'createRelatedActivist', { timeout: timeoutMillisec })({
        os,
        email,
        activistId,
        activistName
      })



      resolve({
        success: true,
      })
    } catch (err) {
      console.log('createActivist err:', err?.details?.code)


      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}





export const stripeCreateConnectUser = async (text, activistId) => {
  const timeoutMillisec = 8000

  await auth.currentUser?.getIdToken(true)
  return new Promise(async (resolve, reject) => {
    try {
      //    エラー発生
      const intent = await httpsCallable(functions, 'stripeCreateConnectUser', { timeout: timeoutMillisec })({
        text: text

      })

      resolve({ success: intent })
    } catch (err) {
      console.log('stripeCreateConnectUser err:', err?.details)
      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('もう一度登録処理を行なってください')
          break
      }
    }
  })


}


export const sendSignUpMail = (email: string, url: string) => {
  const timeoutMillisec = 8000

  return new Promise(async (resolve, reject) => {
    try {
      await httpsCallable(functions, 'activistDefentiveRegistration', { timeout: timeoutMillisec })({ email, url })
      resolve({ success: true })
    } catch (err: any) {

      console.log('defentiveRegistration err:', err?.details?.code)

    }
  })

}


export const back = async (activistId: string, reportId: string, title: string, body: string, thankYouMessageImage: string) => {

  // const date = new Date()



  //   const reportRef = doc(db, 'activists', activistId, 'reports', reportId)




  //   await updateDoc(reportRef, {
  //     thankYouMessageImage: thankYouMessageImage,
  //     title: title,
  //     body: body,
  //     author: author
  //   })

  //   goToReportEditScreen(navigator)












}

