import React, { useEffect, useState, useCallback } from 'react'
import {
  StyleSheet,
  View,
  Dimensions,
  ImageBackground,
  Image,
  TextInput as RNTextInput,
  TouchableOpacityBase,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native'
import { styles as basicStyles, ThankYouImageFunc } from 'app/src/components/advanced/activist/basic'
import { Text, Button } from 'app/src/components/basics'
import { Pressable } from 'react-native-web-hover'
import { goToBasicInfo, goToHome, goToReportConfirmScreen, goToReportScreen, goToSignupFinish } from 'app/src/libs/screen-transition'
import { useNavigation } from 'app/src/hooks/use-navigation'
import { TextInput } from 'app/src/components/basics/text-input'
import { CheckBox } from 'app/src/components/basics/check-box'
import { back, db, fetchActivistInfo, fetchReport } from 'app/src/libs/firebase'
import {
  deleteDoc,
  doc,
  updateDoc,
  addDoc,
  collection,
  getDocs,
  where,
  query,
  arrayRemove,
} from 'firebase/firestore'


import { Formik } from 'formik';
import * as Yup from "yup";

type Props = {
  title: string
  setTitle: (v: string) => void
  body: string
  setBody: (v: string) => void
  thankYouMessageImage: string
  setThankYouMessageImage: (v: string) => void
  activistId: string

  label: string

  isDraft: boolean
  setLabel: (v: string) => void
  reportId: string
  setReportId: (v: string) => void
  isPostThankYouMessage: boolean
  setIsPostThankYouMessage: (v: boolean) => void
  isSent: boolean

}

const { width, height } = Dimensions.get('window')

export const ReportContainer = (props: Props) => {
  const navigation = useNavigation()
  const date = new Date()

  async function deleteReport() {
    const result = window.confirm("本当に削除してもいいですか？")
    if (result) {
      const ref = doc(db, 'activists', props.activistId, 'reports', String(props.reportId))

      const activistInfo = await fetchActivistInfo(props.activistId)
      const preSendingReportIdsList: string[] = activistInfo.preSendingReportIdsList

      const reportInfo = fetchReport(props.activistId, props.reportId)
      if (preSendingReportIdsList.includes(props.reportId)) {
        const activistRef = doc(db, 'activists', props.activistId)

        await updateDoc(activistRef, {
          preSendingReportIdsList: arrayRemove(props.reportId),
        })
      }

      await deleteDoc(ref)

      const q = query(collection(db, 'messages'), where('reportId', '==', String(props.reportId)))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach(async docSnap => {
        const data = docSnap.data()
        const delRef = doc(db, 'reports', data.uid, 'messages', String(props.reportId))
        await deleteDoc(delRef)
      })

      goToReportScreen(navigation)

    }

  }




  async function save() {


    if (props.reportId) {

      const reportDocRef = doc(db, 'activists', props.activistId, 'reports', props.reportId)


      let obj = {
        thankYouMessageImage: props.thankYouMessageImage,
        title: props.title,
        body: props.body,
        activistId: props.activistId,

      }

      if (props.isSent) {

        obj["isDraft"] = false
        await updateDoc(reportDocRef, obj)

        goToReportScreen(navigation)




      } else {


        obj["isDraft"] = true
        await updateDoc(reportDocRef, obj)

        props.setLabel("thank")




      }








    } else {

      const reportDocRef = collection(db, 'activists', props.activistId, 'reports')

      console.log("body", props.body)

      const obj = {
        thankYouMessageImage: props.thankYouMessageImage,
        title: props.title,
        body: props.body,
        isDraft: true,
        activistId: props.activistId,
        createdAt: date.getTime()
      }

      const doc = await addDoc(reportDocRef, obj)



      props.setReportId(doc.id)

      props.setLabel("thank")



    }








  }

  const returnHome = async () => {






    const reportDocRef = collection(db, 'activists', props.activistId, 'reports')

    const obj = {
      thankYouMessageImage: props.thankYouMessageImage,
      title: props.title,
      body: props.body,
      isDraft: true,
      activistId: props.activistId,
      createdAt: date.getTime()
    }

    await addDoc(reportDocRef, obj)

    goToReportScreen(navigation)



  }


  const validation = () =>
    Yup.object().shape({
      title: Yup.string()
        .required("必須項目です"),
      body: Yup.string()
        .required("必須項目です"),
      thankYouMessageImage: Yup.string()
        .required("必須項目です"),
    });

  return (

    <Formik
      initialValues={{
        title: props.title,
        body: props.body,
        thankYouMessageImage: props.thankYouMessageImage
      }}
      enableReinitialize={true}
      validationSchema={validation()}
      onSubmit={values => save()}
    >
      {({ setFieldValue, handleBlur, handleSubmit, values, errors }) => (
        <View style={basicStyles.cardContainer}>
          <View style={basicStyles.card}>
            <Text style={basicStyles.cardTitle}>
              {(() => {
                if (props.label === 'edit') {
                  return '活動報告の編集'
                } else if (props.label === 'create') {
                  return '活動報告の新規作成'
                }
              })()}
            </Text>
            <View>
              <Text style={basicStyles.explanation}>活動報告は最低限、月に一度支援いただいた支援者に対してお礼と報告を兼ねて送付いただくようにしております。ただそれ以外にも記事として近況を発信いただくのにご活用いただけます。</Text>
            </View>

            <View style={basicStyles.cardBody}>
              <View style={styles.bodyContainer}>
                <View style={basicStyles.sectionContainer}>
                  <TextInput
                    label={'タイトル'}
                    multiple={2}
                    value={props.title}
                    error={errors.title ? errors : ""}
                    type={"title"}


                    onChangeText={async (e: any) => {

                      props.setTitle(e)
                      setFieldValue("title", props.title)


                    }}
                    onBlur={handleBlur('title')}

                  />

                </View>

                <View style={basicStyles.sectionContainer}>
                  <TextInput
                    type={"body"}
                    label={'本文'}
                    error={errors.body ? errors : ""}
                    multiple={1}
                    value={props.body}


                    onChangeText={async (e: any) => {

                      props.setBody(e)
                      setFieldValue("body", props.body)


                    }}
                    onBlur={handleBlur('body')}
                  />

                </View>

              </View>
              <View style={basicStyles.imageContainer}>
                <Text style={basicStyles.sectionTitle}>画像</Text>
                <Pressable>
                  {({ hovered }) => (
                    <View>
                      <ThankYouImageFunc
                        value={props.thankYouMessageImage}
                        activistId={props.activistId}
                        setValue={props.setThankYouMessageImage}
                        hover={hovered}
                        error={errors.thankYouMessageImage ? errors.thankYouMessageImage : ""}
                      />
                    </View>

                  )}
                </Pressable>

              </View>
            </View>
            <View style={basicStyles.btnContainer}>
              <View style={basicStyles.deleteBtnContainer}>
                {props.label === 'create' ? (
                  <View />
                ) : (
                  <Pressable>
                    {({ hovered }) => {
                      if (hovered) {
                        return (
                          <Button onPress={() => deleteReport()} buttonType={17}>
                            削除する
                          </Button>
                        )
                      } else {
                        return (
                          <Button onPress={() => deleteReport()} buttonType={10}>
                            削除する
                          </Button>
                        )
                      }
                    }}
                  </Pressable>
                )}
              </View>
              <View>

                <View style={basicStyles.rightBottomButtonContainer}>

                  {!props.isSent && <Pressable>


                    {data => {

                      return <View>{data.hovered ? <Button buttonType={19} onPress={() => returnHome()}>
                        戻る
                      </Button> : <Button buttonType={11} onPress={() => returnHome()}>
                        戻る
                      </Button>}</View>

                    }}
                  </Pressable>}





                  <View>
                    <Pressable style={basicStyles.submitButton}>
                      {data => {

                        return <View>{data.hovered ? <Button buttonType={3} onPress={handleSubmit}>{props.isSent ? "保存する" : "保存して次へ"}</Button> : <Button buttonType={1} onPress={handleSubmit}>
                          {props.isSent ? "保存する" : "保存して次へ"}

                        </Button>}</View>

                      }}
                    </Pressable>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  checkbox: {
    alignSelf: 'center',
    marginRight: 11,
  },
  bodyContainer: {
    minWidth: 610
  },
  hovered: {
    borderColor: '#999999',
    width: 180,
    height: 30,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
  },
  notHovered: {
    borderColor: '#DDDDDD',
    width: 180,
    height: 30,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    width: width * 0.77,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: 40,
    marginBottom: 35,
  },
  card: {
    backgroundColor: 'white',
    width: width * 0.85,

    height: 1000,
  },
  cardBody: {
    flexDirection: 'row',
  },
  titleTextField: {
    borderWidth: 1,
    height: 40,
    marginLeft: 34,
    borderColor: '#DDDDDD',
  },

  forSupporter: {
    fontSize: 11,
    fontWeight: 'bold',
    textAlign: 'center',
  },

  image: {
    width: 50,
    height: 50,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  imageText: {
    color: '#999',
    width: 250,
    fontSize: 12,
  },
  buttonContainer: {
    width: 200,
    alignItems: 'center',
  },
  buttonText: {
    textAlign: 'center',
    color: '#4C64FF',
    padding: 15,
    marginLeft: 1,
    marginRight: 1,
    width: 198,
  },
})
