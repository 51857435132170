import React, { useState } from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { TextInput } from 'app/src/components/basics/text-input'
import { styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { Hoverable } from 'react-native-web-hover'
import { Text, Button } from 'app/src/components/basics'
import { Formik } from 'formik';
import * as Yup from "yup";
import { forgetPassword } from 'app/src/libs/firebase'
import { goToActivistSignin, goToSentForgetPassword } from 'app/src/libs/screen-transition'
import { useNavigation } from 'app/src/hooks/use-navigation'
import { goToActivistSignup } from 'app/src/libs/screen-transition'






export const ForgetPasswordScreen = () => {


    const [email, setEmail] = useState<string>("")
    const navigation = useNavigation()



    const save = async () => {


        let url = ''

        if (typeof window !== 'undefined') {
            url = window.location.protocol + '//' + window.location.host
        }


        forgetPassword(email, url)
            .then((res: any) => {
                if (res.success) {
                    // goToSentMail(router)
                    goToSentForgetPassword(navigation)
                }
            })
            .catch(error => {
                console.log("error", error)
                alert('入力内容に不備があります')
            })


    }

    const validation = () =>
        Yup.object().shape({
            email: Yup.string()
                .email("メールアドレスの形式で入力してください")
                .required("必須項目です"),


        });



    return (

        <View>
            <TouchableOpacity onPress={() => goToActivistSignup(navigation)}>
                <View>
                    <Text style={basicStyles.goToActivistSignup}>初回登録の方はこちら　＞</Text>
                </View>
            </TouchableOpacity>
            <View style={basicStyles.entireContainer}>
                <View style={basicStyles.passwordContainer}>


                    <Formik
                        initialValues={{
                            email: email,
                        }}
                        enableReinitialize={true}
                        validationSchema={validation()}
                        onSubmit={() => save()}
                    >
                        {({ setFieldValue, handleChange, handleBlur, handleSubmit, values, errors }) => (

                            <View>

                                <Text style={basicStyles.cardTitle}>パスワードの再設定メールを送信</Text>




                                <View >

                                    <View style={basicStyles.sectionContainer}>
                                        <TextInput
                                            onBlur={handleBlur('email')}
                                            label={'メールアドレス'}
                                            multiple={6}
                                            value={email}
                                            type={"email"}
                                            error={errors.email ? errors : ""}


                                            onChangeText={async (e: any) => {
                                                setFieldValue("email", e)
                                                setEmail(e)

                                            }}

                                        />
                                    </View>
                                </View>
                                <Hoverable>
                                    {({ hovered }) => (
                                        <View style={basicStyles.saveBtnContainer}>
                                            <Button style={basicStyles.saveBtn} buttonType={hovered ? 3 : 1} onPress={handleSubmit}>
                                                保存する
                                            </Button>
                                        </View>
                                    )}
                                </Hoverable>

                            </View>

                        )}
                    </Formik>
                </View>
            </View>
            <TouchableOpacity onPress={() => goToActivistSignin(navigation)}>


                <Text style={basicStyles.login}>
                    ログインに戻る場合はこちら

                </Text>

            </TouchableOpacity>
        </View>

    )
}



export default ForgetPasswordScreen
