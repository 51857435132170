import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Text, Button } from 'app/src/components/basics'
import { StyleSheet, View, Dimensions,ActivityIndicator } from 'react-native'
import { Hoverable, Pressable } from 'react-native-web-hover'
import { TextInput } from 'app/src/components/basics/text-input'
import { styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { primaryColor } from 'app/src/constants/colors'

import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,

} from 'firebase/auth'
import { Formik } from 'formik';
import * as Yup from "yup";

// import { Formik } from 'formik';
// import * as Yup from "yup";



const { width, height } = Dimensions.get('window')

interface Props {
  activistId: string
  newPassword: string
  confirmNewPassword: string
  currentPassword: string
  oldPassword: string
  email: string
  setNewPassword: (newPassword: string) => void
  setConfirmNewPassword: (confirmNewPassword: string) => void
  setCurrentPassword: (currentPassword: string) => void
  setOldPassword: (v: string) => void
}

export const ResetPasswordCard = forwardRef((props: Props, ref) => {


  const [isLoading,setIsLoading] = useState<boolean>(false)



  useImperativeHandle(
    ref,
    () => ({
      save() {
        save()
      }
    }),
  )


  const validation = () =>
    Yup.object().shape({
      oldPassword: Yup.string().required("必須項目です").matches(/^[0-9a-zA-Z]+$/, '半額英数字で入力してください').min(8, '8文字以上入力してください'),
      newPassword: Yup.string().required("必須項目です").matches(/^[0-9a-zA-Z]+$/, '半額英数字で入力してください').min(8, '8文字以上入力してください'),
      confirmNewPassword: Yup.string().required("必須項目です").matches(/^[0-9a-zA-Z]+$/, '半額英数字で入力してください').min(8, '8文字以上入力してください'),


    });

  const save = async () => {


    const user = getAuth().currentUser

    setIsLoading(true)


    if (user) {

      const credential = EmailAuthProvider.credential(props.email, props.oldPassword)
      reauthenticateWithCredential(user, credential).then(async () => {


        if (props.newPassword && props.confirmNewPassword) {

          if (props.newPassword == props.confirmNewPassword) {



            if (user) {

              try {

                await updatePassword(user, props.newPassword)
                alert("パスワードを変更しました")

              } catch (error) {

                alert(JSON.stringify(error))

              }



            }


          } else {

            alert("パスワードが一致していません")

          }

        }

            setIsLoading(false)



      }).catch(() => {



        alert("パスワードが違います")
            setIsLoading(false)

      })

    }


  }




  return (
    <Formik
      initialValues={{
        oldPassword: props.oldPassword,
        newPassword: props.newPassword,
        confirmNewPassword: props.confirmNewPassword

      }}
      enableReinitialize={true}
      validationSchema={validation()}
      onSubmit={values => save()}
    >
      {({ setFieldValue, handleChange, handleBlur, handleSubmit, values, errors }) => (

        <View style={basicStyles.myPageCard}>
          <View >
            <Text style={basicStyles.cardTitle}>パスワード変更</Text>

          </View>
          <View style={basicStyles.sectionContainer}>
            <TextInput
              error={errors.oldPassword ? errors : ""}
              type={"oldPassword"}
              multiple={8}
              value={props.oldPassword}
              label="現在のパスワード"
              onChangeText={async (e: any) => {

                props.setOldPassword(e)
                setFieldValue("oldPassword", e)


              }}
              onBlur={handleBlur('oldPassword')}
            />


          </View>
          <View style={basicStyles.sectionContainer}>
            <TextInput
              error={errors.newPassword ? errors : ""}
              type={"newPassword"}
              multiple={8}
              value={props.newPassword}
              label="現在のパスワード"
              onChangeText={async (e: any) => {

                props.setNewPassword(e)
                setFieldValue("newPassword", e)


              }}
              onBlur={handleBlur('newPassword')}
            />

          </View>
          <View style={basicStyles.sectionContainer}>
            <TextInput
              error={errors.confirmNewPassword ? errors : ""}
              type={"confirmNewPassword"}
              multiple={8}
              value={props.confirmNewPassword}
              label="新しいパスワード（確認）"
              onChangeText={async (e: any) => {

                props.setConfirmNewPassword(e)
                setFieldValue("confirmNewPassword", e)


              }}
              onBlur={handleBlur('confirmNewPassword')}
            />

          </View>



          <Pressable>
            {({ hovered }) => (
              <View>
                {isLoading?
                   <ActivityIndicator style={styles.loading} size="large" color={primaryColor} />


                :<Button buttonType={hovered ? 3 : 1} style={basicStyles.saveBtn} onPress={handleSubmit}>
                保存する
              </Button>}



              </View>

            )}
          </Pressable>
        </View>

      )}
    </Formik>
  )
})

const styles = StyleSheet.create({
  saveBtnContainer: {
    left: 0,
  },
  basicTextField: {
    borderColor: '#DDDDDD',
    borderWidth: 1,
    maxWidth: 310,
    height: 40,
    marginBottom: 20,
  },
  mypageContent: {
    height: height * 0.7,
    backgroundColor: 'white',
  },
  passwordText: {
    marginTop: 48,
    marginBottom: 20,
  },
  saveBtn: {
    right: 0,
    position: 'absolute',
    bottom: 0,
  },

  cardTitleContainer: {

  },
  passwordBtn: {
    width: 260,
    height: 50,
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: 20,
  },
  loading: {
    margin: '0 auto',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000,
    right: 0,
    position: 'absolute',
    bottom: 0,
    marginRight:30
  },
})
