import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { StyleSheet, View, Image, Dimensions, TouchableOpacity, ImageBackground, Linking } from 'react-native'
import { Text, Button } from 'app/src/components/basics'
import { TextInput } from 'app/src/components/basics/text-input'
import * as ImagePicker from 'expo-image-picker'
import { Card, Title, Paragraph } from 'react-native-paper'

const { width, height } = Dimensions.get('window')

export const CreateStripeAccountCard: React.FC = () => {
  const goToStripe = () => {
    Linking.openURL(
      `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${process.env.HOST}/connect&client_id=${process.env.CLIENTID}&state={STATE_VALUE}&stripe_user[business_type]=company`,
    )
  }
  return (
    <View style={styles.mypageContent}>
      <Text style={styles.stripeTitle}>Stripeのアカウント作成</Text>

      <View style={styles.stripeBodyContainer}>
        <Text style={styles.stripeBody}>
          Shopifyや他社サイトで発生した支援は銀行振込となりますが、meguのサービスサイト内で発生した支援についてはStripe経由での送金となります。そのため事前のStripeアカウント作成をお願いいたします。
        </Text>
      </View>
      <View>
        <Button buttonType={1} onPress={() => goToStripe()}>
          Stripeのアカウント作成
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#F4F4F4',
    paddingBottom: height * 0.2,
  },
  stripeTitle: {
    fontSize: 24,
    marginTop: 190,
    textAlign: 'center',
    marginBottom: 64,
  },
  stripeBody: {
    fontSize: 18,
    width: 480,
    color: '#999999',
  },
  stripeBodyContainer: {
    width: 480,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 80,
  },
  saveBtn: {
    right: 0,
    position: 'absolute',
    bottom: 0,
  },
  bell: {
    height: width * 0.02,
    width: height * 0.03,
  },
  rightIcon: {
    flexDirection: 'row',
    marginEnd: width * 0.1,
  },
  mypageContent: {
    width: width * 0.85,
    height: 600,
    backgroundColor: 'white',
  },
  mypageItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  announceLogo: {
    width: width * 0.2,
  },
  headerItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerFourItems: {
    flexDirection: 'row',
  },
  selectedMenu: {
    backgroundColor: 'white',
    padding: 50,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notSelectedMenu: {
    padding: 50,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    position: 'absolute',
    left: -(width * 0.147),
    right: 0,
    top: 0,
    bottom: 0,
    height: 3,
    marginTop: 31,
    width: width / 10,
  },

  logout: {
    marginTop: height * 0.07,
  },
  backgroundImageContent: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  backgroundImage: {
    width: '110%',
    height: '100%',
  },
  logoContainer: {
    backgroundColor: '#DDDDDD',
    width: '30%',
    height: '30%',
  },
})
