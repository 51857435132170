import React, { forwardRef, useEffect, useImperativeHandle,useState } from 'react'
import { StyleSheet, View, Dimensions,Platform,Image } from 'react-native'
import { Text, Button } from 'app/src/components/basics'
import axios from 'axios'
import { ActivistIcon } from './ActivistIcon'
import { ActivistKakuin } from './ActivistKakuin'
import { Hoverable, Pressable } from 'react-native-web-hover'
import { Loader, styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { getRef, uploadStringStorage, createRelatedActivist } from 'app/src/libs/firebase'
import { pdfjs } from 'react-pdf'

import { TextInput } from 'app/src/components/basics/text-input'
import { db } from 'app/src/libs/firebase'
import {  doc, setDoc } from 'firebase/firestore'
import { Formik } from 'formik';
import * as Yup from "yup";
import { getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid';





const { width, height } = Dimensions.get('window')

interface Props {
  activistId: string
  imageUrl: string
  kakuin: string
  activistName: string
  postalCode: string
  town: string
  phoneNumber: string
  isApproved?: boolean
  stripeAccountId: string
  title: string
  isSetUp?: boolean
  building: string
  activistNameFurigana: string
  establishmentDate: string
  representative: string
  email: string
  proofImage:string
  originalUid:string
  activistNumber:string
  activistAdmittedDate:string
  proviso:string
  relatedActivists:{ label:string;value:string}[]
  number?:number
  setNumber?:(v:number)=>void


  setImageUrl: (logo: string) => void
  setActivistName: (activistName: string) => void
  setPostalCode: (postalCode: string) => void
  setPhoneNumber: (phoneNumber: string) => void
  setKakuin: (kakuin: string) => void
  setIsSetUp?: (isSetUp: boolean) => void
  setEstablishmentDate: (establishmentDate: string) => void
  setRepresentative: (representative: string) => void
  setBuilding: (v: string) => void
  setTown: (town: string) => void
  setActivistNameFurigana: (v: string) => void
  setActivistId:(v:string)=>void
  setEmail: (v: string) => void
  setProofImage: (v: string) => void
  setActivistNumber:(v: string) => void
  setActivistAdmittedDate:(v: string) => void
  setProviso:(v: string) => void
  setRelatedActivists:(v:{ label:string;value:string}[]) => void

  // setStatus?;




}


export const BasicInfoCard = forwardRef((props: Props, ref) => {

  const [isPdf,setIsPdf] = useState<boolean>(false)
  const [newActivistName,setNewActivistName] = useState<string>("")
  const [isLoading,setIsLoading] = useState<boolean>(false)



  useImperativeHandle(
    ref,
    () => ({
      save() {
        save()
      }
    }),
  )



  const saveBasicInfo = async () => {

    try {
      const ref = doc(db, 'activists', props.activistId)

      await setDoc(ref, {
        activistName: props.activistName,
        postalCode: props.postalCode,
        town: props.town,
        building: props.building,
        phoneNumber: props.phoneNumber,
        imageUrl: props.imageUrl,
        kakuin: props.kakuin,
        establishmentDate: props.establishmentDate,
        representative: props.representative,
        activistNameFurigana: props.activistNameFurigana,
        email: props.email,
        proofImage:props.proofImage,
        activistNumber:props.activistNumber,
        activistAdmittedDate:props.activistAdmittedDate,
        proviso:props.proviso,



      },{merge:true})
      alert('内容を保存しました')

    } catch (error) {

      console.log("error",error)

    }

  }

  const save = async () => {

    const ref = doc(db, 'activists', props.activistId)

    try {

    await setDoc(ref, {
      activistName: props.activistName,
      postalCode: props.postalCode,
      town: props.town,
      building: props.building,
      phoneNumber: props.phoneNumber,
      imageUrl: props.imageUrl,
      kakuin: props.kakuin,
      establishmentDate: props.establishmentDate,
      representative: props.representative,
      activistNameFurigana: props.activistNameFurigana,
      email: props.email,
      proof:props.proofImage,
      activistNumber:props.activistNumber,
      activistAdmittedDate:props.activistAdmittedDate,
      proviso:props.proviso,



    },{merge:true})


    } catch (error) {

      console.log("error",error)

    }



  }


  const isNumber = n => {
    const v = n - 0
    if (v || v === 0) {
      return true
    }
    return false
  }



  const apply = async () => {
    const ref = doc(db, 'activists', props.activistId)

    await setDoc(ref, {
      activistName: props.activistName,
      postalCode: props.postalCode,
      town: props.town,
      building: props.building,
      phoneNumber: props.phoneNumber,
      imageUrl: props.imageUrl,
      kakuin: props.kakuin,
      establishmentDate: props.establishmentDate,
      representative: props.representative,
      activistNameFurigana: props.activistNameFurigana,
      email: props.email,
      proofImage:props.proofImage,
      activistNumber:props.activistNumber,
      activistAdmittedDate:props.activistAdmittedDate,
      proviso:props.proviso,

    },{merge:true}).then(async () => {
      // エラーの原因
      const BASE_URL = process.env.SLACK_URL as string
      const options: any = {
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        data: `payload={
                    "channel": "#notification-apply",
                    "username": "notification",
                    "text": "<!channel>\n団体名:${props.activistName}\n団体ID:${props.activistId}\n${props.activistName}が申請しています。以下で申請を完了してください。\nhttps://docs.google.com/forms/d/e/1FAIpQLSdRZUphYNIip2z78QGalpFcwf22ZmbSRux3rd53henCF2UOug/viewform?entry.210035150=${props.activistId}"
                }`,
      }
      await axios.request(options)
      props.setIsSetUp?.(true)
    })
  }

  const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const uploadPdf = async e => {

    setIsPdf(true)
    const file = e.target.files[0]

    const res = String(await toBase64(file))

    const pdfData = atob(res.split('base64,')[1])

    const loadingTask = pdfjs.getDocument({ data: pdfData })

    loadingTask.promise.then(pdf => {
      pdf.getPage(1).then(page => {
        // console.log("page", page);

        var scale = 1.5
        var viewport = page.getViewport({ scale: scale })

        var canvas:any = document.getElementById('canvas')
        if(!canvas) return
        var context = canvas.getContext('2d')
        canvas.style.display = "none";

        canvas.height = viewport.height
        canvas.width = viewport.width


        var renderContext = {
          canvasContext: context,
          viewport: viewport,
        }
        var renderTask = page.render(renderContext)
        renderTask.promise.then(async function () {

          var data = canvas?.toDataURL('image/jpeg')

          const storageRef = getRef(`images/activists/${props.activistId}/proof.jpeg`)

          const result = await uploadStringStorage(storageRef, data, 'data_url')



          const url = await getDownloadURL(result.ref)

          props.setProofImage(url)

          const pdfRef = getRef(`images/activists/${props.activistId}/proof.pdf`)

          await uploadStringStorage(pdfRef, res, 'data_url')


          setIsPdf(false)







        })
      })
    })
  }


  const validation = () =>
    Yup.object().shape({
      email: Yup.string()
        .email("メールアドレスの形式で入力してください")
        .required("必須項目です"),
      activistName: Yup.string()
        .required("必須項目です"),
      activistNameFurigana: Yup.string()
        .required("必須項目です"),
      representative: Yup.string()
        .required("必須項目です"),
      phoneNumber: Yup.string()
        .required("必須項目です"),
      postalCode: Yup.string()
        .required("必須項目です"),
      town: Yup.string()
        .required("必須項目です"),
      establishmentDate: Yup.string()
        .required("必須項目です"),
      activistNumber: Yup.string()
      .required("必須項目です"),
      activistAdmittedDate: Yup.string()
      .required("必須項目です"),
      proviso:Yup.string()
      .required("必須項目です"),


    });






  return (
    <Formik
      initialValues={{
        activistName: props.activistName,
        activistNameFurigana: props.activistNameFurigana,
        representative: props.representative,
        phoneNumber: props.phoneNumber,
        postalCode: props.postalCode,
        town: props.town,
        building: props.building,
        establishmentDate: props.establishmentDate,
        email: props.email,
        activistNumber:props.activistNumber,
        activistAdmittedDate:props.activistAdmittedDate,
        proviso:props.proviso,
      }}
      enableReinitialize={true}
      validationSchema={validation()}
      onSubmit={(values) => {


        if (props.title) {

          apply()

        } else {
          saveBasicInfo()


        }


      }


      }
    >
      {({ setFieldValue, handleChange, handleBlur, handleSubmit, values, errors }) => (

        <View style={props.title ? basicStyles.basicInfoPageCard : basicStyles.myPageCard}>
          {props.title ? <Text style={styles.cardTitle}>{props.title}</Text> : <Text style={styles.cardTitle}>基本情報の設定</Text>}



  {props.title?<></>:<>

<View>
  <View style={{
    marginRight:20,
    flexDirection: 'row',
    alignItems:"center",

    }}>
      <View
      style={{
        width:300
      }}
      >
      <TextInput

label={'新しい団体の追加'}
multiple={2}
value={newActivistName}

onChangeText={async (e: any) => {

  setNewActivistName(e)


}}


/>

      </View>

<View>


  {isLoading?
  <View
  style={styles.relatedLoader}
  >
    <Loader/>
  </View>
  :<>
    <Button
  style={{marginTop:10,marginLeft:10}}
    onPress={async()=>{

      setIsLoading(true)
      const uuid = uuidv4()
      await createRelatedActivist(Platform.OS,props.email,uuid,newActivistName)

      const array = props.relatedActivists

      const v = {label:newActivistName,value:uuid}
      const newArray = [...array,v]
      props.setRelatedActivists([...newArray])
      setNewActivistName("")

      if(props.number != undefined){
        props.setNumber?.(props.number+1)

      }


      // props.setActivistId(uuid)

      setIsLoading(false)



  }}>
  関連のNPOを追加する

</Button>
  </>}





</View>

  </View>

  <View>


{props.relatedActivists&&props.relatedActivists.map((v,i)=>{
    return <Text>{v.label}</Text>
  })}









  </View>


</View>
  </>}




          <View style={styles.mypageItems}>
            <View>
              <ActivistIcon setImageUrl={props.setImageUrl} activistId={props.activistId} imageUrl={props.imageUrl} />
            </View>

            <View>


              <View >

              <ActivistKakuin kakuin={props.kakuin} activistId={props.activistId} setKakuin={props.setKakuin} />


              </View>





              {props.title && (
                <View>
                  <Button style={styles.applyBtn} buttonType={1} onPress={handleSubmit}>
                    申請する
                  </Button>
                </View>
              )}
            </View>
            <View style={{marginTop:20}}>

              <View>

              <Text >税額控除に係る証明書</Text>

<View style={{flexDirection:"row"}}>
<Hoverable>
                              {({ hovered }) => (
                           <View >
                           {isPdf ? <View style={styles.loader}> <Loader /> </View> : <Hoverable>
                             <View style={hovered ? styles.hovered : styles.notHovered}>
                               <label>
                                 PDFをアップロード
                                 <input
                                   onChange={e => uploadPdf(e)}
                                   accept="application/pdf"
                                   type="file"
                                   id="file_upload"
                                   style={{ display: 'none' }}
                                 />
                               </label>
                             </View>
                         </Hoverable>}

                         </View>
                              )}
                            </Hoverable>

<View style={{marginLeft:60}}>
                          {props.proofImage&&   <Image
                          width={70}
                          height={100}
                          source={{uri:props.proofImage}} style={{width:70,height:100}}
                          />}

                         </View>
</View>


                            <canvas id="canvas"></canvas>


              </View>


            </View>
          </View>
          <View style={styles.mypageItems}>
          <View>
          <View>
              <View style={basicStyles.sectionContainer}>
                <TextInput
                  label={'法人名'}
                  multiple={2}
                  onBlur={handleBlur('activistName')}
                  value={props.activistName}
                  error={errors.activistName ? errors : ""}
                  type={"activistName"}
                  onChangeText={async (e: any) => {
                    props.setActivistName(e)
                    setFieldValue("activistName", e)
                  }}

                />
              </View>


<View style={basicStyles.sectionContainer}>
  <TextInput
    label={'設立日'}
    multiple={4}
    error={errors.establishmentDate ? errors : ""}

    style={styles.basicTextField}
    onBlur={handleBlur('establishmentDate')}
    value={props.establishmentDate}
    type={"establishmentDate"}


    onChangeText={async (e: any) => {

      props.setEstablishmentDate(e)
      setFieldValue("establishmentDate", e)


    }}

  />
</View>

<View style={basicStyles.sectionContainer}>
                <TextInput
                  label={'電話番号'}
                  multiple={6}
                  style={styles.basicTextField}
                  type={"phoneNumber"}
                  error={errors.phoneNumber ? errors : ""}

                  onChangeText={async (e: any) => {

                    if (isNumber(e)) {
                      props.setPhoneNumber(e)
                      setFieldValue("phoneNumber", e)

                    }
                  }}
                  onBlur={handleBlur('phoneNumber')}
                  value={props.phoneNumber}
                />
              </View>

              <View style={basicStyles.sectionContainer}>
                <TextInput
                  label={'メールアドレス'}
                  multiple={6}
                  style={styles.basicTextField}
                  type={"email"}
                  error={errors.email ? errors : ""}

                  onChangeText={async (e: any) => {

                    props.setEmail(e)
                    setFieldValue("email", e)


                  }}
                  onBlur={handleBlur('email')}
                  value={props.email}
                />
              </View>
</View>
          </View>
          <View>
          <View style={basicStyles.sectionContainer}>
                <TextInput
                  label={'郵便番号(ハイフンなし半角数字)'}
                  multiple={6}
                  type={"postalCode"}
                  error={errors.postalCode ? errors : ""}

                  onChangeText={async (e: any) => {


                    setFieldValue("postalCode", e)
                    props.setPostalCode(e)


                    if (isNumber(e)) {
                      if (e.length <= 7) {
                        if (e.length === 7) {
                          const zipcloudURL = 'https://zipcloud.ibsnet.co.jp/api/search'
                          const response = await axios.get(`${zipcloudURL}?zipcode=${e}`)
                          const results = response.data.results[0]
                          const firstAddress = results['address1'] + results['address2']
                          const secontAddress = results['address3']
                          setFieldValue("town", firstAddress + secontAddress)
                          props.setTown(firstAddress + secontAddress)
                        }


                      }
                    }


                  }}
                  onBlur={handleBlur('postalCode')}
                  value={props.postalCode}

                />


              </View>
              <View style={basicStyles.sectionContainer}>
                <TextInput label={'住所'} multiple={2}
                  onChangeText={async (e: any) => {

                    props.setTown(e)
                    setFieldValue("town", e)


                  }}
                  onBlur={handleBlur('town')}
                  error={errors.town ? errors : ""}

                  value={props.town}
                  type={"town"}
                />
              </View>
              <View style={basicStyles.sectionContainer}>
                <TextInput
                  label={'住所(建物・部屋番号)'}
                  multiple={2}
                  error={errors.building ? errors : ""}

                  style={styles.basicTextField}

                  onChangeText={async (e: any) => {

                    props.setBuilding(e)
                    setFieldValue("building", e)


                  }}
                  onBlur={handleBlur('building')}
                  value={props.building}
                  type={"building"}
                />
              </View>


              <View style={basicStyles.sectionContainer}>
                <TextInput
                  label={'但し書き入力欄'}
                  multiple={6}
                  style={styles.basicTextField}
                  type={"proviso"}
                  error={errors.proviso ? errors : ""}

                  onChangeText={async (e: any) => {

                    // props.setEmail(e)
                    props.setProviso(e)
                    setFieldValue("proviso", e)


                  }}
                  onBlur={handleBlur('proviso')}
                  value={props.proviso}
                />
              </View>

          </View>

          <View>
          <View style={basicStyles.sectionContainer}>
                <TextInput
                  label={'認定通知書の番号'}
                  multiple={2}
                  style={styles.basicTextField}
                  onBlur={handleBlur('activistNumber')}
                  value={props.activistNumber}
                  error={errors.activistNumber ? errors : ""}
                  type={"activistNumber"}

                  onChangeText={async (e: any) => {

                    props.setActivistNumber(e)
                    setFieldValue("activistNumber", e)


                  }}

                />
              </View>
              <View style={basicStyles.sectionContainer}>
                <TextInput
                  label={'認定年月日'}
                  multiple={4}
                  style={styles.basicTextField}
                  onBlur={handleBlur('activistAdmittedDate')}
                  value={props.activistAdmittedDate}
                  error={errors.activistAdmittedDate ? errors : ""}
                  type={"activistAdmittedDate"}

                  onChangeText={async (e: any) => {

                    props.setActivistAdmittedDate(e)
                    setFieldValue("activistAdmittedDate", e)


                  }}

                />
              </View>


                            <View style={basicStyles.sectionContainer}>
                <TextInput
                  label={'代表者'}
                  multiple={2}
                  style={styles.basicTextField}
                  onBlur={handleBlur('representative')}
                  value={props.representative}
                  error={errors.representative ? errors : ""}
                  type={"representative"}

                  onChangeText={async (e: any) => {

                    props.setRepresentative(e)
                    setFieldValue("representative", e)


                  }}

                />
              </View>






          </View>




          </View>



          {props.isSetUp && (
            <Hoverable>
              {({ hovered }) => (
                  <Button style={styles.saveBtn} buttonType={hovered ? 3 : 1} onPress={handleSubmit}>
                    保存する
                  </Button>
              )}
            </Hoverable>
          )}


        </View>

      )}
    </Formik>

  )
})

const styles = StyleSheet.create({

  relatedLoader:{
    marginTop:10,
    marginLeft:10,
    width:260,
    textAlign: 'center',
    height: 50,


  },
  container: {
    flexDirection: 'row',
    backgroundColor: '#F4F4F4',
    paddingBottom: height * 0.2,
  },
  logoAnnotationText: { width: 300, color: '#999999', textAlign: 'center' },
  proofContainer :{
    marginTop:52
  },
  newActivistNameContainer:{


  },

  basicTextField: {
    borderColor: '#DDDDDD',
    borderWidth: 1,
    width: 310,
    height: 40,
    marginBottom: 20,
  },
  postalCodeTextField: {
    borderColor: '#DDDDDD',
    borderWidth: 1,
    width: 130,
    height: 40,
    marginBottom: 20,
  },
  saveBtn: {
    right: 20,
    position: 'absolute',
    bottom: -40,
  },
  saveBtnContainer: { marginBottom: 20 },
  applyBtn: {
    marginTop: 20,
  },
  bell: {
    height: width * 0.02,
    width: height * 0.03,
  },
  rightIcon: {
    flexDirection: 'row',
    marginEnd: width * 0.1,
  },
  mypageContent: {
    width: width * 0.85,
    backgroundColor: 'white',
  },
  mypageItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 1050,

  },
  hovered: {
    borderColor: '#999999',
    width: 250,
    height: 50,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {

    width: 250,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',

  },
  notHovered: {
    borderColor: '#DDDDDD',
    width: 250,
    height: 50,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  announceLogo: {
    width: width * 0.2,
  },
  underAnnounceLogo: {
    width: 260,
    color: '#999999',
  },
  headerItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerFourItems: {
    flexDirection: 'row',
  },
  selectedMenu: {
    backgroundColor: 'white',
    padding: 50,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notSelectedMenu: {
    padding: 50,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    position: 'absolute',
    left: -(width * 0.147),
    right: 0,
    top: 0,
    bottom: 0,
    height: 3,
    marginTop: 31,
    width: width / 10,
  },

  logout: {
    marginTop: height * 0.07,
  },
  backgroundImageContent: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  backgroundImage: {
    width: '110%',
    height: '100%',
  },
  logoContainer: {
    backgroundColor: '#DDDDDD',
    width: '30%',
    height: '30%',
  },
  cardTitle: {

    fontWeight: 'bold',
    fontSize: 18,
  },
  logoText: {
    fontSize: 10,
    marginTop: 21,
  },
  kakuinText: {
    fontSize: 10,
    marginTop: 21,
  },
  formStyle: {},
  sectionText: {
    fontSize: 10,
  },
})
