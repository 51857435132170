import React, { useCallback } from 'react'
import { StyleSheet, View, Image } from 'react-native'
import { useDropzone } from 'react-dropzone'
import { images } from 'app/src/libs/images'
import { Hoverable, Pressable } from 'react-native-web-hover'
import { styles as basicStyles, ThankYouImageFunc } from 'app/src/components/advanced/activist/basic'
import { getRef, uploadStorage, getDownload } from 'app/src/libs/firebase'
import { Text } from 'app/src/components/basics'
import { db } from 'app/src/libs/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { getDownloadURL } from 'firebase/storage'
import imageCompression from 'browser-image-compression'


interface Props {
  kakuin: string
  activistId: string
  setKakuin: (logo: string) => void
}

export const ActivistKakuin: React.FC<Props> = props => {
  const onDrop = useCallback(
    async files => {
      const file = files[0]

      const date = new Date()

      const compressFile = await getCompressImageFile(file)



      const timeStamp = date.getTime()
      const timeStampFileName = `${timeStamp}-kakuin`

      const storageRef = getRef(`images/activists/${props.activistId}/${timeStampFileName}`)


      const uploadTask = await uploadStorage(storageRef, compressFile)

      const url = await getDownload(uploadTask.ref)

      props.setKakuin(url)


      const ref = doc(db, 'activists', props.activistId)


      await updateDoc(ref, {
        kakuin: url,
      })

    },
    [props.activistId],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg,image.jpg, image/png' })


  const getCompressImageFile = async (file) => {
    const options = {
      maxSizeMB: 0.8, //最大ファイルサイズ
      maxWidthOrHeight: 600, //最大縦横値
    }
    return await imageCompression(file, options)
  }

  return (
    <View>
      <View style={styles.kakuinTitle}>
        <Text style={basicStyles.sectionTitle}>領収書用の角印設定</Text>
      </View>

      <Hoverable>
        {({ hovered }) => (
          <View style={hovered ? basicStyles.imageOpacity : {}}>
            {props.kakuin ? (
              <div {...getRootProps()} style={{
                width: 250, height: 250,

              }}>
                <Image height={250} width={250} source={{ uri: props.kakuin }} style={styles.kakuin} />
                <input {...getInputProps()} />
              </div>
            ) : (
              <div
                {...getRootProps()}
                style={{
                  width: 250,
                  height: 250,

                  backgroundColor: '#DDDDDD',
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p></p>
                ) : (
                  <View
                    style={styles.kakuinTotal}
                  >
                    <View style={styles.kakuinContainer}>
                      <Image source={images['iconCamera']} style={styles.logo} />
                      <div
                        style={{
                          color: '#666666',
                          fontSize: 12,
                          width: 160,
                          textAlign: 'center',
                          margin: 'auto',
                          lineHeight: "14px",

                        }}
                      >
                        白い紙に角印単体の印影をここにドラッグ＆ドロップするか、クリックして画像を選択してください
                      </div>
                    </View>

                  </View>
                )}
              </div>
            )}


          </View>
        )}
      </Hoverable>

      <View >
        <View style={styles.kakuinTitle}>
          <Text type="annotation">※角印の画像をアップロードしてください </Text>
          <Text type="annotation">（推奨：JPEG、PNG、250px x 250px 以上）</Text>
        </View>

      </View>


    </View>
  )
}

const styles = StyleSheet.create({
  logo: {
    width: 50,
    height: 50,
    margin: 'auto',
    // marginTop: 90,
    marginBottom: 15,
    resizeMode: 'contain',

  },
  kakuinTotal: {

    display: "flex",
    alignItems: "center",
    height: "100%",
  },

  kakuinContainer: {
    margin: "auto",
    display: "flex",
    alignItems: "center",


  },
  kakuin: {
    width: 250,
    height: 250,
    resizeMode: 'contain',



  },
  logoContainer: {
    width: 250,
    height: 250,
    backgroundColor: '#DDDDDD',
    textAlign: "center"
  },
  imageText: {
    color: '#666666',
  },
  announceLogo: {
    width: 280,
    marginLeft: 50,
    marginTop: 10,
    textAlign: 'center',
    color: '#999999',
  },
  underAnnounceLogo: {
    width: 260,
    color: '#999999',
  },
  kakuinTitle: {

    width: 250,
    marginTop: 20,
    textAlign: "center"
  },
})
