import React from 'react'
import { StyleSheet, View, ScrollView } from 'react-native'

// constants
import { isWeb } from 'app/src/constants/platform'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { ReportCard, styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { ActivistMenu } from '../../advanced/activist/menu'
import { windowWidth, windowHeight } from 'app/src/constants/layout'


type ContainerProps = {}

type Props = {}

export const TermsScreen: React.FC<ContainerProps> = () => {
  return <UI />
}

const UI: React.FC<Props & ContainerProps> = () => {
  return (
    <>
      <View >
        <View style={basicStyles.container}>
          <View style={basicStyles.bodyContainer}>
            <View>
              <ActivistMenu />
            </View>

            <ScrollView style={styles.scrollContainer}>

              <View style={styles.headerContent}>
                <Text type={'xxlarge'} align={'center'} style={styles.titleWrapper}>
                  利用規約
                </Text>
                <Text type={'medium'} style={styles.content}>
                  本利用規約、その他株式会社beero（以下「当社」といいます。）が定める一切の規約・
                  規程（以下「本規約」と総称します。）は、当社が提供するサービス（当社のウェブサイ
                  トやブログ等を通じて提供するサービス、アプリケーションサービスその他全てのサービ
                  スを含み、また、理由の如何を問わずサービスの内容が変更された場合は、当該変更後の
                  サービスを含みます。以下「本サービス」といいます。）を利用（サイト閲覧、投稿、予
                  約、購入、決済、データ送信、アカウント登録を含みます。以下同様。）する全ての個人
                  又は法人（以下「ユーザー」といいます。）に適用されます。
                  本サービスをご利用頂くにあたっては、本規約の全文をお読み頂いたうえで、本規約の全
                  ての条項について承諾頂く必要があります。本サービスをご利用頂いた場合、利用者の方
                  が本規約の内容を理解しており、かつ、本規約の全ての条項について承諾したものとみな
                  します。

                </Text>
              </View>

              <View style={styles.mainContent}>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【用語の定義】
                  </Text>
                  <Text type={'medium'}>
                    「当社」とは、株式会社beeroをいいます。{'\n'}
                    「本webサイト」とは、https://megu.green及びそれに付随して当社が管理しているwebサイトをいいます。{'\n'}
                    「ユーザー」とは、本サービスを利用する全ての個人又は法人をいいます。{'\n'}
                    「支援会員」とは、本サービスを利用し、又は本サービスに登録し、非営利団体の支援（金銭的支援の有無を問わない）をする者をいいます。{'\n'}
                    「支援金」とは、本サービスを通じて、支援会員が団体等に寄付するお金をいいます。{'\n'}
                    「団体」とは、本サービスに登録している特定非営利団体、一般社団法人、公益法人等の民間非営利団体等であって、支援会員が支援をする団体等をいいます。{'\n'}
                    「コンペ」とは、本サービスが主催提供するゴルフコンペディションを指します。{'\n'}
                    「コンペ参加者」とは、本サービスが提供するコンペを利用する者をいいます。{'\n'}
                    「賞品」とは、コンペ参加者がコンペに参加して獲得した商品のことをいいます。{'\n'}
                    「賞品当選者」とは、コンペにおいて賞品の獲得権を得た者をいいます。{'\n'}

                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【目的】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本webサイトを通じ、ユーザーが本サービスを利用するにあたり適用される本規約を定めます。</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本webサイトは、アクション主催者、サポーター、団体向けにサービスを提供するプラ
                      ットフォームであり、アクション主催者、サポーター、団体等との間での一切のトラブ
                      ルに関しては、当該アクション主催者、当該サポーター、当該団体等の費用と責任にお
                      いてこれを解決するものとし、当社はかかるトラブルについて一切の補償をしません。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【本規約の変更】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、ユーザーに事前に通知することなく、本規約の全部又は一部を任意に変更す
                      ることができるものとし、また本規約を補充する規約を新たに定めることができるも
                      のとします。規約の変更・追加は、本サービスを提供する本ウェブサイトに掲載した
                      時点から効力を発するものとし、効力発生後に提供される各種サービスは、変更・追加後の規約に従うものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、ユーザーに事前に通知することなく、本規約の全部又は一部を任意に変更す
                      ることができるものとし、また本規約を補充する規約を新たに定めることができるも
                      のとします。規約の変更・追加は、本サービスを提供する本ウェブサイトに掲載した
                      時点から効力を発するものとし、効力発生後に提供される各種サービスは、変更・追加後の規約に従うものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【本規約への同意】
                  </Text>
                  <Text style={styles.textContent} type={'medium'}>
                    ユーザーは本webサイトにアクセスし、本サービスを利用した時点で本規約に同意したも
                    のとします。本規約に同意しない限り、ユーザーは本サービスを利用できないものとします。
                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【ユーザーの権利】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーが本規約を遵守している場合において、ユーザーは本サービスを使用する権
                      利がありますが、それは非独占的なもので、かつ譲渡不可のものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーは本サービスの、逆コンパイル、逆アセンブル、リバースエンジニアリング
                      その他の方法による解析等を行ってはならないものとします。これらに違反した場合
                      、当社は即座にユーザーによる本サービスの利用を停止することができ、当社はそれ
                      によりユーザーが被った損害を賠償する責任を一切有さないものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【登録】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵
                      守することに同意し、当社所定の手続によりアカウント登録の申込をすることができます。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      登録希望者は、アカウント登録の情報を虚偽なく正確に入力し、常に最新の情報とな
                      るよう修正するものとします。なお、メールアドレスは日常的に利用し、受信内容を日常的に確認できるものを利用するものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      第1項の登録希望者が以下の各号のいずれかの事由に該当すると当社がその裁量によ
                      って判断する場合（アカウント登録後に該当することが判明した場合を含みます
                      。）、アカウント登録・本サービスの利用継続を拒否することがあり、また、当社は、その理由について一切開示義務を負いません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>当社に提供した登録事項の全部又は一部につき虚偽、誤記又は記載漏れがあった場合</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本規約に定める禁止事項に違反する場合</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>過去に本規約に違反したことがある場合もしくは当社が不適切であると判断した者からの申込である場合</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>登録希望者が、当社、他のユーザーとの意思疎通が困難と認められる場合</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      登録希望者が未成年者、成年被後見人、被保佐人又は被補助人であり、法定代理人
                      、後見人、保佐人又は補助人の同意等を得ていなかった場合
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      登録希望者が反社会的勢力等（暴力団、暴力団員その他これらに準ずる者をいいま
                      す。以下同様。）である又は反社会的勢力等に資金提供をする等反社会的勢力等とらかの関与をしている場合
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>その他アカウント登録の申込の受付が不適切と当社が判断した場合</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      4.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      法人又は団体であるユーザーがアカウント登録をする場合、当該ユーザーは、当該法
                      人又は団体の正確な名称・所在地等の情報を入力して申込をするものとし（なお、当
                      該情報に変更が生じた場合、変更後の情報を当社に通知するものとし、当社が求めた
                      場合には、最新かつ正確な商号・本店所在地・電話番号を通知するものとします
                      。）、当該情報が不明確な場合、当該申込をした個人が当該アカウント登録をしたユ
                      ーザーとしての義務の一切を負担することに同意します。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      5.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーがアカウント登録をせず、本サービスの予約のために連絡先等の簡易的な報入力を行った場合でも、本規約が適用されます。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      6.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーは、自己の責任において、本サービスにかかるユーザーID及びパスワード（
                      以下「アカウント情報」といいます。）を管理及び保管するものとし、これを第三者
                      に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      7.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      アカウント情報の管理不十分、使用上の過誤、第三者の使用等による損害の責任はユ
                      ーザーが負うものとし、当社は一切の責任を負いません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      8.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーは、アカウント情報が盗まれ、又は第三者に使用されていることが判明した
                      場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【料金】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーは、本サービスの利用に関して、当社の「特定商取引法に基づく表記」にて
                      定める料金及び契約書、申込書等、当社とユーザーとの間で別途合意した料金（もしあれば）を支払うものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーが本サービスの利用に関する料金の支払を遅滞した場合、ユーザーは、年14.6%による遅延損害金を当社に支払うものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【寄付金】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>サポーターは、以下の方法で、団体に対して寄付金を支払うことができるものとします。</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      (1)
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      アクションの参加費のうちアクション主催者が別途指定する金額を、アクション主催者が別途指定する団体へ、寄付金として支払う方法
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      (2)
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      サポーターがアクション中又はアクション後に別途指定した金額を、アクション主催者が別途指定する団体へ、寄付金として支払う方法
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      (3)
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>サポーターが別途指定した金額を、サポーターが別途指定する団体へ、寄付金として支払う方法</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      前項の場合はいずれも、サポーターから団体に対する寄付金の支払事務について、当
                      社が団体に代わり寄付金を受領し、それを当社が団体に引渡すことにより行われるも
                      のとし、団体は、本規約に定めるところにより、当社に対して、サポーターからの寄
                      付金を団体に代理して受領する権限を付与したものとみなされます。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【アクションへの参加費】
                  </Text>
                  <Text style={styles.textContent} type={'medium'}>
                    サポーターは、アクション主催者に対するアクションの参加費から、アクション主催者が
                    当該アクションにおいて別途指定した寄付金の額を控除した額（以下「報酬額」といいま
                    す。）をアクション主催者に支払う必要があります。サポーターからアクション主催者に
                    対する報酬額の支払事務については、当社がアクション主催者に代わり報酬額を受領し、
                    それを当社がアクション主催者に引渡すことにより行われるものとし、アクション主催者
                    は、本規約に定めるところにより、当社に対して、サポーターからの報酬額をアクション
                    主催者に代理して受領する権限を付与したものとみなされます。
                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【アクションのキャンセル及び延期】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      サポーターはアクション開始時間の24時間前までアクションへの参加をキャンセルで
                      きるものとし、支払った参加費は当社より返金されます。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      サポーターが参加予定のアクションが延期になった場合でも、キャンセルできるのは
                      変更後のアクション開始時間の24時間前までとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      アクション主催者がすでに公開済みでサポーターが参加費を一人でも決済済みのアク
                      ションをキャンセルする場合、決済済みのサポーターの参加人数分の総額参加費の5%
                      をキャンセルフィーとして当社に支払うことでキャンセルが可能となります。天候の
                      影響で開催が困難なアクションの場合でもアクションの開催を延期するのではなく、
                      アクションの開催をキャンセルする場合は同上とします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      4.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      アクション主催者がすでに公開済みでサポーターが参加費を一人でも決済済みのアク
                      ションをキャンセルする場合、アクション開始時間の72時間前までに、その旨を当社
                      に通知する必要があります。もし72時間前までに通知を行わずに、アクションのキャ
                      ンセル及び、アクションの開催を行わなかった場合、決済済みのサポーターの参加人
                      数分の総額参加費の5%のキャンセルフィーと、損害賠償としての10万円を合算した額を当社に支払うものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      5.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      アクション主催者がすでに公開済みだが、まだ一人も参加費を決済済みのサポーター
                      が居ない場合は、キャンセルに際しキャンセルフィーがかかることなくキャンセルできるものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【領収書の発行】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスでは【寄付金】の１．（３）にあるサポーターが別途指定した金額を、サ
                      ポーターが別途指定する団体へ、寄付金として支払う方法の場合のみ領収書の発行を
                      請求できるものとし、【寄付金】の１．（１）（２）の方法の場合は領収書の発行は請求できません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      サポーターが、各種法令等により寄付金控除の対象となる団体に寄付をする場合で、
                      寄付時に、「領収書の発行を希望する」を選択したサポーターは、寄付をした団体よ
                      り、当該団体の費用と責任において、サポーターによる寄付金額が記載された領収書
                      を発行してもらうことができるものとします。かかる領収書は、当該団体よりサポータ
                      ーに直接郵送されるため、サポーターの住所、氏名、電話番号等の領収書発行、郵送
                      に必要となる個人情報（個人情報の保護に関する法律第2条第1項に定める「個人情報
                      」を意味し、以下同様とします。）が当社から当該団体に開示されることをサポーターはあらかじめ了承するものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      各種法令等により、寄付金控除の対象となる団体は、領収書の発行を希望するサポー
                      ターより寄付をされた場合、当該サポーターに対し、寄付金額が記載された領収書を
                      発行しなければならないものとします。かかる領収書は当該団体からサポーターに対
                      し直接郵送するものとし、当社はサポーターの住所、氏名、電話番号等の領収書発行
                      、郵送に必要となる個人情報を当該団体に開示し、当該団体は、個人情報保護法（個
                      人情報の保護に関する法律、関連法令、ガイドライン等を含みます。）の規定に従い適切に管理するものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【個人情報】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーは、本サービスに関連して当社が知り得たユーザーの個人情報を当社のプラ
                      イバシーポリシー（「個人情報保護方針」及び「個人情報の取扱い」を含みます。）
                      及び本規約に従って取り扱うことにつき同意するものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、本サービスを提供する上でユーザーにとって必要な情報を、ユーザーに対し
                      、電子メール、郵便、電話等によって連絡をすることができるものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーは、本サービスに関連して知り得た個人情報を個人情報保護法の規定に従い
                      適切に管理し、第三者による盗取・漏洩等の発生防止の措置を講じ、個人情報保護法その他の法令を遵守するものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【保証の否認及び免責】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>内容の妥当性についての非保証</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスは、アクション主催者とサポーターと団体の間の本サービスにおける取引
                      のためのプラットフォームであり、本サービスにてユーザーに提供される情報に関す
                      る正確性・信頼性・安全性・適法性・道徳性・最新性・有用性・適合性・完全性・妥
                      当性、本サービスによりユーザーの希望が満たされるかの担保を含め、当社はいかなる責任も負いません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービス内には本サービス以外の他のサイトへのリンクが含まれる場合があります
                      が、他のサイトで得られる情報について、当社はいかなる責任も負いません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスの利用によるユーザーの予約数・販売数・売上・寄付金の増加その他の結果について、当社は一切保証しないものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスの提供によりもたらされる成果発生の有無、データの的確性、正確性及び
                      信頼性又はその他本サービスの利用により期待される利益の一切に関し、当社は何ら責任を負わないものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      アクション主催者は、本サービスを通じて掲載されたコンテンツ・広告に関して一切
                      の責任を負うものとし、第三者との間で何らかの紛争が発生した場合には、自らの責
                      任と費用においてこれを解決するとともに、当社に何らの迷惑及び損害を与えないも
                      のとします。また、当社は本サービスを通じて掲載されたコンテンツ・広告の内容に
                      ついて、一切の責任を負わないものとし、コンテンツ・広告に関する紛争に起因して
                      ユーザーに生じた損害について一切の責任を負わないものとします。
                      ・ユーザーが本サービスを利用することが、ユーザーの所属する組織や業界団体の内部
                      規定に違反するか否かは、ユーザーが自らの責任と費用で調査、判断するものとし、
                      当社はユーザーの本サービスの利用においてかかる違反が存在しないことを保証しません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーが本サービスを利用することが、ユーザーの所属する組織や業界団体の内部
                      規定に違反するか否かは、ユーザーが自らの責任と費用で調査、判断するものとし、
                      当社はユーザーの本サービスの利用においてかかる違反が存在しないことを保証しません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      アクション主催者とサポーターがアクションのやり取りを行う場合、アクションが提
                      供される場所、時間、その他の状況を踏まえ、アクションの提供及びその利用に伴う
                      リスクを双方の当事者が十分に理解した上で自己の責任で対象サービスの提供及び利
                      用を行うものとします。アクション主催者とサポーターのアクションのやり取りにお
                      いて、物品・金銭の紛失や破損、傷害・暴行又はそれに準ずる行為、性的な嫌がらせ
                      をはじめとする双方又はいずれかが不快に感じる行為、その他公序良俗に違反する行
                      為や不法行為が発生した場合も、当社はその損害や不利益について、いかなる賠償責任も負いません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は本サービスを利用するユーザーに対して適宜情報提供（例えば、おすすめやア
                      ドバイス等）をすることがありますが、これらの内容の有用性・妥当性・正確性を保
                      証するものではなく、これらに従った結果について、当社はいかなる責任も負いません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスの提供の遅滞、変更、中止もしくは廃止、本サービスを通じて登録、提供
                      される情報等の流失もしくは消失等又は本サービスから遷移する他のウェブサイトの
                      停止、中断もしくは廃止その他本サービスの利用に関連してユーザーに損害が発生し
                      た場合は、いかなる場合においても当社は一切責任を負わないものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスは、外部SNSサービスと連携することがありますが、かかる連携を保証す
                      るものではなく、本サービスにおいて外部SNSサービスと連携できなかった場合でも
                      、当社は一切の責任を負わないものとします。本サービスが外部SNSサービスと連携
                      している場合において、ユーザーは外部SNS利用規約を自己の費用と責任で遵守する
                      ものとし、ユーザーと当該外部SNSサービスを運営する外部SNS事業者との間で紛争
                      等が生じた場合でも、当社は当該紛争等について一切の責任を負わないものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      火災、停電、天災地変、疫病などの不可抗力により本サービスの運営ができなくなっ
                      た場合、当社は、これによりユーザーに生じた損害について一切の責任を負いません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>システムについての非保証</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーが使用する機器、設備又はソフトウェアが本サービスの利用に適さない場合
                      であっても、本サービスにかかるシステムの改変、修正を行う義務を当社は負いません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、本サービス並びに本サービスに関連して当社から送られる電子メール及びコ
                      ンテンツに、コンピューターウィルス等の有害なものが含まれていないことを保証しません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>不正アクセスや脆弱性攻撃によりユーザーに生じた損害について、当社はいかなる責任も負いません。</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスのアクセス不能、障害又はバグの発生等の瑕疵及び仕様不備について当社はいかなる責任も負いません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      通信回線、ソフトウェア、ハードウェアなどのパフォーマンスの低下、障害、不正ア
                      クセスにより、本サービスのシステムの中断・遅延・中止等によって生じたいかなる損害についても、当社は一切の責任を負いません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスに関し、ユーザー間、又はユーザーと第三者との間で紛争が生じた場合、
                      ユーザーは、当社にこれを通知のうえ、自己の責任と費用において解決するものとし
                      ます。当社は、当該紛争に関与する義務を負わず、何ら責任を負わないものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      上記の各項目の他、本サービスの利用に関して又は本サービスを利用できなかったこ
                      とに関してユーザーに生じた損害について当社はいかなる責任も負いません。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【本サービスの中断・終了・変更等】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、本サービスの運用上・技術上の理由により、本サービスの提供を中断する必
                      要があると判断した場合は、事前の告知なく任意の期間、本サービスの全部又は一部の提供を中断することができます。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、何らの事前の予告なく、当社の裁量によって本サービスの全部又は一部の内容を変更又は終了することができるものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      前2項の中断、変更又は終了に関連して発生した損害について、当社はユーザー及び第三者に対していかなる責任も負いません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      4.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      消費者契約法その他の強行法規の適用その他何らかの理由により、当社がユーザーに
                      対して損害賠償責任を負う場合においても、当社の賠償責任は、損害の事由が生じた
                      時点から遡って過去1か月間の期間にユーザーから現実に受領した本サービスの利用
                      料金の総額、又は1万円のうち、いずれか高い方を上限とします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      5.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社及びユーザーは、それぞれ7日前までに当社所定の方法で相手方に通知することにより、ユーザーの登録を取り消すことができます。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      6.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、いかなる理由によっても、ユーザーに対して、当社が受領した金銭を返還する義務を負わないものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      7.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は本サービスにかかる事業を第三者に譲渡（事業譲渡、会社分割その他態様の如
                      何を問わないものとします。）した場合には、当該譲渡に伴い利用契約上の地位、本
                      規約に基づく権利及び義務並びにユーザーの登録情報その他の顧客情報を当該譲渡の
                      譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【権利帰属】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスに関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾し
                      ている者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、本
                      規約において明示されているものを除き、本サービスに関する当社又は当社にライセ
                      ンスを許諾している者の知的財産権の譲渡又は使用許諾を意味するものではありませ
                      ん。ユーザーは、いかなる理由によっても当社又は当社にライセンスを許諾している
                      者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバー
                      スエンジニアリングを含みますが、これに限定されません。）をしないものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスにおいて、ユーザーが投稿その他送信を行った文章、画像、動画その他の
                      データについては、当社において、本サービスの提供に必要な範囲内で、無償で自由
                      に利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することができるものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【秘密保持】
                  </Text>
                  <Text style={styles.textContent} type={'medium'}>
                    ユーザーは、当社が秘密に取り扱うことを求めて開示した本サービスに関する情報につい
                    て、当社の書面による承諾がある場合を除き、第三者への開示・漏洩をしてはならないものとします。
                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【支払遅延時の措置】
                  </Text>
                  <Text style={styles.textContent} type={'medium'}>
                    ユーザーが本規約に基づいて当社に負う金銭債務の支払を遅延した場合、当社はユーザー
                    に対して、未払金に対する年１４．６％の遅延損害金を請求できるものとします。遅延損
                    害金の計算方法は、支払期限の日の翌日から起算し、未払金の支払完了の日までの日数に
                    より日割り計算によるものとします。ユーザーが支払を怠った場合､当社はユーザーによ
                    る本サービスの利用を留保、停止又は拒絶できるものとします。
                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【秘密保持及び個人情報の保護】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本規約において「秘密情報」とは、本サービスに関連して、ユーザーが、当社よ
                      り書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、当社
                      の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。但し
                      、(1)当社から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知とな
                      っていた、又は既に知得していたもの、(2)当社から提供若しくは開示又は知得した後、自
                      己の責めに帰せざる事由により刊行物その他により公知となったもの、(3)提供又は開示の
                      権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情
                      報によることなく単独で開発したもの、(5)当社から秘密保持の必要なき旨書面で確認され
                      たものについては、秘密情報から除外するものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーは、秘密情報を本サービスの利用の目的のみに利用するとともに、当社
                      の書面による承諾なしに第三者に当社の秘密情報を提供、開示又は漏洩しないものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      第2項の定めに拘わらず、ユーザーは、法律、裁判所又は政府機関の命令、要求
                      又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請
                      があった場合、速やかにその旨を当社に通知しなければなりません。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      4.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーは、秘密情報を記載した文書又は磁気記録媒体等を複製する場合には、
                      事前に当社の書面による承諾を得ることとし、複製物の管理については第2項に準じて厳重に行うものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      5.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーは、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従
                      い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【禁止事項】
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      ユーザーは本サービスを利用するにあたり、当社、他のユーザー、又は第三者に対し
                      、下記の各号に該当する行為又はその恐れがある行為をしてはならないものとします
                      。これらの行為が発覚した場合、もしくは当社がこれらの行為に該当すると判断した
                      場合には、当社はユーザーに催告することなしに、ユーザーによる本サービスの利用
                      を停止することができるものとします。なお、それに関連してユーザーに損害が発生
                      した場合には当社は一切の責任を負わないものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      知的財産権（著作権、意匠権、特許権、実用新案権、商標権、工業所有権等）、肖像
                      権その他の権利又は名誉、プライバシー及びその他の権利を侵害する行為
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>財産、信用、プライバシーを侵害する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>不利益を与える行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>第三者の個人情報の売買又は譲受にあたる行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>公序良俗に反する行為、又はそれを助長する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>公序良俗に反する情報を提供する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>未成年者にとって有害と認められる情報の売買、譲受又は掲載する行為、それらを助長する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>法令に違反する行為や犯罪的行為又はそれを幇助する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービス及びその他当社が提供するサービスの運営を妨げる行為、又は当社の信用・名誉等を毀損する行為
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      コンピューターウィルス等有害なプログラムを、本サービスを通じて又は本サービスに関連して使用、又は提供する行為
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>他人になりすまして情報を送信、受信又は表示する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      サービス利用により知り得た個人情報を利用した次の行為（本サービスのE-メールサ
                      ービスを利用する場合も含みますがこれに限りません）
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>IPアドレス、アカウント、ID、パスワード、電子メールアドレス、及びドメイン名を不正に使用する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>第三者又は当社に不利益を与える又は迷惑をかける方法で本サービスを利用、宣伝する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本サービスを当社の許可なく第三者に利用させる行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>インターネット上で、第三者もしくは当社が入力した情報を不正に改ざんする行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>サーバその他当社のコンピュータに不正にアクセスする行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本サービスにおいて、事実に反する、又はその恐れのある情報を提供する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>当社と同種、又は類似の業務を行う行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      暴力団等の反社会的勢力等と関連すること、また名目を問わず資金提供その他当社が不適切と判断する行為
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本規約に違反する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>ねずみ講、マルチ商法、マルチレベルマーケティング、チェーンメール送信又はこれらに類する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスとは関連性がない又は関連性が著しく小さいと当社がその裁量により判断する団体やサービス、宗教、活動への勧誘
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      宣伝・告知・勧誘・アクセス数向上等を目的とする複数回の投稿又は発信行為（スパム、マルチポスト等）
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本サービスを利用して、営利目的でユーザーを他のウェブサイトに誘導する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>クレジットカード利用枠の現金化を目的とした行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本サービスの二次利用、複製行為及びこれらを目的とする行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>セクシャルハラスメント、ストーキング、その他第三者に対する嫌がらせ行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本サービスの趣旨に沿わない予約や必要以上繰り返される問合せ等の営業妨害行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>詐欺、規制薬物の濫用、マネーロンダリング等の犯罪に結びつく又は結びつくおそれのある行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>法律で義務付けられている免許、資格条件を満たしていない対象サービスの提供及び利用</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>架空取引（自分との取引又は取引があるかのように第三者と通謀する行為等）を行う行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>芸術性の有無に関わらず、露出度の高い画像の投稿行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>グロテスク・暴力的な表現の投稿行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>民族、人種、性別、年齢による差別につながる表現行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>他のユーザーが不快と感じる表現の投稿行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>当社の裁量により不適切と認める表現・投稿・書込行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>性的交渉の勧誘行為又は出会いを希望する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>個人を特定できる情報（メールアドレス、電話番号、住所等）の掲載行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスを通じて得られた他のユーザーの情報や機密情報を当該ユーザーの事前の
                      承諾なしに本サービス以外の目的で利用し又は第三者に提供する行為
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本サービスの改ざん・破壊行為（不正プログラム・ウイルスの送信を含みます。）</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本サービスの脆弱性やバグ・仕様不備の利用、人為的な高負荷の発生行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>自己又は他人の名称、住所、電話番号、メールアドレス等について、意図的に虚偽の情報を登録する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>悪意のあるレビュー等を掲載する行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本サービスに関する契約上の地位・権利・義務の第三者への譲渡、共有、交換行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスの利用停止、アカウント登録抹消された者が、再度、本サービスを利用、アカウント登録を試みる行為
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.bulletPoint}>
                      ・
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>上記のいずれかに該当すると当社が判断する行為及び当社が不適切と判断する全ての行為</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      前項の行為を行った場合以外にも、下記各号に該当する場合、当社はユーザーに催告
                      することなしに、ユーザーによる本サービスの利用を停止することができるものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      ①
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>過去に本サービスの利用を停止されている場合</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      ②
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>登録の際に当社に提供された情報に虚偽、誤記、記載漏れがあった場合</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      ③
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見
                      人､保佐人又は補助人の同意等を得ていなかった場合
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      ④
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      反社会的勢力等である、又は資金提供その他を通じて反社会的勢力等の維持、運営若し
                      くは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
                    </Text>
                  </View>
                  <Text type={'medium'}>
                    上記以外の行為でも当社が不適切と判断した場合には、本サービスの利用を停止することができるものとします。
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      第1項又は前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っ
                      ている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【準拠法】
                  </Text>
                  <Text type={'medium'}>本規約に関する準拠法は、全て日本国の法令が適用されるものとします。</Text>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    【協議及び管轄裁判所】
                  </Text>
                  <Text type={'medium'}>
                    本規約の解釈を巡って疑義が生じた場合、当社は合理的な範囲でその解釈を決定できるも
                    のとします。本規約及び本サービスに関する全ての紛争については、東京地方裁判所を第
                    1審の専属的合意管轄裁判所とすることを予め合意するものとします。
                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'medium'}>制定・施行：2020年9月1日</Text>
                </View>
              </View>


              <View style={styles.headerContent}>
                <Text type={'xxlarge'} align={'center'} style={styles.titleWrapper}>
                  団体についての特約
                </Text>
                <Text type={'medium'} style={styles.content}>
                  本特約は、特定非営利団体、一般社団法人、公益法人等の非営利活動団体等と当社との間
                  で別途合意した利用規約（以下「本利用規約」といいます。）の特約として、団体が本サービスを利用する際の条件について定めるものです。本特約における用語は、本特約にお
                  いて特に定めのない限り、本利用規約における用語の定義に従うものとします。また、本
                  特約に定めのない事項については、本利用規約が適用されるものとします。
                </Text>
              </View>


              <View style={styles.mainContent}>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第1条（定義）
                  </Text>
                  <Text type={'medium'}>本特約において、次に揚げる用語は、当該各号に定める意味を有します。</Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>「利用料」とは、第8条に定める料金をいいます。</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      「本サイト等」とは、専用団体紹介ページ及び本サービスに実装されている寄付誘導機能の総称をいいます。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      「専用団体紹介ページ」とは、本サービスにおいて、団体ごとに作成される、団体を紹介する専用のウェブページをいいます。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      4.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      「領収書被発行者情報」とは、支援会員による団体への寄付に関して団体当社から団体に通知する支援会員の情報をいいます。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第2条（申込方法）
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      団体としての本サービスの利用を希望する登録希望者は、当社所定の申込書及び当社が
                      別に定める必要書類を、別に定める当社連絡先へ提出するものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、前項に基づく申込み（以下「申込み」といいます。）の妥当性を判断するため、当社の裁量により審査を行うものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第3条(申込みの承諾)
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>当社が、前条に基づく申込みを承諾した場合は、当該団体に対しその旨の通知をいたします。</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、本利用規約【登録】第3項に定める事由のほか、次の各号のいずれかに該当する
                      と当社がその裁量により判断する場合は、申込みを承諾しないことがあります。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      団体の名称、所在地等の当該団体を特定する情報を、当社が別に定める方法により確認することができなかった場合
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本サービスにおける団体として既に登録されている場合</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>当社が、別に定める基準に照らし、次に掲げる要件を満たさないと判断した場合</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      {' '}
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      1特定非営利活動促進法に基づく特定非営利活動法人若しくは認定特定非営利活動
                      法人としての認定、又は公益社団法人及び公益財団法人の認定等に関する法律に基
                      づく公益社団法人若しくは公益財団法人としての認定、その他当社が必要と認める認定を受けている法人であること。{'\n'}2
                      営利を目的としていない団体であること。{'\n'}3当該団体の事業に社会性、健全性、公平性、公共性があること。{'\n'}4
                      活動実績があり、かつ活動の継続性が認められること。{'\n'}5支援金の使用用途が明確であること。{'\n'}6
                      活動実績、支援金の使用用途が一般公開されていること。{'\n'}7宗教活動及び政治活動を目的としていないこと。{'\n'}8
                      反社会的勢力等との関わりがないこと。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      4.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>利用料の徴収を拒否するおそれがある場合</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      5.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>本規約に現に違反している、又は違反するおそれがある場合</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      6.
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>その他当社の業務の遂行上支障がある場合</Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第4条（変更情報の届出）
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      団体は、第2条に基づき提出された申込書の記載情報に変更が生じた場合は、当該変更情
                      報を別に定める当社連絡先に、速やかに届け出るものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      前項の届出がなされた場合、当社は、当該団体に対し、当該変更情報の正確性を証する書類を提出していただくことがあります。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第5条（本サイト等の構築）
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>団体が本サービスにより支援金を受領するためには、本サイト等の構築が必要となります。</Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      （1）{' '}
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      専用団体紹介ページについて{'\n'}1当社は、第3条に基づく承諾後、別に定める期間内に専用団体紹介ページを作成します。{'\n'}2
                      当社が専用団体紹介ページを作成するにあたり、団体は、当社が別に定める素材及び情報等を提供するものとします。{'\n'}
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      （2）{' '}
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本サービスに実装されている寄付誘導機能について{'\n'}1
                      当社は、第3条に基づく承諾後、別に定める期間内及び方法で、画像から団体の専用
                      団体紹介ページへの誘導を可能とするために必要となる画像登録を行います。{'\n'}2
                      団体は、画像登録を行うにあたり、当社が別に定める素材及び情報等を提供するものとします。{'\n'}
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      （3）{' '}
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      領収書発行希望申込・解除機能について{'\n'}
                      当社は、第3条に基づく承諾後、別に定める期間内に、支援会員が団体に対して、領
                      収書の発行を希望する旨の申込み又は領収書の発行希望の解除を行うことのできる機能を提供します。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、本サイト等が利用可能となった後速やかに、団体に対し通知を行います。なお
                      、当該通知日に、団体は本サービスにおける団体として正式登録されるものとします（以下、この登録を「本件登録」といいます）。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第6条（支援金の支払い）
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、前月1日から前月末日の間に受領した、支援会員から団体への支援金を、当月
                      末までの当社が別に定める日にまとめて団体が申込み時に指定した金融機関口座に振り込みます。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      前項の支援金の支払いに関して、団体は、本利用規約に定めるところにより、当社に対
                      して、支援会員からの支援金を団体に代理して受領する権限を付与したものとみなされるものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、当社が支援会員から受領した支援金から、利用料及び団体に支払う際に発生
                      する金融機関の手数料等を差し引いた金額を団体に支払うものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第7条（領収書発行希望申込者情報の取り扱い）
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、支援会員から支援金を受領した日を含む月の翌月末までに、団体に対して、 領収書発行希望申込者情報を開示します。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社が通知する領収書発行希望申込者情報は以下のとおりです。{'\n'}
                      「寄付の申込日、寄付額、携帯電話番号、氏名、氏名（ふりがな）、郵便番号、住所、メ
                      ールアドレス、固定連絡先電話番号（当社に対して届出がなされている場合に限ります。）」
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      3
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      団体は、当社が通知した領収書発行希望申込者情報について、個人情報の保護に関する
                      法律及び団体のプライバシーポリシー等に則り、適切に管理・保有・利用しなければなり
                      ません。当社は、団体による領収書発行希望申込者情報の管理・保有・利用に関し、一切責任を負いません。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第8条（利用料）
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      団体は、本件登録がなされた日が属する月の翌月から、毎月、本サービスの利用料とし
                      て、当社が支援会員から受領した支援金に10%を乗じた金額（1円未満切捨て。以下「利
                      用料」といいます。）を、次項に基づく方法により当社へ支払うものとします。なお、当
                      該利用料には別途消費税相当額が加算されます。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      当社は、毎月、当社が支援会員から受領した支援金から利用料及び金融機関へ支払う
                      振込手数料を控除した残額を第６条所定の方法により団体へ支払うことにより、利用料を徴収いたします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第9条（団体の申出による本件登録の解除）
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本件登録の解除を希望する場合は、当社指定の連絡先に当社所定の解除申請書を提出す
                      るものとします。なお、本利用規約【本サービスの中断・終了・変更等】第5項の規定に
                      拘わらず、前月の9日から当月の8日までに提出された解除申請書に係る本件登録は、当月末日をもって解除されます。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      前項に基づく本件登録の解除後であっても、当社から団体への支援金の振り込みが発生
                      する場合には、団体は、利用料を本規約の定めに従い負担するものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第10条（当社が行う本件登録の解除）
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      本利用規約【禁止事項】第2項に定める事由のほか、団体が次の各号のいずれかに該当す
                      る場合、当社は団体に催告することなしに、当該団体に係る本件登録を解除することができるものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      {' '}
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      1利用料の徴収を拒否したとき、又はそのおそれがある場合{'\n'}
                      2合理的な理由により、団体が次のいずれかに該当すると当社が判断した場合{'\n'}
                      3営利を目的とした活動に支援金を利用している場合{'\n'}4 団体の事業に社会性、健全性、公平性、公共性が認められない場合{'\n'}
                      5活動実績及び支援金の使用用途が一般公開されていない場合{'\n'}
                      6宗教活動及び政治活動を目的とした活動を行っている場合{'\n'}
                      7反社会的勢力等との関わりが認められる場合{'\n'}
                      8本サービスにより支援金を受領する意思がないと認められる場合{'\n'}
                      9本規約に違反した場合{'\n'}
                      10本サービスにより支援金を受領することが不適切であると当社が判断した場合{'\n'}
                      11その他当社の業務遂行上支障がある場合
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      前項に基づく本件登録の解除がなされた場合でも、当社から団体への支援金の振り込み
                      が発生する場合には、団体は、利用料を本規約の定めに従い負担するものとします。当該
                      解除がなされた日が属する月の利用料は、日割り計算されることなく、全額発生いたしま
                      す。また、解除前に発生した利用料については、本規約の定めに従い支払わなければなりません。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第11条（名称の利用等）
                  </Text>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      1
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      団体は、当社が、本サービス及び支援金が使用された活動を周知する目的のために限り
                      、団体の名称、ロゴ等を各種媒体（ウェブサイト、電子メール、アプリケーション、パン
                      フレット、カタログ、ラべル、ステッカーを含みますが、これらに限られません。以下同
                      じとします。）に使用する権利を当社に許諾するものとします。
                    </Text>
                  </View>
                  <View style={styles.listItem}>
                    <Text type={'medium'} style={styles.numberBulletPoint}>
                      2
                    </Text>
                    <Text style={styles.textContent} type={'medium'}>
                      団体は、本件登録解除後であっても、当社が支援金を用いた団体の活動を周知する目的
                      に限り、団体の名称、ロゴ等を各種媒体に使用する権利を当社に許諾するものとします。
                    </Text>
                  </View>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第12条(秘密保持)
                  </Text>
                  <Text type={'medium'}>
                    団体及び当社は、本サービスに関して知り得た相手方の業務上の情報を、秘密として取り
                    扱うこととします。なお、本件登録の解除後も同様とします。
                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第13条（譲渡禁止）
                  </Text>
                  <Text type={'medium'}>
                    団体は、本規約に基づく権利及び義務の一切を、譲渡し、承継させ、又はその他の方法で処分してはならないものとします。
                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'xlarge'} style={styles.titleWrapper}>
                    第14条（免責）
                  </Text>
                  <Text type={'medium'}>
                    当社の責めに帰さないシステム障害や団体の状況により、団体が本サービスを利用できな
                    い場合であっても、当社はその責任を一切負わないものとします。
                  </Text>
                </View>
              </View>

              {/* <Text type={'quickRTitle'} style={styles.footerTitle}>
              Individual rules for {'\n'}participants <Text style={styles.sectionSubTitle}>参加者向け個別規定</Text>
            </Text> */}
            </ScrollView>


          </View>
        </View>

        <Footer />
      </View>

    </>

  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
    maxWidth: windowWidth - 180,
    zIndex: -100,
    marginBottom: 20

  },
  headerContent: {
    marginTop: 20,
    marginHorizontal: 28,
  },
  mainContent: {
    marginHorizontal: 28,
    fontSize: 14,
  },
  content: {
    paddingBottom: 40,


  },
  textContent: {

    maxWidth: windowWidth - 200,
    paddingRight: 50

  },
  titleWrapper: {
    marginBottom: 8,
  },
  listItem: {
    flexDirection: 'row',
  },
  bulletPoint: {
    fontSize: 30,
  },
  numberBulletPoint: {
    marginRight: 5,
  },
  // sectionTitle: {
  //   marginTop: 40,
  //   marginBottom: 32,
  // },
  // footerTitle: {
  //   marginTop: 10,
  // },
  // sectionSubTitle: {
  //   fontSize: 10,
  //   opacity: 0.4,
  // },
})
