// constants
import { isWeb } from 'app/src/constants/platform'
import { Linking } from 'react-native'

// ホーム
export const goToHome = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/`,
    })
  }

  if (!isWeb) {
    navigation.navigate('HomeScreen')
  }
}

// アクション一覧
export const goToActionList = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/action/list`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionListScreen')
  }
}

// アクション詳細
export const goToActionDetail = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/action/detail`,
      search: id ? `?id=${id}` : '',
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionDetailScreen', {
      id,
    })
  }
}

// アクション作成
export const goToActionCreate = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/action/create`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionCreateScreen')
  }
}

// アクション編集
export const goToActionEdit = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/action/edit`,
      search: `?id=${id}`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionEditScreen', {
      id,
    })
  }
}

// マイページ
export const goToMypage = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/mypage`,
    })
  }

  if (!isWeb) {
    navigation.navigate('MyPageScreen')
  }
}

// 設定リスト
export const goToSettingList = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/mypage/settings/list`,
    })
  }

  if (!isWeb) {
    navigation.navigate('SettingListScreen')
  }
}

// アカウント設定
export const goToAccountSetting = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/mypage/settings/account`,
    })
  }

  if (!isWeb) {
    navigation.navigate('AccountSettingScreen')
  }
}

// 決済設定
export const goToPaymentSettings = (navigation) => {
  if (isWeb) {
    navigation.push({
      pathname: `/mypage/payment`
    })
  }

  if (!isWeb) {
    navigation.navigate('PaymentSettingScreen')
  }
}

// 決済完了
export const goToPaymentFinish = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/payment/finish`,
      search: id ? `?actionId=${id}` : '',
    })
  }

  if (!isWeb) {
    navigation.navigate('PaymentFinishScreen')
  }
}

// 出金設定
export const goToBankAccount = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/mypage/settings/bank-account`,
    })
  }

  if (!isWeb) {
    navigation.navigate('BankAccountSettingScreen')
  }
}

// サインイン
export const goToSignin = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/signin`,
      search: id ? `?actionId=${id}` : '',
    })
  }

  if (!isWeb) {
    navigation.navigate('SigninScreen')
  }
}

// サインアップ
export const goToSignup = (navigation, id = '', fromPathname = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/signup`,
      state: { fromPathname: fromPathname, id: id },
    })
  }

  if (!isWeb) {
    navigation.navigate('SignupScreen')
  }
}

// サインアップ完了
export const goToSignupFinish = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/signup/finish`,
    })
  }

  if (!isWeb) {
    navigation.navigate('SignupFinishScreen')
  }
}

// ライブビューアー
export const goToLiveViewer = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/live/viewer`,
    })
  }

  if (!isWeb) {
    navigation.navigate('LiveViewerScreen')
  }
}

// ライブ配信側
export const goToLiveDelivery = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/live/delivery`,
    })
  }

  if (!isWeb) {
    navigation.navigate('LiveDeliveryScreen')
  }
}

// ポートフォリオ
export const goToPortfolio = (navigation, yearMonth = []) => {
  if (isWeb) {
    navigation.push({
      pathname: `/mypage/portfolio`,
      yearMonth: yearMonth
    })
  }

  if (!isWeb) {
    navigation.navigate('PortfolioScreen')
  }
}

export const goToPortfolioAccumulation = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/mypage/portfolio/accumulation`,
    })
  }

  if (!isWeb) {
    navigation.navigate('PortfolioAccumulationScreen')
  }
}

export const goToPortfolioHistory = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/mypage/portfolio/history`,
    })
  }

  if (!isWeb) {
    navigation.navigate('PortfolioHistoryScreen')
  }
}


// エリア詳細
export const goToAreaDetail = (navigation, area = 0, type = 0) => {
  if (isWeb) {
    navigation.push({
      pathname: `/area/detail/${area}`,
      state: { type: type },
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionDetailScreen')
  }
}

// 規約
export const goToTerms = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/terms`,
    })
  }

  if (!isWeb) {
    navigation.navigate('TermScreen')
  }
}

// HowTo
export const goToHowTo = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/how-to`,
    })
  }

  if (!isWeb) {
    navigation.navigate('HowToScreen')
  }
}

// プライバシーポリシー
export const goToPrivacy = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/privacy`,
    })
  }

  if (!isWeb) {
    navigation.navigate('PrivacyScreen')
  }
}

// QA
export const goToQa = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/qa`,
    })
  }

  if (!isWeb) {
    navigation.navigate('QaScreen')
  }
}

// 会社概要
export const goToCompany = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/company`,
    })
  }

  if (!isWeb) {
    navigation.navigate('CompanyScreen')
  }
}

// お問い合わせ
export const goToContact = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/contact`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ContactScreen')
  }
}

export const goToGuideline = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/guideline`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ContactScreen')
  }
}

// 特定商取引法に基づく表記
export const goToTransactions = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/transactions`,
    })
  }

  if (!isWeb) {
    navigation.navigate('TransactionsScreen')
  }
}

// 領収書


export const goToInvoices = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/mypage/invoices`,
    })
  }

  if (!isWeb) {
    navigation.navigate('InvoiceScreen')
  }
}


export const goToItem = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/items/detail`,
      search: `?id=${id}`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionEditScreen', {
      id,
    })
  }
}


export const goToStore = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/items/store`,
      search: `?id=${id}`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionEditScreen', {
      id,
    })
  }
}







export const goToDirectDonation = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/donation/direct`,
      search: `?id=${id}`,
    })
  }

  if (!isWeb) {
    navigation.navigate('DrinkDetailScreen', {
      id,
    })
  }
}


export const goToAvtivistReport = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/reports`,
      search: id ? `?id=${id}` : '',
    })
  }

  if (!isWeb) {
    navigation.navigate('ActivistDetailScreen', {
      id,
    })
  }
}


export const goToActivistDeposit = (navigation) => {

  if (isWeb) {
    navigation.push({
      pathname: '/deposit'

    })
  }
}


export const goToActivistSignup = (navigation) => {

  if (isWeb) {
    navigation.push({
      pathname: '/signup'
    })
  }
}



export const goToForgetPassword = (navigation) => {

  if (isWeb) {
    navigation.push({
      pathname: '/forget-password'
    })
  }
}

export const goToSentForgetPassword = (navigation) => {

  if (isWeb) {
    navigation.push({
      pathname: '/forget-password/sent'
    })
  }
}


export const goToActivistSignin = (navigation) => {

  if (isWeb) {
    navigation.push({
      pathname: '/'
    })
  }
}

export const goToActivistDetail = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/detail`,
      search: id ? `?id=${id}` : '',
    })
  }

  if (!isWeb) {
    navigation.navigate('ActivistDetailScreen', {
      id,
    })
  }
}

export const goToActivistMypage = (navigation) => {

  if (isWeb) {
    navigation.push({
      pathname: `/mypage`
    })
  }

  if (!isWeb) {
    navigation.navigate('ActivistMyapageScreen')
  }

}



export const goToActivistInvoice = (navigation) => {

  if (isWeb) {
    navigation.push({
      pathname: `/invoice`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ActivistInvoiceScreen')
  }

}


export const goToActivistIntroduction = (navigation) => {

  if (isWeb) {
    navigation.push({
      pathname: `/introduction`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ActivistIntroductionScreen')
  }

}



export const goToMonthlyDonation = (navigation) => {

  if (isWeb) {
    navigation.push({
      pathname: `/donation/monthly`,
    })
  }

  if (!isWeb) {
    navigation.navigate('MonthlyDonationScreen')
  }

}

export const goToBadges = (navigation) => {


  if (isWeb) {
    navigation.push({
      pathname: `/badges`,
    })
  }

  if (!isWeb) {
    navigation.navigate('BadgesScreen')
  }


}


export const goToOrders = (navigation) => {


  if (isWeb) {
    navigation.push({
      pathname: `/mypage/orders`,
    })
  }

  if (!isWeb) {
    navigation.navigate('PurchasedScreen')
  }


}



export const goToFavorites = (navigation) => {


  if (isWeb) {
    navigation.push({
      pathname: `/mypage/favorites`,
    })
  }

  if (!isWeb) {
    navigation.navigate('FavoritesdScreen')
  }


}

export const goToDrink = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/drinks/detail`,
      search: `?id=${id}`,
    })
  }

  if (!isWeb) {
    navigation.navigate('DrinkDetailScreen', {
      id,
    })
  }
}

export const goToApproval = (navigation) => {


  if (isWeb) {
    navigation.push({
      pathname: `/drinks/approval`,
    })
  }

  if (!isWeb) {
    navigation.navigate('DrinkApprovalScreen')
  }


}


export const goToShopDetailScreen = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/shop/detail`,
      search: id ? `?id=${id}` : '',
    })
  }

  if (!isWeb) {
    navigation.navigate('ShopDetailScreen', {
      id,
    })
  }
}


export const goToReportConfirmScreen = (navigation) => {


  if (isWeb) {
    navigation.push({
      pathname: `/report/confirm`,
      // search:id ? `?id=${id}` :""
    })
  }
}

export const goToReportCreateScreen = (navigation) => {


  if (isWeb) {
    navigation.push({
      pathname: `/report/create`
    })
  }
}
export const goToReportScreen = (navigation) => {


  if (isWeb) {
    navigation.push({
      pathname: `/report`
    })
  }
}

export const goToReportEditScreen = (navigation) => {


  if (isWeb) {
    navigation.push({
      pathname: `/report/edit`,

    })
  }
}

export const goToDonations = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/donations`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionListScreen')
  }
}


export const goToBasicInfo = (navigation) => {

  if (isWeb) {
    navigation.push({
      pathname: `/basicinfo`,
    })
  }

  if (!isWeb) {
    navigation.navigate('basicInfoScreen')
  }


}



export const goToLine = () => {

  const url = "https://line.me/R/ti/p/@672smznq"

  if (isWeb) {
    Linking.openURL(url)
  }

  if (!isWeb) {
    Linking.openURL(url)
  }


}


