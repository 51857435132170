import React from 'react'
import { View, StyleSheet, TextStyle, Text as RNText, TextProps } from 'react-native'
import styled from 'styled-components'

// constants
import { lightGray, black, darkGray, headlineTextColor } from 'app/src/constants/colors'

type Props = {
  style?: TextStyle
  type?: string
  font?: string
  align?: TextStyle['textAlign']
  text?: string
}
export const Text: React.FC<Props & TextProps> = (props: Props) => {
  const { style, type = 'medium', font, align = 'left', text } = props

  const Title = styled.div`
    rgba(176, 0, 32, 1)background: -webkit-linear-gradient(180deg, rgba(204, 0, 0, 1), rgba(176, 0, 32, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Noto Sans CJK JP;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height, or 117% */

    text-transform: lowercase;
  `

  const textStyle = () => {
    if (type === 'small') return styles.small
    if (type === 'medium') return styles.medium
    if (type === 'large') return styles.large
    if (type === 'xlarge') return styles.xlarge
    if (type === 'xxlarge') return styles.xxlarge
    if (type === 'quickRTitle') return styles.quickRTitle
    if (type === 'quickTitle') return styles.quickTitle
    if (type === 'alert') return styles.alert
    if (type === 'empty') return styles.empty
    if (type === 'annotation') return styles.underAnnounceLogo
    if (type == "active") return styles.active
  }

  const fontStyle = () => {
    let font = 'noto-sans-regular'
    if (font === 'quick-r') font = 'quicksand-regular'
    if (font === 'quick-m') font = 'quicksand-medium'

    return {
      fontFamily: font,
    }
  }

  return (
    <View>
      {(() => {
        if (type === 'gradient') {
          return <Title>{text}</Title>
        } else if (type === 'annotation') {
          return <RNText allowFontScaling={false} {...props} style={[textStyle(), font ? fontStyle() : {}, style]} />
        } else {
          return <RNText allowFontScaling={false} {...props} style={[textStyle(), { textAlign: align }, font ? fontStyle() : {}, style]} />
        }
      })()}
    </View>
  )
}

const styles = StyleSheet.create({
  empty: {
    color: '#999999',
    fontSize: 14,
  },
  small: {
    fontSize: 11,
    lineHeight: 20,
    color: lightGray,
    fontFamily: 'noto-sans-regular',
  },
  medium: {
    fontSize: 12,
    lineHeight: 22,
    color: black,
    fontFamily: 'noto-sans-regular',
  },
  large: {
    fontSize: 14,
    lineHeight: 24,
    color: darkGray,
    fontFamily: 'noto-sans-regular',
  },
  xlarge: {
    fontSize: 14,
    lineHeight: 24,
    color: darkGray,
    fontWeight: 'bold',
    fontFamily: 'noto-sans-bold',
  },
  xxlarge: {
    fontSize: 16,
    lineHeight: 26,
    color: darkGray,
    fontFamily: 'noto-sans-regular',
  },
  quickRTitle: {
    fontSize: 30,
    lineHeight: 30,
    color: headlineTextColor,
    fontFamily: 'quicksand-regular',
  },
  quickTitle: {
    fontSize: 24,
    lineHeight: 28,
    color: '#000000',
    fontFamily: 'quicksand-regular',
  },
  alert: {
    color: '#CC0000',
    fontSize: 14,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 11,
  },
  underAnnounceLogo: {
    color: '#999999',
    fonFamily: 'Quicksand',
    fonStyle: 'normal',
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'lowercase',
  },
  active: {
    color: '#999999',
    fontSize: 14,
    fontFamily: 'Roboto',
    lineHeight: 24,



  },

  /* or 117% */
})
