import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { images } from 'app/src/libs/images'
import { Text } from 'app/src/components/basics/text'



type Props = {
    status: number
}

const Progress = (props: Props) => {


    return (
        <View style={styles.container}>

            <View style={styles.progressContainer}>
                <Text style={styles.text}>パスワード変更</Text>
                {props.status >= 1 ? (
                    <LinearGradient
                        colors={['rgba(44, 164, 222, 1)', 'rgba(150, 200, 67, 1)']}
                        start={{ x: 0.0, y: 1 }}
                        end={{ x: 1, y: 1 }}
                        style={styles.progress}
                    ></LinearGradient>) : <View style={styles.normal} />
                }

            </View>
            <View>



                <Image resizeMode="contain" style={styles.arrow} source={images['progressArrow']} />

            </View>
            <View style={styles.progressContainer}>
                <Text style={styles.text}>stripeのアカウント作成</Text>

                {props.status >= 2 ? (
                    <LinearGradient
                        colors={['rgba(44, 164, 222, 1)', 'rgba(150, 200, 67, 1)']}
                        start={{ x: 0.0, y: 1 }}
                        end={{ x: 1, y: 1 }}
                        style={styles.progress}
                    ></LinearGradient>) : <View style={styles.normal} />
                }
            </View>

            <View>
                <Image resizeMode="contain" style={styles.arrow} source={images['progressArrow']} />

            </View>
            <View style={styles.progressContainer}>
                <Text style={styles.text}>完了</Text>

                {props.status >= 3 ? (
                    <LinearGradient
                        colors={['rgba(44, 164, 222, 1)', 'rgba(150, 200, 67, 1)']}
                        start={{ x: 0.0, y: 1 }}
                        end={{ x: 1, y: 1 }}
                        style={styles.progress}
                    ></LinearGradient>) : <View style={styles.normal} />
                }
            </View>

        </View>

    )
}

export default Progress


const styles = StyleSheet.create({
    container: {

        marginHorizontal: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
        display: "flex",
        flexDirection: "row"
    },

    normal: {
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: "#BDBDBD",
        margin: "auto"

    },
    progress: {
        width: 10,
        height: 10,
        borderRadius: 5,
        margin: "auto"

    },
    arrow: {
        width: 140,
        height: 20,
        marginLeft: 18,
        marginRight: 18
    },
    progressContainer: {
        width: 120,
        height: 40


    },
    text: {
        fontSize: 10, lineHeight: 14, textAlign: "center",
    }

})