import React from 'react'
import { StyleSheet, View, Image, TouchableOpacity, Clipboard } from 'react-native'

// libs
import { images } from 'app/src/libs/images'
import { LinkingOpenURL } from 'app/src/libs/linking'
import { HeadTag } from 'app/src/components/advanced/head-tag'

export const ShareList: React.FC = () => {
  // const actionDetailUrl = `https://megu.green/action/detail?id=${actionId}`

  // const facebookSharingUrl = `https://www.facebook.com/sharer/sharer.php?u=${actionDetailUrl}`
  // const twitterSharingUrl = `https://twitter.com/intent/tweet?text=${title}%0a&url=${actionDetailUrl}`
  // const lineSharingUrl = `http://line.me/R/msg/text/?${title}%0D%0A${actionDetailUrl}`
  // const copyShareUrl = () => {
  //   Clipboard.setString(actionDetailUrl)
  //   alert('URLをコピーしました')
  // }

  return (
    <View style={styles.actionShareList}>
      {/* <HeadTag title={`megu | ${title}`} description={description.replace(/\\n/g, '').slice(0, 120)} imageUrl={imageFileUrl} /> */}
      {/* <HeadTag
        title={`megu | ${title}`}
        description={description && description.replace(/\\n/g, '').slice(0, 120)}
        imageUrl={imageFileUrl}
      /> */}
      <View style={styles.item}>
        <Image style={styles.icon} source={images['iconLink']} />
      </View>
      <View style={styles.item}>
        <Image style={styles.icon} source={images['iconFb']} />
      </View>
      <View style={styles.item}>
        <Image style={styles.icon} source={images['iconTw']} />
      </View>
      <View style={styles.item}>
        <Image style={styles.icon} source={images['iconLine']} />
      </View>
      {/* {isShownDownload && (
        <TouchableOpacity style={styles.item}>
          <Image style={styles.icon} source={images['iconDownload']} />
        </TouchableOpacity>
      )} */}
    </View>
  )
}

const styles = StyleSheet.create({
  actionShareList: {
    alignItems: 'center',
    width: 300,
    // width: 175,
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  item: {},
  icon: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
  },
})
