import React from 'react'
import { StyleSheet, View, Image } from 'react-native'
import { images } from 'app/src/libs/images'
import { Text } from 'app/src/components/basics'

export const ForgetPasswordSentScreen = () => {
    return (
        <View style={styles.entireContainer}>
            <View style={styles.container}>
                <Image style={styles.logoBig} source={images['logoBig']} />
                <View style={styles.titleContainer}>
                    <Text style={styles.title}>パスワード再設定メールを送付しました</Text>
                </View>
                <View style={styles.subtitleContainer}>
                    <Text style={styles.subtitle}>ご入力いただいたメールアドレスに届いているメールからパスワードの再設定を完了してください。</Text>
                </View>
            </View>
        </View>
    )
}

export default ForgetPasswordSentScreen

const styles = StyleSheet.create({
    entireContainer: {
        backgroundColor: 'white',
        width: '80%',
        height: 600,
        margin: 'auto',
        marginTop: 144,
    },
    container: {
        margin: 'auto',
        marginTop: 105,
    },
    logoBig: {
        width: 120,
        height: 160,
        margin: 'auto',
        fontFamily: 'noto-sans-regular',
    },
    titleContainer: {
        marginTop: 83,
        textAlign: 'center',
        margin: 'auto',
    },
    title: {
        fontSize: 24,
    },
    subtitleContainer: {
        marginTop: 64,
        width: 480,
    },

    subtitle: {
        color: '#999999',
        textAlign: 'center',
        margin: 'auto',
        fontSize: 18,
    },
})