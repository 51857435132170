import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ScrollView, Alert, Image } from 'react-native'

// libs
import { sendMailForContact } from 'app/src/libs/firebase'

// constants
import { isWeb } from 'app/src/constants/platform'

// components
import { Text } from 'app/src/components/basics'
import { TextInput } from 'app/src/components/basics/text-input'
import { Button } from 'app/src/components/basics/button'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { SelectBox } from 'app/src/components/basics/selectbox'
import { images } from 'app/src/libs/images'
import { windowWidth } from 'app/src/constants/layout'
import { goToLine } from 'app/src/libs/screen-transition'
import { ReportCard, styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { ActivistMenu } from '../../advanced/activist/menu'




type ContainerProps = {}

type Props = {
  subject: string
  name: string
  email: string
  message: string
  setSubject: (subject: string) => void
  setName: (name: string) => void
  setEmail: (email: string) => void
  setMessage: (message: string) => void
  submitContact: () => void
}

export const ContactScreen: React.FC<ContainerProps> = () => {
  const [subject, setSubject] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState<string>('')



  const _submitContact = () => {
    if (!subject || !name || !email || !message) {
      alert('未入力の項目があります。')
      return
    }

    const data = {
      subject,
      name,
      email,
      message,
    }
    sendMailForContact(data)
    setSubject('')
    setName('')
    setEmail('')
    setMessage('')
    alert('送信完了しました。')
  }

  return (
    <UI
      subject={subject}
      setSubject={setSubject}
      name={name}
      setName={setName}
      email={email}
      setEmail={setEmail}
      message={message}
      setMessage={setMessage}
      submitContact={_submitContact}
    />
  )
}

const UI: React.FC<Props & ContainerProps> = ({
  subject,
  setSubject,
  name,
  setName,
  email,
  setEmail,
  message,
  setMessage,
  submitContact,
}) => {

  console.log("width", windowWidth)
  let width = ""
  let lineIconWidth: number | string = ""

  if (windowWidth <= 700) {

    width = "90%"

    lineIconWidth = "90%"






  } else {

    width = "50%"
    lineIconWidth = 355
  }





  return (





    <View >
      <View style={basicStyles.container}>
        <View style={basicStyles.bodyContainer}>
          <View>
            <ActivistMenu />
          </View>

          <ScrollView style={styles.scrollContainer}>
            <View style={styles.headerContent}>
              <Text type={'xxlarge'} align={'center'}>
                お問合せ
              </Text>
            </View>

            <View style={styles.headerImage}>
              <Image source={images['iconLineBackground']} style={{ height: 190, width: lineIconWidth, margin: "auto" }} resizeMode="cover" />

            </View>

            <View style={styles.mainContent}>


              <View style={styles.textContainer}>
                <Text style={{ textAlign: "center" }} type={'normal'} >
                  お客様からのお問い合わせはLINEにて承っております。下のボタンからmeguのLINEを追加いただき、ご連絡ください。
                </Text>

              </View>


              <Button buttonType={1} onPress={() => goToLine()}>
                Lineに追加する
              </Button>



            </View>
          </ScrollView>




        </View>
      </View>

      <Footer />
    </View>
  )
}




const styles = StyleSheet.create({

  textContainer: {


    paddingLeft: 38,
    paddingRight: 37,
    paddingTop: 35,
    paddingBottom: 35,
    width: windowWidth - 180,
    textAlign: "center",
    margin: "auto",


  },


  container: {
    flex: 1,
    paddingTop: 48,

  },
  scrollContainer: {
    flex: 1,
    width: windowWidth - 180,
    zIndex: -100


  },
  headerContent: {
    marginTop: 20,
    marginBottom: 34,
    margin: 40

  },
  mainContent: {
    marginHorizontal: 28,
    fontSize: 14,
  },
  content: {
    flexDirection: 'row',
    paddingBottom: 25,
    display: "flex",

    justifyContent: "space-between"
  },
  title: {
    fontSize: 11,
    width: windowWidth / 2 - 135,
    textAlign: 'right',
    marginRight: 20,
  },
  text: {
    fontSize: 11,
    flex: 1,

    width: windowWidth / 2 - 90,

    textAlign: "left",
    // backgroundColor: "red"
    paddingRight: 30



  },

  textContent: {

    maxWidth: windowWidth - 200,
    paddingRight: 30

  },
  beeroLogo: {
    marginBottom: 24,
    width: 189,
    height: 65,
    margin: "auto"

  },
  headerImage: {
    width: windowWidth - 180,
    textAlign: "center",


  },





})
