import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, View, Dimensions, TouchableOpacity } from 'react-native'
import { ActivistMenu } from 'app/src/components/advanced/activist/menu'
import { Text } from 'app/src/components/basics'
import { LinearGradient } from 'expo-linear-gradient'
import { useNavigation } from 'app/src/hooks/use-navigation'
import { fetchActivistInfo } from 'app/src/libs/firebase'
import { goToActivistSignin,goToBasicInfo, goToHome } from 'app/src/libs/screen-transition'
import { BasicInfoCard } from '../../../advanced/activist/basicInfo/InfoCard'
import { ResetPasswordCard } from '../../../advanced/activist/basicInfo/ResetPassword'
import { BankAccountCard } from '../../../advanced/activist/basicInfo/BankAccountCard'
import { Hoverable } from 'react-native-web-hover'
import { ActivistIntroduction } from 'app/src/components/advanced/activist/basicInfo/ActivistIntroduction'
import { ActivistHeaderMenu } from 'app/src/components/advanced/activist/headerMenu'
import { styles as basicStyles } from 'app/src/components/advanced/activist/basic'

import {  ref, listAll, getDownloadURL } from 'firebase/storage'
import {  signOut, onAuthStateChanged } from 'firebase/auth'
import {   auth, storage } from 'app/src/libs/firebase'
import { SelectedMenu } from 'app/src/components/advanced/activist/mypage/selectedMenu'
import { NotSelectedMenu } from 'app/src/components/advanced/activist/mypage/notSelectedMenu'
import { Loader } from 'app/src/components/basics/loader'
import { getDocs,collection } from 'firebase/firestore'
import { db } from 'app/src/libs/firebase'


type ContainerProps = {}

const { width, height } = Dimensions.get('window')

export const ActivistMypageScreen: React.FC<ContainerProps> = () => {






  const date = new Date()
  const yys = new Array(5).fill(null).map((_, i) => String(date.getFullYear() - i))
  const [selectedMenu, setSelectedMenu] = useState<number>(0)
  const navigation = useNavigation()
  const [imageUrl, setImageUrl] = useState<string>('')
  const [kakuin, setKakuin] = useState<string>('')
  const [activistId, setActivistId] = useState<string>('')
  const [activistName, setActivistName] = useState<string>('')
  const [activistNameFurigana, setActivistNameFurigana] = useState<string>('')
  const [postalCode, setPostalCode] = useState<string>('')
  const [town, setTown] = useState<string>('')
  const [building, setBuilding] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [bankCode, setBankCode] = useState<string>('')
  const [bankBranchCode, setBankBranchCode] = useState<string>("")
  const [bankAccountNumber, setBankAccountNumber] = useState<number>(0)
  const [bankAccountType, setBankAccountType] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [whatWeDo, setWhatWeDo] = useState<string>('')
  const [whatWeDoImages, setWhatWeDoImages] = useState<string[]>(['', ''])
  const [whatWeDoImage1, setWhatWeDoImage1] = useState<string>('')
  const [whatWeDoImage2, setWhatWeDoImage2] = useState<string>('')
  const [howWeDoImage1, setHowWeDoImage1] = useState<string>('')
  const [howWeDoImage2, setHowWeDoImage2] = useState<string>('')
  const year = new Date().getFullYear()
  const [selectedYear, setSelectedYear] = useState<string>(String(year))
  const [howWeDo, setHowWeDo] = useState<string>('')
  const [howWeDoImages, setHowWeDoImages] = useState<string[]>(['', ''])
  const [pdfs, setPdfs] = useState<any[]>([])
  const [pdfImage, setPdfImage] = useState('')
  const [years, setYears] = useState<string[]>(yys)
  const [thumbnail, setThumbnail] = useState<string>('')
  const [establishmentDate, setEstablishmentDate] = useState<string>('')
  const [representative, setRepresentative] = useState<string>('')
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [isSetUp, setIsSetUp] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPdf, setIsPdf] = useState<boolean>(false)
  const [stripeAccountId, setStripeAccountId] = useState<string>('')
  const [doImages, setDoImages] = useState<any>({})
  const [bankName, setBankName] = useState<string>("");
  const [branchName, setBranchName] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [proofImage,setProofImage] = useState<string>("");
  const [relatedActivists,setRelatedActivists] = useState<{ label:string;value:string}[]>([])
  const [originalUid,setOriginalUid] = useState<string>("");
  const [activistNumber,setActivistNumber] = useState<string>('')
  const [activistAdmittedDate, setActivistAdmittedDate] = useState<string>('')
  const [proviso,setProviso] = useState<string>('')
  const [number,setNumber] = useState<number>(0)







  const _fetchUserInfo = async (uid: string) => {
    const activistInfo = await fetchActivistInfo(uid)


    const id = localStorage.getItem("mypage")


    setSelectedMenu(Number(id))


    localStorage.removeItem("mypage")



    if(!activistInfo) return
    if (activistInfo.isSetUp) {
      setOriginalUid(uid)





      setIsApproved(activistInfo.isApproved)
      setActivistName(activistInfo.activistName)
      setActivistNameFurigana(activistInfo.activistNameFurigana)
      setPostalCode(activistInfo.postalCode)
      setTown(activistInfo.town)
      setBuilding(activistInfo.building)
      setPhoneNumber(activistInfo.phoneNumber)
      setImageUrl(activistInfo.imageUrl)

      setKakuin(activistInfo.kakuin)
      setActivistId(uid)
      setBankCode(activistInfo.bankCode)
      setBankBranchCode(activistInfo.bankBranchCode)
      setBankAccountType(activistInfo.bankAccountType)
      setBankAccountNumber(activistInfo.bankAccountNumber)
      setThumbnail(activistInfo.thumbnail)
      setEstablishmentDate(activistInfo.establishmentDate)
      setRepresentative(activistInfo.representative)
      setIsSetUp(true)
      setStripeAccountId(activistInfo.stripeAccountId)
      setDoImages(activistInfo.doImages)
      setBankName(activistInfo.bankName)
      setBranchName(activistInfo.bankBranchName)
      setEmail(activistInfo.email)
      setActivistAdmittedDate(activistInfo.activistAdmittedDate)
      setActivistNumber(activistInfo.activistNumber)
      setProviso(activistInfo.proviso)

      if(activistInfo?.doImages){
        if(activistInfo?.doImages["whatWeDoImages"]){
          setWhatWeDoImage1(activistInfo?.doImages["whatWeDoImages"][0])
          setWhatWeDoImage2(activistInfo?.doImages["whatWeDoImages"][1])

        }


        if(activistInfo.doImages["howWeDoImages"]){

          setHowWeDoImage1(activistInfo?.doImages["howWeDoImages"][0])
          setHowWeDoImage2(activistInfo?.doImages["howWeDoImages"][1])


        }



      }






      let array: string[] = []
      let yearArray: string[] = []

      const listRef = ref(storage, `images/activists/${uid}/annual`)

      try {

        const url = await getDownloadURL(ref(storage, `images/activists/${uid}/proof.jpeg`))


          setProofImage(url)




      } catch (error) {

        console.log("err",error)

      }



      await listAll(listRef).then(async result => {
        const items = result.items

        for (let i = 0; i < items.length; i++) {
          const ref = items[i]

          const value = await getDownloadURL(ref).then(url => {
            return url
          })

          if (value.indexOf('.jpeg') != -1) {
            var t = value.substr(value.indexOf('.jpeg') - 4, 4)
            yearArray.push(t)

            array.push(value)
          }
        }
      })

      array.sort(function (a, b) {
        if (a < b) {
          return 1
        } else if (a > b) {
          return -1
        } else {
          return 0
        }
      })
      yearArray.sort(function (a, b) {
        if (a < b) {
          return 1
        } else if (a > b) {
          return -1
        } else {
          return 0
        }
      })

      let newYears: any[] = []

      for (let i = 0; i < array.length; i++) {
        newYears.push({ year: yearArray[i], value: array[i] })
      }

      setPdfs(newYears)
      setWhatWeDo(activistInfo?.whatWeDo)
      setHowWeDo(activistInfo?.howWeDo)

    } else {
      goToBasicInfo(navigation)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    const fetchMyPage = async () => {
      await onAuthStateChanged(auth, user => {
        if (user) {


        //会員登録後、メール認証したか
          const selectedActivistId = localStorage.getItem("selectedActivistId")
          const id = selectedActivistId?selectedActivistId:user.uid
          setActivistId(id)
          const setUp = async()=>{

            const result = await getDocs(collection(db, 'related-activists', user.uid,"activists"))
            const docs = result.docs


            if(id != user.uid){
              const activistInfo = await fetchActivistInfo(user.uid)

              const activistName = activistInfo.activistName
              relatedActivists.push({
                label:activistName,
                value:user.uid,

              })


            }



            docs.forEach((docSnap)=>{

              const activistName = docSnap.data().activistName
              relatedActivists.push({
                label:activistName,
                value:docSnap.id,

              })

              setRelatedActivists(relatedActivists)
            })






          }
          setUp()


            _fetchUserInfo(id)

        } else {
          goToHome(navigation)
        }
      })
    }
    fetchMyPage()
  }, [])

  const selected = (v: number) => {

    setSelectedMenu(v)

  }

  useEffect(()=>{

    const setUp = ()=>{

      if(!activistId) return

      const selectedActivistId = localStorage.getItem("selectedActivistId")
      const id = selectedActivistId?selectedActivistId:activistId


      setActivistId(id)
      _fetchUserInfo(id)

    }
    setUp()

  },[activistId])

  const childRef = useRef<any>()




  return (
    <View>
      <View>
        <View style={basicStyles.container}>
          <View style={basicStyles.bodyContainer}>
            <ActivistMenu pageTitle={'mypage'} />
            <View style={basicStyles.scrollContainer}>
              <ActivistHeaderMenu number={number} activistId={activistId} imageUrl={imageUrl} title={'マイページ'} />



              <View style={basicStyles.cardContainer}>
                <View style={styles.headerItems}>
                  <View style={styles.headerFourItems}>
                    {selectedMenu === 0 ? (
                      <SelectedMenu selectedMenu={0} text={'基本情報設定'} selected={selected} />
                    ) : (
                      <NotSelectedMenu selectedMenu={0} text={'基本情報設定'} selected={selected} />
                    )}

                    {selectedMenu === 1 ? (
                      <SelectedMenu selectedMenu={1} text={'団体紹介'} selected={selected} />
                    ) : (
                      <NotSelectedMenu selectedMenu={1} text={'団体紹介'} selected={selected} />
                    )}

                    {selectedMenu === 2 ? (
                      <SelectedMenu selectedMenu={2} text={'パスワード変更'} selected={selected} />
                    ) : (
                      <NotSelectedMenu selectedMenu={2} text={'パスワード変更'} selected={selected} />
                    )}
                    {selectedMenu === 3 ? (
                      <SelectedMenu selectedMenu={3} text={'支援金送金先'} selected={selected} />
                    ) : (
                      <NotSelectedMenu selectedMenu={3} text={'支援金送金先'} selected={selected} />
                    )}
                  </View>

                  <Hoverable>
                    {({ hovered }) => (
                      <TouchableOpacity
                        onPress={() =>
                          signOut(auth).then(() => {
                            goToActivistSignin(navigation)
                          })
                        }
                        style={styles.logout}
                      >
                        <Text type={'large'} style={hovered ? basicStyles.textUnderLine : basicStyles.normalText}>
                          ログアウト
                        </Text>
                      </TouchableOpacity>
                    )}
                  </Hoverable>
                </View>
                <LinearGradient
                  // Background Linear Gradient
                  colors={['#00a4de', '#006fb9', '#00c020', '#009239']}
                  style={{
                    height: 2,
                  }}
                  start={[0.0, 0.0]}
                  end={[1.0, 0]}
                />
                {isLoading ?
                  <View style={basicStyles.loadCard}>

                    <Loader />

                  </View>
                  : <View>

                    {(() => {
                      if (selectedMenu === 0) {
                        return (
                          <BasicInfoCard
                          setNumber={setNumber}
                          number={number}

                            originalUid={originalUid}
                            activistId={activistId}
                            imageUrl={imageUrl}
                            activistName={activistName}
                            postalCode={postalCode}
                            town={town}
                            building={building}
                            phoneNumber={phoneNumber}
                            kakuin={kakuin}
                            setKakuin={setKakuin}
                            setActivistId={setActivistId}
                            setImageUrl={setImageUrl}
                            setActivistName={setActivistName}
                            setPostalCode={setPostalCode}
                            setTown={setTown}
                            setBuilding={setBuilding}
                            setPhoneNumber={setPhoneNumber}
                            stripeAccountId={''}
                            title={''}
                            establishmentDate={establishmentDate}
                            representative={representative}
                            setEstablishmentDate={setEstablishmentDate}
                            setRepresentative={setRepresentative}
                            isApproved={isApproved}
                            isSetUp={isSetUp}
                            ref={childRef}
                            setActivistNameFurigana={setActivistNameFurigana}
                            activistNameFurigana={activistNameFurigana}
                            relatedActivists={relatedActivists}
                            setRelatedActivists={setRelatedActivists}
                            email={email}
                            setEmail={setEmail}
                            proofImage={proofImage}
                            setProofImage={setProofImage}
                            activistAdmittedDate={activistAdmittedDate}
                            setActivistAdmittedDate={setActivistAdmittedDate}
                            activistNumber={activistNumber}
                            setActivistNumber={setActivistNumber}
                            proviso={proviso}
                            setProviso={setProviso}




                          />
                        )
                      } else if (selectedMenu === 1) {
                        return (
                          <ActivistIntroduction
                            whatWeDo={whatWeDo}
                            setWhatWeDo={setWhatWeDo}
                            howWeDo={howWeDo}
                            setHowWeDo={setHowWeDo}
                            activistId={activistId}
                            years={years}
                            setYears={setYears}
                            whatWeDoImage1={whatWeDoImage1}
                            setWhatWeDoImage1={setWhatWeDoImage1}
                            whatWeDoImage2={whatWeDoImage2}
                            setWhatWeDoImage2={setWhatWeDoImage2}
                            howWeDoImage1={howWeDoImage1}
                            setHowWeDoImage1={setHowWeDoImage1}
                            howWeDoImage2={howWeDoImage2}
                            setHowWeDoImage2={setHowWeDoImage2}
                            selectedYear={selectedYear}
                            setSelectedYear={setSelectedYear}
                            pdfs={pdfs}
                            setPdfs={setPdfs}
                            pdfImage={pdfImage}
                            setPdfImage={setPdfImage}
                            howWeDoImages={howWeDoImages}
                            whatWeDoImages={whatWeDoImages}
                            thumbnail={thumbnail}
                            setThumbnail={setThumbnail}
                            isPdf={isPdf}
                            setIsPdf={setIsPdf}
                            doImages={doImages}
                            setDoImages={setDoImages}
                            ref={childRef}

                          />
                        )
                      } else if (selectedMenu === 2) {
                        return (
                          <ResetPasswordCard
                            activistId={''}
                            newPassword={newPassword}
                            setNewPassword={setNewPassword}
                            confirmNewPassword={confirmNewPassword}
                            setConfirmNewPassword={setConfirmNewPassword}
                            currentPassword={currentPassword}
                            setCurrentPassword={setCurrentPassword}
                            ref={childRef}
                            oldPassword={oldPassword}
                            setOldPassword={setOldPassword}
                            email={email}


                          />
                        )
                      } else {
                        return (
                          <BankAccountCard
                            activistId={activistId}
                            bankCode={bankCode}
                            bankBranchCode={bankBranchCode}
                            bankAccountNumber={bankAccountNumber}
                            bankAccountType={bankAccountType}
                            setBankCode={setBankCode}
                            setBankBranchCode={setBankBranchCode}
                            setBankAccountNumber={setBankAccountNumber}
                            setBankAccountType={setBankAccountType}
                            stripeAccountId={stripeAccountId}
                            ref={childRef}
                            setBankName={setBankName}
                            setBranchName={setBranchName} bankName={bankName} branchName={branchName} />
                        )
                      }
                    })()}
                  </View>}


              </View>
            </View>
          </View>
        </View>
      </View>

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: height,
  },
  relatedContainer:{
    position:"absolute",
    width:300,
    right:80,
    top:30,
    zIndex:100


  },
  saveBtn: {
    right: 0,
    position: 'absolute',
    bottom: 0,
  },
  bell: {
    height: width * 0.02,
    width: height * 0.03,
  },
  rightIcon: {
    flexDirection: 'row',
    marginEnd: width * 0.1,
  },
  mypageContent: {
    width: width * 0.75,
  },
  mypageItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  announceLogo: {
    width: width * 0.2,
  },
  headerItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 10,
  },
  headerFourItems: {
    flexDirection: 'row',
  },
  selectedMenu: {
    width: 136,
    height: 52,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  notSelectedMenu: {
    padding: 50,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    position: 'absolute',
    left: -(width * 0.147),
    right: 0,
    top: 0,
    bottom: 0,
    height: 3,
    marginTop: 31,
    width: width / 10,
  },

  logout: {
    marginTop: 15,
    paddingRight: 40,
    borderBottomColor: 'red',
  },
  backgroundImageContent: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  backgroundImage: {
    width: '110%',
    height: '100%',
  },

  title: {
    marginTop: 40,
    marginBottom: 35,
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
