import React, { useEffect, useState, useCallback } from 'react'
import {
    StyleSheet,
    View,
    Dimensions,
    ImageBackground,
    Image,
    TextInput as RNTextInput,
    TouchableOpacityBase,
    TouchableOpacity,
    SafeAreaView,
} from 'react-native'
import { styles as basicStyles, ThankYouImageFunc } from 'app/src/components/advanced/activist/basic'
import { Text, Button } from 'app/src/components/basics'
import { Pressable } from 'react-native-web-hover'
import { goToReportConfirmScreen, goToReportScreen, goToReportEditScreen } from 'app/src/libs/screen-transition'
import { useNavigation } from 'app/src/hooks/use-navigation'
import { TextInput } from 'app/src/components/basics/text-input'
import { CheckBox } from 'app/src/components/basics/check-box'
import { back, db, fetchActivistInfo, fetchReport } from 'app/src/libs/firebase'
import {
    deleteDoc,
    doc,
    updateDoc,
    addDoc,
    collection,
    getDocs,
    where,
    query,
    arrayRemove,
} from 'firebase/firestore'


import { Formik } from 'formik';
import * as Yup from "yup";

type Props = {

    thankYouMessage: string
    setThankYouMessage: (v: string) => void
    isThankYouMessage: boolean
    setIsThankYouMessage: (v: boolean) => void
    author: string
    setAuthor: (v: string) => void
    isAuthor: boolean
    setIsAuthor: (v: boolean) => void
    title: string
    body: string

    activistId: string

    reportId: string
    label: string
    isPreSending: boolean
    isDraft: boolean
    setLabel: (v: string) => void
    isPostThankYouMessage: boolean
    setIsPostThankYouMessage: (v: boolean) => void
}

const { width, height } = Dimensions.get('window')

export const ThankYouContainer = (props: Props) => {


    const validation = () =>
        Yup.object().shape({
            author: Yup.string()
                .required("必須項目です"),
            thankYouMessage: Yup.string()
                .required("必須項目です"),
        });
    const navigation = useNavigation()
    const date = new Date()

    async function deleteReport() {
        const result = window.confirm("本当に削除してもいいですか？")
        if (result) {
            const ref = doc(db, 'activists', props.activistId, 'reports', String(props.reportId))

            const activistInfo = await fetchActivistInfo(props.activistId)
            const preSendingReportIdsList: string[] = activistInfo.preSendingReportIdsList

            const reportInfo = fetchReport(props.activistId, props.reportId)
            if (preSendingReportIdsList.includes(props.reportId)) {
                const activistRef = doc(db, 'activists', props.activistId)

                await updateDoc(activistRef, {
                    preSendingReportIdsList: arrayRemove(props.reportId),
                })
            }

            await deleteDoc(ref)

            const q = query(collection(db, 'messages'), where('reportId', '==', String(props.reportId)))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach(async docSnap => {
                const data = docSnap.data()
                const delRef = doc(db, 'reports', data.uid, 'messages', String(props.reportId))
                await deleteDoc(delRef)
            })

            goToReportScreen(navigation)

        }

    }

    async function confirm() {


        const ref = doc(db, 'activists', props.activistId)



        if (props.isThankYouMessage) {
            await updateDoc(ref, {
                isThankYouMessage: true,
                thankYouMessage: props.thankYouMessage,
            })
        } else {

            await updateDoc(ref, {
                isThankYouMessage: false,
                thankYouMessage: "",
            })
        }
        if (props.isAuthor) {
            await updateDoc(ref, {
                isAuthor: true,
                author: props.author,
            })
        } else {



            await updateDoc(ref, {
                isAuthor: false,
                author: "",
            })


        }


        const reportDocRef = doc(db, 'activists', props.activistId, 'reports', props.reportId)


        await updateDoc(reportDocRef, {
            isRead: false,
            isSent: false,
            isDraft: false,
            thankYouMessage: props.thankYouMessage,
            author: props.author,
        })

        props.setIsPostThankYouMessage(true)

        localStorage.setItem('reportId', props.reportId)

        goToReportConfirmScreen(navigation)


    }

    async function add() {

        const ref = doc(db, 'activists', props.activistId)

        if (props.isThankYouMessage) {
            await updateDoc(ref, {
                isThankYouMessage: true,
                thankYouMessage: props.thankYouMessage,
            })
        } else {

            await updateDoc(ref, {
                isThankYouMessage: false,
                thankYouMessage: "",
            })

        }
        if (props.isAuthor) {
            await updateDoc(ref, {
                isAuthor: true,
                author: props.author,
            })
        } else {

            await updateDoc(ref, {
                isAuthor: false,
                author: "",
            })


        }


        const reportDocRef = doc(db, 'activists', props.activistId, 'reports', props.reportId)


        await updateDoc(reportDocRef, {
            isRead: false,
            isSent: false,
            isDraft: false,
            thankYouMessage: props.thankYouMessage,
            author: props.author,
            title: props.title,
            body: props.body
        })







        goToReportScreen(navigation)

    }


    const returnHome = async () => {

        // props.setLabel()
        localStorage.setItem("reportId", props.reportId)
        // goToReportEditScreen(navigation)
        props.setLabel("edit")


    }

    return (


        <Formik
            initialValues={{
                thankYouMessage: props.thankYouMessage,
                author: props.author,
            }}

            validationSchema={validation()}
            enableReinitialize={true}
            onSubmit={values => confirm()}
        >
            {({ setFieldValue, handleBlur, handleSubmit, values, errors }) => (
                <View style={basicStyles.cardContainer}>
                    <View style={basicStyles.card}>
                        <View style={basicStyles.thankYouMessageTextContainer}>
                            <View>
                                <Text style={basicStyles.cardTitle}>
                                    Thank you message
                                </Text>
                            </View>
                            <TouchableOpacity onPress={() => add()}>
                                <Text style={basicStyles.skipConfirm}>活動報告をThank you messageとして送付しない場合はスキップ　＞</Text>

                            </TouchableOpacity>


                        </View>

                        <View style={basicStyles.cardBody}>
                            <View>

                                <View>
                                    <View style={basicStyles.sectionContainer}>
                                        <TextInput
                                            label={'送信者名(執筆者のお名前をお書きください)'}
                                            multiple={2}
                                            value={props.author}




                                            onChangeText={async (e: any) => {

                                                props.setAuthor(e)
                                                setFieldValue("author", e)


                                            }}
                                            onBlur={handleBlur('author')}
                                            type={"author"}
                                            error={errors.author ? errors : ""}

                                        />

                                    </View>

                                    <View style={basicStyles.saveAuthorContainer}>
                                        <View style={basicStyles.saveAuthorBtnContainer}>
                                            <CheckBox isValue={props.isAuthor} isSetValue={() => props.setIsAuthor(!props.isAuthor)} />

                                            <Text>今後もこの送付者を利用するために保存する</Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={basicStyles.sectionContainer}>

                                    <TextInput placeholder=''
                                        label={'Thank you message'}
                                        multiple={1}
                                        value={props.thankYouMessage}
                                        type={"thankYouMessage"}
                                        error={errors.thankYouMessage ? errors : ""}

                                        // onChangeText={props.setThankYouMessage} 


                                        onChangeText={async (e: any) => {

                                            props.setThankYouMessage(e)
                                            setFieldValue("thankYouMessage", e)


                                        }}
                                        onBlur={handleBlur('thankYouMessage')}

                                    />
                                </View>

                                <View style={basicStyles.buttonContainer}>
                                    <View >
                                        <Pressable>
                                            {({ hovered }) => (
                                                <TouchableOpacity
                                                    style={hovered ? styles.hovered : styles.notHovered}
                                                    onPress={() => props.setThankYouMessage(props.thankYouMessage + '{username}')}
                                                >
                                                    <Text style={styles.forSupporter}>宛先の支援者の名前を入れる</Text>
                                                </TouchableOpacity>
                                            )}
                                        </Pressable>
                                    </View>
                                    <View style={basicStyles.saveThankYouMessageButtonContainer}>
                                        <CheckBox isValue={props.isThankYouMessage} isSetValue={() => props.setIsThankYouMessage(!props.isThankYouMessage)} />
                                        <Text>記載したものをテンプレートとして保存する</Text>
                                    </View>
                                </View>


                            </View>

                        </View>
                        <View style={basicStyles.btnContainer}>
                            <View style={basicStyles.deleteBtnContainer}>
                                {props.label === 'create' ? (
                                    <View />
                                ) : (
                                    <Pressable>
                                        {({ hovered }) => {
                                            if (hovered) {
                                                return (
                                                    <Button onPress={() => deleteReport()} buttonType={17}>
                                                        削除する
                                                    </Button>
                                                )
                                            } else {
                                                return (
                                                    <Button onPress={() => deleteReport()} buttonType={10}>
                                                        削除する
                                                    </Button>
                                                )
                                            }
                                        }}
                                    </Pressable>
                                )}
                            </View>
                            <View>

                                <View style={basicStyles.rightBottomButtonContainer}>
                                    <Pressable>
                                        {data => {
                                            if (data.hovered) {
                                                return (
                                                    <Button buttonType={19} onPress={() => returnHome()}>
                                                        戻る
                                                    </Button>
                                                )
                                            } else {
                                                return (
                                                    <Button buttonType={11} onPress={() => returnHome()}>
                                                        戻る
                                                    </Button>
                                                )
                                            }
                                        }}
                                    </Pressable>

                                    <View>
                                        <Pressable style={basicStyles.submitButton}>
                                            {data => {
                                                if (data.hovered) {

                                                    return (
                                                        <View>
                                                            <Button buttonType={3} onPress={handleSubmit}>
                                                                確認する
                                                            </Button>

                                                        </View>
                                                    )

                                                } else {

                                                    return (
                                                        <View>

                                                            <Button buttonType={1} onPress={handleSubmit}>
                                                                確認する

                                                            </Button>

                                                        </View>
                                                    )

                                                }
                                            }}
                                        </Pressable>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

            )}
        </Formik>
    )
}

const styles = StyleSheet.create({
    checkbox: {
        alignSelf: 'center',
        marginRight: 11,
    },
    hovered: {
        borderColor: '#999999',
        width: 180,
        height: 30,
        borderWidth: 2,
        borderRadius: 50,
        justifyContent: 'center',
    },
    notHovered: {
        borderColor: '#DDDDDD',
        width: 180,
        height: 30,
        borderWidth: 2,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerContainer: {
        width: width * 0.77,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        marginTop: 40,
        marginBottom: 35,
    },
    card: {
        backgroundColor: 'white',
        width: width * 0.85,

        height: 1000,
    },
    cardBody: {
        flexDirection: 'row',
    },
    titleTextField: {
        borderWidth: 1,
        height: 40,
        marginLeft: 34,
        borderColor: '#DDDDDD',
    },

    forSupporter: {
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'center',
    },

    image: {
        width: 50,
        height: 50,
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    imageText: {
        color: '#999',
        width: 250,
        fontSize: 12,
    },
    buttonContainer: {
        width: 200,
        alignItems: 'center',
    },
    buttonText: {
        textAlign: 'center',
        color: '#4C64FF',
        padding: 15,
        marginLeft: 1,
        marginRight: 1,
        width: 198,
    },
})
