import React, { useState, useEffect, useCallback } from 'react'
import { StyleSheet, View, Image, ActivityIndicator } from 'react-native'
import * as SplashScreen from 'expo-splash-screen'
import * as Font from 'expo-font'
import { Asset } from 'expo-asset'
import { activateKeepAwake } from 'expo-keep-awake'
import { primaryColor, white } from 'app/src/constants/colors'
import { Text } from 'app/src/components/basics/text'

import { images } from 'app/src/libs/images'
import { fonts } from 'app/src/libs/fonts'

import { isWeb } from 'app/src/constants/platform'
import { windowHeight, windowWidth } from 'app/src/constants/layout'

type Props = {}
const width = windowWidth > 950 ? 950 : '100%'

export const Loader: React.FC<Props> = () => {



    return (
        <View>
            <ActivityIndicator style={styles.loading} size="large" color={primaryColor} />
        </View>
    )
}

const styles = StyleSheet.create({
    loading: {
        margin: 'auto',
        top: 170,
        width,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10000,
    },
    loaderContainer: {
        flex: 1,
        backgroundColor: white,
        alignItems: 'center',
        justifyContent: 'center',
        height: windowHeight,
        width: windowWidth,
    },
})
