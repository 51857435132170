export const images = {
  logo: require('app/src/assets/images/logo.png'),
  logoBig: require('app/src/assets/images/logo_big.png'),
  logoPc: require('app/src/assets/images/logo_pc.png'),
  pcIconHome: require('app/src/assets/images/pc_icon_home.png'),
  pcIconMypage: require('app/src/assets/images/pc_icon_mypage.png'),
  pcIconPortfolio: require('app/src/assets/images/pc_icon_portfolio.png'),
  pcIconWarning: require('app/src/assets/images/pc_icon_warning.png'),
  beta: require('app/src/assets/images/beta.png'),
  logoSmall: require('app/src/assets/images/logo_small.png'),
  actionIcon1Active: require('app/src/assets/images/action_icon_1_active.png'),
  actionIcon2Active: require('app/src/assets/images/action_icon_2_active.png'),
  actionIcon3Active: require('app/src/assets/images/action_icon_3_active.png'),
  actionIcon4Active: require('app/src/assets/images/action_icon_4_active.png'),
  actionIcon5Active: require('app/src/assets/images/action_icon_5_active.png'),
  actionIcon2: require('app/src/assets/images/action_icon_2.png'),
  actionIcon3: require('app/src/assets/images/action_icon_3.png'),
  actionIcon4: require('app/src/assets/images/action_icon_4.png'),
  actionIcon5: require('app/src/assets/images/action_icon_5.png'),
  actionSample1: require('app/src/assets/images/actionSample1.png'),
  actionTopSample1: require('app/src/assets/images/actionTopSample1.png'),
  actionTopSample2: require('app/src/assets/images/actionTopSample2.png'),
  actionTopSample3: require('app/src/assets/images/actionTopSample3.png'),
  actionTopSample4: require('app/src/assets/images/actionTopSample4.png'),
  btnCamera: require('app/src/assets/images/btn_camera.png'),
  btnClose: require('app/src/assets/images/btn_close.png'),
  btnType1: require('app/src/assets/images/btn_type1.png'),
  btnType2: require('app/src/assets/images/btn_type2.png'),
  btnType3: require('app/src/assets/images/btn_type3.png'),
  btnType4: require('app/src/assets/images/btn_type4.png'),
  btnType4Wide: require('app/src/assets/images/btn_type4_wide.png'),
  btnType5: require('app/src/assets/images/btn_type5.png'),
  btnType6: require('app/src/assets/images/btn_type6.png'),
  btnType7: require('app/src/assets/images/btn_type6.png'),
  btnType8: require('app/src/assets/images/btn_type8.png'),
  btnType9: require('app/src/assets/images/btn_type9.png'),
  btnType10: require('app/src/assets/images/btn_type10.png'),
  btnType11: require('app/src/assets/images/btn_type11.png'),
  btnType12: require('app/src/assets/images/btn_type12.png'),
  btnType13: require('app/src/assets/images/btn_type13.png'),
  btnType14: require('app/src/assets/images/btn_type14.png'),
  btnType15: require('app/src/assets/images/btn_type15.png'),
  btnType16: require('app/src/assets/images/btn_type16.png'),
  btnType17: require('app/src/assets/images/btn_type17.png'),
  btnType18: require('app/src/assets/images/btn_type18.png'),
  btnType19: require('app/src/assets/images/btn_type19.png'),
  btnType20: require('app/src/assets/images/btn_type20.png'),
  downArrowSvg: require("app/src/assets/images/down_arrow.svg"),
  upperArrowSvg: require("app/src/assets/images/upper_arrow.svg"),




  btnSmalWithdrawal: require('app/src/assets/images/btn_smal_withdrawal.png'),
  btnSmallDetail: require('app/src/assets/images/btn_small_detail.png'),
  btnUrlCopy: require('app/src/assets/images/btn_url_copy.png'),
  btnLogo: require('app/src/assets/images/btn_logo.png'),
  deleteText: require('app/src/assets/images/delete_text.png'),
  defaultUserIcon: require('app/src/assets/images/default_user_icon.png'),
  footerHead: require('app/src/assets/images/footer_head.png'),
  iconArrowDown: require('app/src/assets/images/icon_arrow_down.png'),
  iconArrowRight: require('app/src/assets/images/icon_arrow_right.png'),
  iconClose: require('app/src/assets/images/icon_close.png'),
  iconText: require('app/src/assets/images/icon_text.png'),
  iconCamera: require('app/src/assets/images/icon_camera.png'),
  iconDownload: require('app/src/assets/images/icon_download.png'),
  iconEdit: require('app/src/assets/images/icon_edit.png'),
  iconFb: require('app/src/assets/images/icon_fb.png'),
  iconInsL: require('app/src/assets/images/icon_ins_l.png'),
  iconLine: require('app/src/assets/images/icon_line.png'),
  iconLink: require('app/src/assets/images/icon_link.png'),
  iconLiveviewCount: require('app/src/assets/images/icon_liveview_count.png'),
  iconMail: require('app/src/assets/images/icon_mail.png'),
  iconShare: require('app/src/assets/images/icon_share.png'),
  iconTwL: require('app/src/assets/images/icon_tw_l.png'),
  iconTw: require('app/src/assets/images/icon_tw.png'),
  iconYouL: require('app/src/assets/images/icon_you_l.png'),
  icon: require('app/src/assets/images/icon.png'),
  iconMeguBig: require('app/src/assets/images/icon_megu_big.png'),
  iconThankYou: require('app/src/assets/images/icon_thank_you.png'),
  iconLineBackground: require("app/src/assets/images/icon_line_background.png"),
  input: require('app/src/assets/images/input.png'),
  inputBackground: require('app/src/assets/images/input_background.png'),
  likeCountMask: require('app/src/assets/images/likeCountMask.png'),
  liveEllipse: require('app/src/assets/images/live_ellipse.png'),
  liveUserSample: require('app/src/assets/images/live_user_sample.png'),
  liveWait: require('app/src/assets/images/live_wait.png'),
  liveShareSample: require('app/src/assets/images/live_share_sample.png'),
  liveBackground: require('app/src/assets/images/live_bg.png'),
  logoF: require('app/src/assets/images/logo_f.png'),
  footerLogo: require('app/src/assets/images/footer_logo.png'),
  mypageIcon: require('app/src/assets/images/mypage_icon.png'),
  mypageNoAction: require('app/src/assets/images/mypage_no_action.png'),
  mypageNoJoin: require('app/src/assets/images/mypage_no_join.png'),
  section1Catchphrase: require('app/src/assets/images/section1_catchphrase.png'),
  section2Bg: require('app/src/assets/images/section2_bg.png'),
  section2Catchphrase: require('app/src/assets/images/section2_catchphrase.png'),
  section2Image1: require('app/src/assets/images/section2_image1.png'),
  section2Image2: require('app/src/assets/images/section2_image2.png'),
  section2Image3: require('app/src/assets/images/section2_image3.png'),
  section2Image4: require('app/src/assets/images/section2_image4.png'),
  section2Image5: require('app/src/assets/images/section2_image5.png'),
  earthTop: require('app/src/assets/images/earth_top.png'),
  earthSignin: require('app/src/assets/images/earth_signin.png'),
  earthPortfolio: require('app/src/assets/images/earth_portfolio.png'),
  ttlActions: require('app/src/assets/images/ttl_actions.png'),
  ttlDetail: require('app/src/assets/images/ttl_detail.png'),
  ttlFollowus: require('app/src/assets/images/ttl_followus.png'),
  ttlPopular: require('app/src/assets/images/ttl_popular.png'),
  ttlShare: require('app/src/assets/images/ttl_share.png'),
  user: require('app/src/assets/images/user.png'),
  userIcon1: require('app/src/assets/images/userIcon1.png'),
  userIcon2: require('app/src/assets/images/userIcon2.png'),
  userIcon3: require('app/src/assets/images/userIcon3.png'),
  userIcon4: require('app/src/assets/images/userIcon4.png'),
  userIconSeina: require('app/src/assets/images/userIconSeina.png'),
  userIconSaho: require('app/src/assets/images/userIconSaho.png'),
  userIconDefault: require('app/src/assets/images/user_icon_default.png'),
  headerBg: require('app/src/assets/images/header_bg.png'),
  beeroLogo: require('app/src/assets/images/beero_logo.png'),
  detailImageMask: require('app/src/assets/images/detail_mage_mask.png'),
  btnViewAccumulation: require('app/src/assets/images/btn_view_accumulation.png'),
  btnViewList: require('app/src/assets/images/btn_view_list.png'),
  comingSoon: require('app/src/assets/images/coming_soon.png'),
  btnLeft: require('app/src/assets/images/btn_left.png'),
  btnRight: require('app/src/assets/images/btn_right.png'),
  areaIcon1: require('app/src/assets/images/area_icon1.png'),
  areaIcon2: require('app/src/assets/images/area_icon2.png'),
  areaIcon3: require('app/src/assets/images/area_icon3.png'),
  areaIcon4: require('app/src/assets/images/area_icon4.png'),
  areaIcon5: require('app/src/assets/images/area_icon5.png'),
  areaIcon6: require('app/src/assets/images/area_icon6.png'),
  areaIcon7: require('app/src/assets/images/area_icon7.png'),
  areaIcon8: require('app/src/assets/images/area_icon8.png'),
  areaIcon9: require('app/src/assets/images/area_icon9.png'),
  areaIcon10: require('app/src/assets/images/area_icon10.png'),
  iconSigninLine: require('app/src/assets/images/icon_signin_line.png'),
  iconSigninYahoo: require('app/src/assets/images/icon_signin_yahoo.png'),
  bgSignin: require('app/src/assets/images/bg_signin.png'),
  bgSignup: require('app/src/assets/images/bg_signup.png'),
  graphType1Bg: require('app/src/assets/images/graph_type1_bg.png'),
  areaHeaderImage1: require('app/src/assets/images/area_header_image1.png'),
  areaHeaderImage2: require('app/src/assets/images/area_header_image2.png'),
  areaHeaderImage3: require('app/src/assets/images/area_header_image3.png'),
  areaHeaderImage4: require('app/src/assets/images/area_header_image4.png'),
  areaHeaderImage5: require('app/src/assets/images/area_header_image5.png'),
  areaHeaderImage6: require('app/src/assets/images/area_header_image6.png'),
  areaHeaderImage7: require('app/src/assets/images/area_header_image7.png'),
  areaHeaderImage8: require('app/src/assets/images/area_header_image8.png'),
  liveViewerSample: require('app/src/assets/images/viewer.png'),
  soundMix: require('app/src/assets/images/sound_mix.png'),
  mail: require('app/src/assets/images/mail.png'),
  creditCard: require('app/src/assets/images/credit_card.png'),
  question: require('app/src/assets/images/question.png'),
  questionActive: require('app/src/assets/images/question_active.png'),
  iconNpo1: require('app/src/assets/images/icon_npo1.png'),
  iconNpo2: require('app/src/assets/images/icon_npo2.png'),
  iconNpo3: require('app/src/assets/images/icon_npo3.png'),
  iconNpo4: require('app/src/assets/images/icon_npo4.png'),
  aboutMegu: require('app/src/assets/images/about_megu.png'),
  aboutMeguFigure: require('app/src/assets/images/about_megu_figure.png'),
  npoHeaderImage1: require('app/src/assets/images/npo_header_image1.png'),
  npoHeaderImage2: require('app/src/assets/images/npo_header_image2.png'),
  npoHeaderImage3: require('app/src/assets/images/npo_header_image3.png'),
  npoHeaderImage4: require('app/src/assets/images/npo_header_image4.png'),
  npoHeaderImage5: require('app/src/assets/images/npo_header_image5.png'),
  npoHeaderImage6: require('app/src/assets/images/npo_header_image6.png'),
  npoHeaderImage7: require('app/src/assets/images/npo_header_image7.png'),
  npoHeaderImage8: require('app/src/assets/images/npo_header_image8.png'),
  npoHeaderImage9: require('app/src/assets/images/npo_header_image9.png'),
  npoHeaderImage10: require('app/src/assets/images/npo_header_image10.png'),
  npoHeaderImage11: require('app/src/assets/images/npo_header_image11.png'),
  npoHeaderImage12: require('app/src/assets/images/npo_header_image12.png'),
  npoHeaderImage13: require('app/src/assets/images/npo_header_image13.png'),
  npoHeaderImage14: require('app/src/assets/images/npo_header_image14.png'),
  npoHeaderImage15: require('app/src/assets/images/npo_header_image15.png'),
  npoHeaderImage16: require('app/src/assets/images/npo_header_image16.png'),
  npoHeaderImage17: require('app/src/assets/images/npo_header_image17.png'),
  npoHeaderImage18: require('app/src/assets/images/npo_header_image18.png'),
  npoHeaderImage19: require('app/src/assets/images/npo_header_image19.png'),
  npoHeaderImage20: require('app/src/assets/images/npo_header_image20.png'),
  area1Image1: require('app/src/assets/images/area1_image1.png'),
  area1Image2: require('app/src/assets/images/area1_image2.png'),
  area1Image3: require('app/src/assets/images/area1_image3.png'),
  area1Image4: require('app/src/assets/images/area1_image4.png'),
  area1Image5: require('app/src/assets/images/area1_image5.png'),
  area1Image6: require('app/src/assets/images/area1_image6.png'),
  area1Image7: require('app/src/assets/images/area1_image7.png'),
  area1Image8: require('app/src/assets/images/area1_image8.png'),
  area2Image1: require('app/src/assets/images/area2_image1.png'),
  area2Image2: require('app/src/assets/images/area2_image2.png'),
  area2Image3: require('app/src/assets/images/area2_image3.png'),
  area2Image4: require('app/src/assets/images/area2_image4.png'),
  area2Image5: require('app/src/assets/images/area2_image5.png'),
  area2Image6: require('app/src/assets/images/area2_image6.png'),
  area2Image7: require('app/src/assets/images/area2_image7.png'),
  area2Image8: require('app/src/assets/images/area2_image8.png'),
  area2Image9: require('app/src/assets/images/area2_image9.png'),
  area2Image10: require('app/src/assets/images/area2_image10.png'),
  area3Image1: require('app/src/assets/images/area3_image1.png'),
  area3Image2: require('app/src/assets/images/area3_image2.png'),
  area3Image3: require('app/src/assets/images/area3_image3.png'),
  area3Image4: require('app/src/assets/images/area3_image4.png'),
  area3Image5: require('app/src/assets/images/area3_image5.png'),
  area3Image6: require('app/src/assets/images/area3_image6.png'),
  area4Image1: require('app/src/assets/images/area4_image1.png'),
  area4Image2: require('app/src/assets/images/area4_image2.png'),
  area4Image3: require('app/src/assets/images/area4_image3.png'),
  area4Image4: require('app/src/assets/images/area4_image4.png'),
  area4Image5: require('app/src/assets/images/area4_image5.png'),
  area5Image1: require('app/src/assets/images/area5_image1.png'),
  area5Image2: require('app/src/assets/images/area5_image2.png'),
  area5Image3: require('app/src/assets/images/area5_image3.png'),
  area5Image4: require('app/src/assets/images/area5_image4.png'),
  area5Image5: require('app/src/assets/images/area5_image5.png'),
  area6Image1: require('app/src/assets/images/area6_image1.png'),
  area6Image2: require('app/src/assets/images/area6_image2.png'),
  area6Image3: require('app/src/assets/images/area6_image3.png'),
  area6Image4: require('app/src/assets/images/area6_image4.png'),
  area6Image5: require('app/src/assets/images/area6_image5.png'),
  area7Image1: require('app/src/assets/images/area7_image1.png'),
  area7Image2: require('app/src/assets/images/area7_image2.png'),
  area7Image3: require('app/src/assets/images/area7_image3.png'),
  area7Image4: require('app/src/assets/images/area7_image4.png'),
  area7Image5: require('app/src/assets/images/area7_image5.png'),
  area7Image6: require('app/src/assets/images/area7_image6.png'),
  area7Image7: require('app/src/assets/images/area7_image7.png'),
  area7Image8: require('app/src/assets/images/area7_image8.png'),
  area8Image1: require('app/src/assets/images/area8_image1.png'),
  area8Image2: require('app/src/assets/images/area8_image2.png'),
  area8Image3: require('app/src/assets/images/area8_image3.png'),
  area8Image4: require('app/src/assets/images/area8_image4.png'),
  area8Image5: require('app/src/assets/images/area8_image5.png'),
  area8Image6: require('app/src/assets/images/area8_image6.png'),
  area8Image7: require('app/src/assets/images/area8_image7.png'),
  seinaLineQrCode: require('app/src/assets/images/seinaLineQrCode.png'),
  sahoLineQrCode: require('app/src/assets/images/sahoLineQrCode.png'),
  area8Image8: require('app/src/assets/images/area8_image8.png'),
  gray_microscope: require('app/src/assets/images/gray_ microscope.png'),
  gray_bulb: require('app/src/assets/images/gray_bulb.png'),
  gray_dog_cat: require('app/src/assets/images/gray_dog_cat.png'),
  gray_heart: require('app/src/assets/images/gray_heart.png'),
  gray_school_cap: require('app/src/assets/images/gray_school_cap.png'),
  gray_tree: require('app/src/assets/images/gray_tree.png'),
  gray_turtle: require('app/src/assets/images/gray_turtle.png'),
  gray_union: require('app/src/assets/images/gray_union.png'),
  map_pin: require('app/src/assets/images/map_pin.png'),
  plasticBottle: require("app/src/assets/images/plasticBottle.png"),
  co2Clouds: require("app/src/assets/images/co2Clouds.png"),
  bottle: require("app/src/assets/images/bottle.png"),
  footprint: require("app/src/assets/images/footprint.png"),
  drinksHistory: require("app/src/assets/images/drinks_history.png"),
  favorite: require("app/src/assets/images/favorite.png"),
  creditcards: require("app/src/assets/images/creditcards.png"),
  wrenchScrewdriver: require("app/src/assets/images/wrench_screwdriver.png"),
  wing: require("app/src/assets/images/wing.png"),
  shield: require("app/src/assets/images/shield.png"),
  clipboard: require("app/src/assets/images/clipboard.png"),
  legalPaper: require("app/src/assets/images/legal_paper.png"),
  company: require("app/src/assets/images/company.png"),
  loudspeaker: require("app/src/assets/images/loudspeaker.png"),
  balloon: require("app/src/assets/images/balloon.png"),
  figureImage: require("app/src/assets/images/figureImage.png"),
  bell: require("app/src/assets/images/bell.png"),
  download: require("app/src/assets/images/download.png"),
  elipse: require("app/src/assets/images/elipse.png"),
  selectedArrow: require("app/src/assets/images/selectedArrow.png"),
  notSelectedArrow: require("app/src/assets/images/notSelectedArrow.png"),
  underArrow: require("app/src/assets/images/under_arrow.png"),
  progressArrow: require("app/src/assets/images/progress_arrow.png"),











  // tab
  iconAdd: require('app/src/assets/images/tab/iconAdd.png'),
  iconHome: require('app/src/assets/images/tab/iconHome.png'),
  iconMyPage: require('app/src/assets/images/tab/iconMyPage.png'),
  iconPortfolio: require('app/src/assets/images/tab/iconPortfolio.png'),
  iconSearch: require('app/src/assets/images/tab/iconSearch.png'),
  tabBar: require('app/src/assets/images/tab/tabBar.png'),
  tab_megu_graph: require("app/src/assets/images/tab_megu_graph.png"),
  tab_square: require("app/src/assets/images/tab_square.png"),
}
