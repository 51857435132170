

import React from 'react'

//libs
import { goToContact } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { isWeb } from 'app/src/constants/platform'
import { blue } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { ReportCard, styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { ActivistMenu } from '../../advanced/activist/menu'
import { windowWidth, windowHeight } from 'app/src/constants/layout'
import { images } from 'app/src/libs/images'
import { StyleSheet, View, ScrollView, Image } from 'react-native'




type ContainerProps = {}

type Props = {
  navigation: any
}

export const CompanyScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()

  return <UI navigation={navigation} />
}

const UI: React.FC<Props & ContainerProps> = ({ navigation }) => {
  return (
    <>

      <View >
        <View style={basicStyles.container}>
          <View style={basicStyles.bodyContainer}>
            <View>
              <ActivistMenu />
            </View>

            <ScrollView style={basicStyles.scrollContainer}>
              <View style={styles.headerContent}>
                <Text type={'xxlarge'} align={'center'}>
                  運営会社
                </Text>
              </View>

              <View style={styles.headerImage}>
                <Image style={styles.beeroLogo} source={images['beeroLogo']} />

              </View>

              <View style={styles.mainContent}>
                <View style={styles.content}>
                  <View>
                    <Text type={'medium'} style={styles.title}>
                      会社名
                    </Text>
                  </View>
                  <View />
                  <View>

                    <Text type={'medium'} style={styles.text}>
                      株式会社beero
                    </Text>
                  </View>


                </View>
                <View style={styles.content}>
                  <Text type={'medium'} style={styles.title}>
                    役員
                  </Text>
                  <Text type={'medium'} style={styles.text}>
                    CEO 越島 悠介{'\n'}
                    CTO 為藤 アキラ
                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'medium'} style={styles.title}>
                    所在地
                  </Text>
                  <Text type={'medium'} style={styles.text}>
                    〒150-0011{'\n'}
                    東京都渋谷区東１−１３−１
                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'medium'} style={styles.title}>
                    資本金
                  </Text>
                  <Text type={'medium'} style={styles.text}>
                    ¥1,000,000
                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'medium'} style={styles.title}>
                    事業内容
                  </Text>
                  <Text type={'medium'} style={styles.text}>
                    サービス開発
                  </Text>
                </View>
                <View style={styles.content}>
                  <Text type={'medium'} style={styles.title}>
                    販売価格
                  </Text>
                  <Text type={'medium'} style={styles.text}>
                    アクションの詳細ページ及び購入手続きの際に画面に表示されます。{'\n'}
                    消費税は内税として表示しております。
                  </Text>
                </View>
                <View style={styles.content}>
                  <View>
                    <Text type={'medium'} style={styles.title}>
                      販売価格以外でお客様に{'\n'}発生する金銭
                    </Text>
                  </View>
                  <View />
                  <View>

                    <Text type={'medium'} style={styles.text}>
                      当サイトのページの閲覧、コンテンツ購入、ソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金は、お客様のご負担となります。また開催されるアクションがオンライン上での開催でなく、物理的に集る必要がある場合の交通費はお客様のご負担となります。また当サイト上で物品を購入された場合の送料が別途記載されている場合はお客様のご負担となります。
                    </Text>
                  </View>


                </View>


              </View>
            </ScrollView>




          </View>
        </View>

        <Footer />
      </View>


    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 48,

  },
  scrollContainer: {
    flex: 1,
    width: windowWidth - 180,
    zIndex: -100


  },
  headerContent: {
    marginTop: 20,
    marginBottom: 34,
    margin: 40

  },
  mainContent: {
    marginHorizontal: 28,
    fontSize: 14,
  },
  content: {
    flexDirection: 'row',
    paddingBottom: 25,
    display: "flex",

    justifyContent: "space-between"
  },
  title: {
    fontSize: 11,
    width: windowWidth / 2 - 135,
    textAlign: 'right',
    marginRight: 20,
  },
  text: {
    fontSize: 11,
    flex: 1,

    width: windowWidth / 2 - 90,

    textAlign: "left",
    // backgroundColor: "red"
    paddingRight: 30



  },
  linkText: {
    fontSize: 11,
    color: blue,
    borderBottomWidth: 1,
    borderBottomColor: blue,
  },
  textContent: {

    maxWidth: windowWidth - 200,
    paddingRight: 30

  },
  beeroLogo: {
    marginBottom: 24,
    width: 189,
    height: 65,
    margin: "auto"

  },
  headerImage: {
    width: windowWidth - 180,
    textAlign: "center",


  },
})

