import React, { useState, useEffect } from 'react'
import { StyleSheet, View, Image, ActivityIndicator } from 'react-native'
import { stripeCreateConnectUser } from 'app/src/libs/firebase'
import { goToBasicInfo, goToHome, goToSignupFinish } from 'app/src/libs/screen-transition'
import { useNavigation } from 'app/src/hooks/use-navigation'
import { primaryColor, white } from 'app/src/constants/colors'
import * as SplashScreen from 'expo-splash-screen'
import * as Font from 'expo-font'
import { Asset } from 'expo-asset'
import { activateKeepAwake } from 'expo-keep-awake'
import { auth } from 'app/src/libs/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import {
  doc,
  updateDoc,
} from 'firebase/firestore'
import { db } from 'app/src/libs/firebase'

// libs

// contexts

// libs
import { images } from 'app/src/libs/images'
import { fonts } from 'app/src/libs/fonts'

// constants
import { isWeb } from 'app/src/constants/platform'
import { windowWidth } from 'app/src/constants/layout'

type ContainerProps = {}

export const ActivistConnectScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()

  const [isSplashComplete, setIsSplashComplete] = useState(false)
  const [isLoadingComplete, setIsLoadingComplete] = useState(false)

  useEffect(() => {
    const setup = async () => {
      onAuthStateChanged(auth, async activist => {
        if (activist) {
          console.log(activist.uid)
            const search = location.search
            const preCode = search.split('&')[0]
            const code = preCode.split('=')[1]

            stripeCreateConnectUser(code, activist.uid)
              .then(async (value:any) => {
                const data = value.success.data
                console.log("data",data)
                const ref = doc(db, 'activists', activist.uid)
                await updateDoc(ref, {
                  stripeAccountId:data,
                  isSetUp: true })
                goToBasicInfo(navigation)
              })
              .catch(err => {
                console.log("err",err)
                alert(err)
              })

        } else {
          goToHome(navigation)
        }
      })
    }

    setup()
  }, [])

  const _loadResourcesAsync = async () => {
    SplashScreen.hideAsync()
    try {
      if (!isWeb) {
        await Asset.loadAsync(Object.keys(images).map(key => images[key]))
      }
      await Font.loadAsync(fonts)

      // Analytics.init(Constants.deviceId || 'expo client')
      if (__DEV__) {
        activateKeepAwake()
      }
      setIsLoadingComplete(true)
    } catch {
      setIsLoadingComplete(true)
    }
  }

  return (
    <View style={{ flex: 1, backgroundColor: white, alignItems: 'center', justifyContent: 'center' }}>
      <Image source={require('app/src/assets/splash.png')} onLoad={_loadResourcesAsync} style={[{ width, height: '100%' }]} />
      <ActivityIndicator style={styles.loading} size="large" color={primaryColor} />
    </View>
  )
}

const width = windowWidth > 950 ? 950 : '100%'

const styles = StyleSheet.create({
  loading: {
    margin: '0 auto',
    position: 'absolute',
    top: 120,
    width,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000,
  },
})
