import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Dimensions, Image } from 'react-native'
import { Text, Button } from 'app/src/components/basics'
import { goToBasicInfo, goToHome, goToReportEditScreen, goToReportScreen, goToSignupFinish } from 'app/src/libs/screen-transition'
import { useNavigation } from 'app/src/hooks/use-navigation'
import {  fetchActivistInfo } from 'app/src/libs/firebase'
import { images } from '../../../../libs/images'
import { ActivistMenu } from 'app/src/components/advanced/activist/menu'
import { ActivistHeaderMenu } from 'app/src/components/advanced/activist/headerMenu'
import { styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { Pressable } from 'react-native-web-hover'
import { onAuthStateChanged } from 'firebase/auth'
import { db, auth } from 'app/src/libs/firebase'
import { doc, updateDoc, getDoc, arrayUnion } from 'firebase/firestore'

const { width, height } = Dimensions.get('window')

type ContainerProps = {}

export const ReportConfirmScreen: React.FC<ContainerProps> = () => {
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [image, setImage] = useState('')
  const date = new Date()
  const navigation = useNavigation()
  const [reportId, setReportId] = useState<string>('')
  const [outline, setOutline] = useState<string>('')
  const [thankYouMessage, setThankYouMessage] = useState<string>(`この度は当NPO法人に、ご支援金を賜りまして誠に有難うございました。{username}様の温かいご支援に、あらためて心より厚く御礼申し上げます。
  当団体の△△の事業において益々前進できますよう、お志を使わせていただく所存でございます。
  今後とも当財団の事業へのお力添えをどうぞよろしくお願いいたします。`)
  const [author, setAuthor] = useState<string>('')
  const [activistId, setActivistId] = useState<string>('')
  const [activistInfo, setActivistInfo] = useState<any>()
  const [imageUrl, setImageUrl] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [createdAt, setCreatedAt] = useState<number>(0)

  const [day, setDay] = useState<string>('')
  const [thankYouMessageImage, setThankYouMessageImage] = useState<string>('')

  async function _fetchActivistInfo(activistId: string, id: string) {
    const data = await fetchActivistInfo(activistId)

    if (data?.isSetUp) {
      setActivistInfo(activistInfo)
      setImageUrl(data?.imageUrl)

      const reportRef = doc(db, 'activists', activistId, 'reports', id)
      const reportResult = await getDoc(reportRef)
      const reportData = reportResult.data()
      setActivistInfo(data)
      setReportId(id)
      setActivistId(activistId)
      setTitle(reportData?.title)
      setBody(reportData?.body)
      setImage(reportData?.image)
      setOutline(reportData?.outline)
      setThankYouMessage(reportData?.thankYouMessage)
      setAuthor(reportData?.author)
      setThankYouMessageImage(reportData?.thankYouMessageImage)
      setCreatedAt(reportData?.createdAt)

      const date = new Date(reportData?.createdAt)
      const yearMonthDate = String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' + String(date.getDate())
      setDay(yearMonthDate)
    } else {
      goToBasicInfo(navigation)
    }
  }

  useEffect(() => {
    const fetch = async () => {
      await onAuthStateChanged(auth, async activist => {
        if (activist) {

            const reportId = localStorage.getItem('reportId')
            const selectedActivistId = localStorage.getItem("selectedActivistId")
            const id = selectedActivistId?selectedActivistId:activist.uid
            if (reportId) {
              setActivistId(id)
              await _fetchActivistInfo(id, reportId)
              setIsLoading(false)
            } else {
              goToReportScreen(navigation)
            }

        } else {
          goToHome(navigation)
        }
      })
    }
    fetch()
  }, [])

  async function send() {
    const activistRef = doc(db, 'activists', activistId)
    const activistResult = await getDoc(activistRef)
    const reportRef = doc(db, 'activists', activistId, 'reports', reportId)

    const id = activistResult.data()?.preSendingReportIdsList[0]
    if (id) {


      await updateDoc(reportRef, {
        isDraft: false,
      }).then(() => {
        goToReportScreen(navigation)
      })

    } else {


      await updateDoc(activistRef, {
        preSendingReportIdsList: [],
      })

      await updateDoc(activistRef, {
        preSendingReportIdsList: arrayUnion(reportId),
      }).then(async () => {
        const reportResult = await getDoc(reportRef)
        const reportData = reportResult.data()

        await updateDoc(reportRef, {
          isDraft: false,
        }).then(() => {
          goToReportScreen(navigation)
        })
      })

    }

  }





  async function save() {



    const activistRef = doc(db, 'activists', activistId)
    const activistResult = await getDoc(activistRef)
    const reportRef = doc(db, 'activists', activistId, 'reports', reportId)

    const id = activistResult.data()?.preSendingReportIdsList[0]
    if (id) {


      await updateDoc(reportRef, {
        isDraft: false,
      }).then(() => {
        goToReportScreen(navigation)
      })

    } else {


      await updateDoc(activistRef, {
        preSendingReportIdsList: [],
      })

      await updateDoc(activistRef, {
        preSendingReportIdsList: arrayUnion(reportId),
      }).then(async () => {

        await updateDoc(reportRef, {
          isDraft: false,
        }).then(() => {
          goToReportScreen(navigation)
        })
      })

    }

  }

  const returnHome = async () => {

    localStorage.setItem("reportId", reportId)
    goToReportEditScreen(navigation)
  }

  return (
    <View>
      <View>
        <View style={basicStyles.container}>
          <View style={basicStyles.bodyContainer}>
            <View>
              <ActivistMenu />
            </View>
            <View style={basicStyles.scrollContainer}>
              <ActivistHeaderMenu activistId={activistId} imageUrl={imageUrl} title={'活動報告'} />

              <View style={basicStyles.cardContainer}>
                <View style={basicStyles.card}>
                  <View style={styles.container}>
                    <View>
                      <View style={styles.cardHeader}>
                        <Text style={basicStyles.cardTitle}>活動報告の見え方</Text>

                      </View>
                      <Text style={styles.reportTitle}>{title}</Text>
                      <Text style={styles.dayText}>{day}</Text>
                      <Image style={styles.bodyImage} source={{ uri: thankYouMessageImage }} />
                      <Text style={styles.bodyText}>{body}</Text>
                    </View>
                    <View>
                      <View style={styles.cardHeader}>
                        <Text style={basicStyles.cardTitle}>Thank you messageの見え方</Text>

                      </View>
                      <View style={styles.thankYouMessageContainer}>
                        <View style={styles.thankYouMessageHeader}>
                          <Image source={images['iconMeguBig']} style={styles.meguIcon} />

                          <Text style={styles.dateExplanation}>
                            {date.getMonth() + 1}月中にいただいたご支援に対してThank you メッセージが届きました！
                          </Text>
                        </View>

                        <View style={styles.messageBodyContainer}>
                          <View style={styles.logoContainer}>
                            <Image source={{ uri: imageUrl }} style={styles.logo} />
                          </View>

                          <Image source={{ uri: thankYouMessageImage }} style={styles.thankYouMessageImageContainer} />

                          <View style={styles.reportDate}>
                            <Text>{activistInfo?.activistName}</Text>

                            <Text>{date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()}</Text>
                          </View>
                          <View style={styles.messageBody}>
                            <View>
                              <Text style={styles.thankYouMessageText}>{thankYouMessage}</Text>
                            </View>

                            <View style={styles.author}>
                              <Text style={styles.thankYouMessageText}>{author}</Text>
                            </View>
                          </View>
                        </View>

                        <View style={styles.shopListContainer}>
                          <Text style={styles.confirmShooList}>支援金をお送りいただいたサイトからも確認いただけます！</Text>
                          <Image source={images['iconThankYou']} style={styles.thankYouImage} />
                        </View>
                      </View>
                    </View>
                  </View>
                  <View>
                    <View style={basicStyles.rightBottomButtonContainer}>

                      <Text style={styles.alert}>
                        Thank you messageと共に公開する場合は25日に公開されます。
                        活動報告だけの場合は即時公開されます。
                      </Text>
                    </View>
                  </View>

                  <View style={basicStyles.btnContainer}>

                    <View style={basicStyles.rightBottomButtonContainer}>


                      <Pressable>
                        {data => {
                          if (data.hovered) {
                            return (
                              <Button buttonType={19} onPress={() => returnHome()}>
                                戻る
                              </Button>
                            )
                          } else {
                            return (
                              <Button buttonType={11} onPress={() => returnHome()}>
                                戻る
                              </Button>
                            )
                          }
                        }}
                      </Pressable>

                      <Pressable style={basicStyles.submitButton}>
                        {data => {
                          if (data.hovered) {
                            return (
                              <Button buttonType={3} onPress={() => save()}>
                                下書きとして保存
                              </Button>
                            )
                          } else {
                            return <Button onPress={() => save()}>下書きとして保存</Button>
                          }
                        }}
                      </Pressable>
                      <View>


                        <Pressable style={basicStyles.submitButton}>

                          {data => {
                            if (data.hovered) {
                              return (
                                <Button buttonType={3} onPress={() => send()}>
                                  送信する
                                </Button>
                              )
                            } else {
                              return <Button onPress={() => send()}>送信する</Button>
                            }
                          }}
                        </Pressable>
                      </View>

                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  thankYouMessageContainer: {
    width: 300,
    borderColor: '#DDDDDD',
    borderWidth: 1,
    backgroundColor: 'white',
    textAlign: 'center',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
  },
  messageContainer: {
    backgroundColor: '#F4F4F4',
    width: '90%',
  },
  meguIcon: {
    resizeMode: 'contain',
    height: 100,
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 35,
    marginBottom: 28,
  },
  messageBodyContainer: {
    backgroundColor: '#F4F4F4',
    width: 280,
    margin: 'auto',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'left',
    fontSize: 7,
    paddingBottom: 30,
  },
  logo: {
    resizeMode: 'contain',
    height: 40,
    width: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  logoContainer: {
    backgroundColor: 'white',
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 20,
    marginBottom: 20,
  },
  thankYouMessageImageContainer: {
    width: 240,
    height: 220,
    resizeMode: 'contain',
  },
  messageBody: {
    backgroundColor: 'white',
    width: 200,
    padding: '5%',
    margin: 'auto',
  },

  author: {
    marginTop: '5%',
    textAlign: 'left',
  },
  thankYouMessageHeader: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  shopListContainer: {
    margin: 'auto',
    width: 270,
    marginTop: 23,
  },
  thankYouImage: {
    width: width * 0.1,
    height: height * 0.1,
    resizeMode: 'contain',
    margin: 'auto',
  },
  reportDate: {
    margin: 'auto',
    marginTop: 20,
    marginBottom: 15,
  },
  headerContainer: {
    width: width * 0.77,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerTitle: {
    marginTop: 40,
    marginBottom: 35,
  },
  cardHeader: {
    marginBottom: 28,
  },
  reportTitle: {
    fontSize: 24,
  },
  dayText: {
    marginTop: 20,
    color: '#999',
  },
  bodyText: {
    maxWidth: width * 0.4,
  },
  dateExplanation: {
    width: 240,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 25,
    textAlign: 'center',
  },
  confirmShooList: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  bodyImage: {
    width: width * 0.4,
    height: height * 0.4,
    resizeMode: 'contain',
  },
  thankYouMessageText: {
    maxWidth: '90%',
  },
  alert: {
    marginTop: 60,
    fontSize: 14,
    color: "#CC0000",
    width: 428,
    textAlign: "right",
    fontWeight: 700

  },
  depth: {

    position: "relative",
    zIndex: -1

  }

})
