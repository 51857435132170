import React, { useState, useEffect } from 'react'
import { StyleSheet, View, Dimensions } from 'react-native'

import { Text } from 'app/src/components/basics/text'
import { ActivistMenu } from 'app/src/components/advanced/activist/menu'
import { Button } from 'app/src/components/basics'
import axios from 'axios'
import { useNavigation } from 'app/src/hooks/use-navigation'
import { goToActivistMypage, goToBasicInfo, goToDonations, goToHome, goToSignupFinish } from 'app/src/libs/screen-transition'
import { createPdf, stripeTransfer } from 'app/src/libs/firebase'
import { ActivistHeaderMenu } from 'app/src/components/advanced/activist/headerMenu'
import {  Loader, styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { auth, db } from 'app/src/libs/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import {
  doc,
  getDoc
} from 'firebase/firestore'

type ContainerProps = {}

const { width, height } = Dimensions.get('window')

export const DepositScreen: React.FC<ContainerProps> = () => {
  const [amount, setAmount] = useState<number>(0)
  const [imageUrl, setImageUrl] = useState<string>('')
  const [activistName, setActivistName] = useState<string>('')
  const [activistId, setActivistId] = useState<string>('')
  const [shopifyDeposit, setShopifyDeposit] = useState<number>(0)
  const [otherDeposit, setOtherDeposit] = useState<number>(0)
  const [meguDeposit, setMeguDeposit] = useState<number>(0)
  const [bankName, setBankName] = useState<string>('')
  const [bankBranchName, setBankBranchName] = useState<string>('')
  const [bankAccountNumber, setBankAccountNumber] = useState<number>()
  const [bankAccountType, setBankAccountType] = useState<number>()
  const [bankBranchCode, setBankBranchCode] = useState<string>('')
  const [bankCode, setBankCode] = useState<string>('')
  const [stripeAccountId, setStripeAccountId] = useState<string>('')
  const [totalDeposit, setTotalDeposit] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const navigation = useNavigation()

  useEffect(() => {
    const f = async () => {
      await onAuthStateChanged(auth, async activist => {
        if (activist) {

          const selectedActivistId = localStorage.getItem("selectedActivistId")
          const id = selectedActivistId?selectedActivistId:activist.uid
            const ref = doc(db, 'activists', id)

            const result = await getDoc(ref)

            const data = result.data()
            if(!data){

              alert('団体名及びに口座情報を入力してください')

              goToActivistMypage(navigation)
              return
            }
            if (data.isSetUp) {
              if (
                data.activistName &&
                data.bankName &&
                data.bankCode &&
                data.bankBranchName &&
                data.bankBranchCode &&
                data.bankAccountNumber &&
                data.bankAccountType
              ) {

                if(!data.stripeAccountId){

                  alert('送金するためにはStripeでアカウントを作成する必要がありますので、お問合せください')


                  // localStorage.setItem("mypage", "3")

                  // goToActivistMypage(navigation)


                  return




                }


                if (data.totalDeposit >= 10000) {
                  setActivistName(data.activistName)
                  setActivistId(id)
                  setBankName(data.bankName)
                  setBankCode(data.bankCode)
                  setBankBranchName(data.bankBranchName)
                  setBankAccountNumber(data.bankAccountNumber)
                  setBankAccountType(data.bankAccountType)
                  setBankBranchCode(data.bankBranchCode)
                  setMeguDeposit(data.meguDeposit)
                  setTotalDeposit(data.totalDeposit)
                  setShopifyDeposit(data.shopifyDeposit)
                  setOtherDeposit(data.otherDeposit)
                  setStripeAccountId(data.stripeAccountId)
                  setImageUrl(data.imageUrl)
                } else {
                  goToActivistMypage(navigation)
                }
              } else {
                alert('団体名及びに口座情報を入力してください')

                goToActivistMypage(navigation)
              }
            } else {
              goToBasicInfo(navigation)
            }

        } else {
          goToHome(navigation)
        }

        setIsLoading(false)
      })
    }
    f()
  }, [])

  const requestWithdraw = async () => {





    if (activistName && bankName && bankCode && bankBranchName && bankBranchCode && bankAccountNumber && bankAccountType && stripeAccountId) {


      setIsLoading(true)
      await stripeTransfer(activistId, stripeAccountId)

      await createPdf()


      const BASE_URL = 'https://hooks.slack.com/services/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'
      const options: any = {
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        data: `payload={
                  "channel": "#notification-withdraw",
                  "username": "notification",
                  "text": "団体名:${activistName}\n団体ID:${activistId}\n金融機関コード:${bankCode}\n銀行名:${bankName}\n支店コード:${bankBranchCode}\n支店名:${bankBranchName}\n預金種類:${bankAccountType}\n口座番号:${bankAccountNumber}\n出金申請額:${shopifyDeposit}円"
              }`,
      }
      await axios.request(options)


      setIsLoading(false)

      goToDonations(navigation)
    } else {
      alert('口座情報を入力してください')


      localStorage.setItem("mypage", "3")

      goToActivistMypage(navigation)
    }
  }

  return (


    <View>
      <View>
        <View style={basicStyles.container}>
          <View style={basicStyles.bodyContainer}>
            <ActivistMenu pageTitle={'donations'} />
            <View>
              <ActivistHeaderMenu activistId={activistId} imageUrl={imageUrl} title={'支援一覧'} />

              <View style={basicStyles.scrollContainer}>

                <View style={basicStyles.cardContainer}>
                  <View style={basicStyles.myPageCard}>
                    <Text style={basicStyles.cardTitle}>出金依頼</Text>
                    <View>
                      <View style={styles.totalAmountContainer}>
                        <Text>
                          累積支援金<Text style={basicStyles.amountText}>{totalDeposit.toLocaleString()}</Text>
                          <Text> 円</Text>
                        </Text>
                      </View>
                    </View>
                    {totalDeposit >= 10000 ? (
                      <>
                      {isLoading?<View style={[styles.saveBtn,styles.loaderContaienr]}><Loader /></View>:<Button style={styles.saveBtn} onPress={() => requestWithdraw()}>出金を依頼する</Button>}
                      </>
                    ) : (
                      <span />
                    )}
                  </View>
                </View>
              </View>






            </View>


          </View>
        </View>
      </View>
    </View>
  )
}


const sleep = waitTime => new Promise( resolve => setTimeout(resolve, waitTime) );


const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  depositContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  hovered: {
    borderColor: '#999999',
    width: 140,
    height: 50,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
    flexDirection: 'row',
    marginLeft: 45,
    textAlign: 'center',
    paddingTop: 15,
  },
  notHovered: {
    borderColor: '#DDDDDD',
    width: 140,
    height: 50,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
    flexDirection: 'row',
    marginLeft: 45,
    textAlign: 'center',
    margin: 'auto',
    paddingTop: 15,
  },
  cardContainer: {
    height: 600,
    width: width * 0.85,
  },
  saveBtn: {
    right: 45,
    position: 'absolute',
    bottom: 38,
  },
  bell: {
    height: width * 0.02,
    width: height * 0.03,
  },
  rightIcon: {
    flexDirection: 'row',
    marginEnd: width * 0.1,
  },
  mypageContent: {
    width: width * 0.75,
  },
  mypageItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  announceLogo: {
    width: width * 0.2,
  },
  headerItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerFourItems: {
    flexDirection: 'row',
  },
  selectedMenu: {
    backgroundColor: 'white',
    padding: 50,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notSelectedMenu: {
    padding: 50,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    position: 'absolute',
    left: -(width * 0.147),
    right: 0,
    top: 0,
    bottom: 0,
    height: 3,
    marginTop: 31,
    width: width / 10,
  },

  logout: {
    marginTop: height * 0.07,
  },
  backgroundImageContent: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  backgroundImage: {
    width: '110%',
    height: '100%',
  },
  logoContainer: {
    width: '30%',
    height: '30%',
  },
  headerContainer: {
    width: width * 0.77,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  title: {
    marginTop: 40,
    marginBottom: 35,
  },
  headerText: {
    marginLeft: 30,
    marginTop: 36,
    fontWeight: 'bold',
    fontSize: 14,
  },
  totalAmountContainer: {
    marginTop: 51,
    marginBottom: 49,
  },
  amountText: {
    fontWeight: 'bold',
    fontSize: 24,
    marginLeft: 12,
    marginRight: 12,
  },
  textFiled: {
    borderWidth: 1,
    borderColor: '#DDDDDD',
    width: 313,
    height: 40,
  },
  textFiledContainer: {
    marginLeft: 30,
  },
  loaderContaienr:{
    width:300

  }
})
