import React from 'react'
import { StyleSheet, ImageBackground, TextInput as RNTextInput, View, KeyboardType, Platform, TouchableOpacity } from 'react-native'

// constants
import { lightGray } from 'app/src/constants/colors'

//components
import { Text } from './index'

//libs

import { Hoverable } from 'react-native-web-hover'
import { styles as basicStyles, ThankYouImageFunc } from 'app/src/components/advanced/activist/basic'
import Autocomplete from 'react-native-autocomplete-input';


type Props = {
  label?: string
  onChangeText: (value: string) => void
  value: string
  validation?: string
  placeholder?: string
  translucentGrayBackground?: boolean
  secureTextEntry?: boolean
  keyboardType?: KeyboardType
  isRequired?: boolean
  numberOfLines?: number
  maxLength?: number
  style?: any
  multiple: number
  filtered?: string[]
  code?: (v: any) => void
  onBlur?: (v: any) => void
  error?: any
  type?: string

}

export const TextInput: React.FC<Props> = ({
  label,
  onChangeText,
  value = '',
  validation,
  placeholder,
  translucentGrayBackground = false,
  secureTextEntry = false,
  keyboardType,
  isRequired = false,
  numberOfLines = 0,
  maxLength,
  style,
  multiple,
  filtered,
  code,
  onBlur,
  error,
  type
}) => {


  const errorMultiple = (multiple: number, hovered) => {


    switch (multiple) {


      case 1:
        if (hovered) {


          return {
            borderWidth: 1,
            borderColor: '#40A9FF',
            paddingLeft: 10,
            boxSizing: 'border-box',
            boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
            borderRadius: 2,
            paddingTop: 14,
            paddingBottom: 14,
            height: 160,
            resize: "vertical",
            minWidth: 560
          }

        } else {

          return {
            borderWidth: 1,
            borderColor: '#F5222D',
            paddingLeft: 10,
            boxSizing: 'border-box',
            borderRadius: 2,
            paddingTop: 14,
            paddingBottom: 14,
            height: 160,
            resize: "vertical",
            minWidth: 560
          }
        }

      case 2:
        if (hovered) {


          return {
            borderWidth: 1,
            borderColor: '#40A9FF',
            paddingLeft: 10,
            boxSizing: 'border-box',
            boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
            borderRadius: 2,
            paddingTop: 14,
            paddingBottom: 14,
            with: 320,
            height: 40
          }

        } else {

          return {
            borderWidth: 1,
            borderColor: '#F5222D',
            paddingLeft: 10,
            borderRadius: 2,
            paddingTop: 14,
            paddingBottom: 14,
            with: 320,
            height: 40
          }
        }
      // return
      case 4:

        if (hovered) {


          return {
            borderWidth: 1,
            borderColor: '#40A9FF',
            paddingLeft: 10,
            boxSizing: 'border-box',
            height: 40,
            width: 320,
            paddingTop: 14,
            paddingBottom: 14,
            boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
            borderRadius: 2,
          }

        } else {

          return {
            paddingTop: 14,
            borderRadius: 2,
            height: 40,
            width: 320,
            paddingBottom: 14,
            paddingLeft: 10,
            borderColor: '#F5222D',
            boxSizing: 'border-box',
            backgroundColor: '#FFFFFF',
            borderWidth: 1,

          }
        }

      case 6:

        if (hovered) {


          return {
            borderWidth: 1,
            borderColor: '#40A9FF',
            paddingLeft: 10,
            boxSizing: 'border-box',
            height: 40,
            width: 320,
            paddingTop: 14,
            paddingBottom: 14,
            boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
            borderRadius: 2,
          }
        } else {

          return {
            borderWidth: 1,
            borderColor: '#F5222D',
            paddingLeft: 10,
            paddingTop: 14,
            borderRadius: 2,
            height: 40,
            width: 320,
            paddingBottom: 14,
          }
        }
      case 7:
        if (hovered) {


          return {
            borderWidth: 0,
            borderColor: 'transparent',

            height: 40,
            width: 320,
            paddingLeft: 10





          }
        } else {

          return {
            borderWidth: 0,
            borderColor: '#F5222D',

            height: 40,
            width: 320,
            paddingLeft: 10



          }
        }
      case 8:
        if (hovered) {


          return {
            borderWidth: 1,
            borderColor: '#40A9FF',
            paddingLeft: 10,
            boxSizing: 'border-box',
            height: 40,
            width: 320,
            paddingTop: 14,
            paddingBottom: 14,
            boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
            borderRadius: 2,
          }
        } else {

          return {
            borderWidth: 1,
            borderColor: '#F5222D',
            paddingLeft: 10,
            paddingTop: 14,
            borderRadius: 2,
            height: 40,
            width: 320,
            paddingBottom: 14,
          }
        }

    }
  }


  const normalMultiple = (multiple: number, hovered) => {


    switch (multiple) {


      case 1:
        if (hovered) {


          return {
            borderWidth: 1,
            borderColor: '#40A9FF',
            paddingLeft: 10,
            boxSizing: 'border-box',
            boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
            borderRadius: 2,
            paddingTop: 14,
            paddingBottom: 14,
            height: 160,
            resize: "vertical",
            minWidth: 560

          }

        } else {

          return {


            borderWidth: 1,
            borderColor: '#ddd',
            paddingLeft: 10,
            boxSizing: 'border-box',
            borderRadius: 2,
            paddingTop: 14,
            paddingBottom: 14,
            height: 160,
            resize: "vertical",
            minWidth: 560
          }
        }

      case 2:
        if (hovered) {


          return {
            borderWidth: 1,
            borderColor: '#40A9FF',
            paddingLeft: 10,
            boxSizing: 'border-box',
            boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
            borderRadius: 2,
            paddingTop: 14,
            paddingBottom: 14,
            with: 320,
            height: 40
          }

        } else {

          return {
            borderWidth: 1,
            borderColor: '#ddd',
            paddingLeft: 10,
            borderRadius: 2,
            paddingTop: 14,
            paddingBottom: 14,
            with: 320,
            height: 40
          }
        }
      // return
      case 4:

        if (hovered) {


          return {
            borderWidth: 1,
            borderColor: '#40A9FF',
            paddingLeft: 10,
            boxSizing: 'border-box',
            height: 40,
            width: 320,
            paddingTop: 14,
            paddingBottom: 14,
            boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
            borderRadius: 2,
          }

        } else {

          return {
            paddingTop: 14,
            borderRadius: 2,
            height: 40,
            width: 320,
            paddingBottom: 14,
            paddingLeft: 10,
            background: '#ddd',
            border: '1px solid #DDDDDD',
            boxSizing: 'border-box',
            backgroundColor: '#FFFFFF',
          }
        }

      case 6:

        if (hovered) {


          return {
            borderWidth: 1,
            borderColor: '#40A9FF',
            paddingLeft: 10,
            boxSizing: 'border-box',
            height: 40,
            width: 320,
            paddingTop: 14,
            paddingBottom: 14,
            boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
            borderRadius: 2,
          }
        } else {

          return {
            borderWidth: 1,
            borderColor: '#ddd',
            paddingLeft: 10,
            paddingTop: 14,
            borderRadius: 2,
            height: 40,
            width: 320,
            paddingBottom: 14,
          }
        }
      case 7:
        if (hovered) {


          return {
            borderWidth: 0,
            borderColor: 'transparent',

            height: 40,
            width: 320,
            paddingLeft: 10





          }
        } else {

          return {
            borderWidth: 0,
            borderColor: 'transparent',
            boxSizing: 'border-box',
            height: 40,
            width: 320,
            paddingLeft: 10





          }
        }
      case 8:
        if (hovered) {


          return {
            borderWidth: 1,
            borderColor: '#40A9FF',
            paddingLeft: 10,
            boxSizing: 'border-box',
            height: 40,
            width: 320,
            paddingTop: 14,
            paddingBottom: 14,
            boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
            borderRadius: 2,
          }
        } else {

          return {
            borderWidth: 1,
            borderColor: '#ddd',
            paddingLeft: 10,
            paddingTop: 14,
            borderRadius: 2,
            height: 40,
            width: 320,
            paddingBottom: 14,
          }
        }

    }
  }

  const inputStatus = (hovered, error, multiple) => {


    if (error) {

      return errorMultiple(multiple, hovered)

    } else {


      return normalMultiple(multiple, hovered)

    }




  }


  const autoCompleteStyle = (hovered, error) => {

    if (hovered) {


      return {
        borderWidth: 1,
        borderColor: '#40A9FF',



        height: 40,
        width: 320,






      }
    } else {

      if (error) {


        return {
          borderWidth: 1,
          borderColor: 'red',
          boxSizing: 'border-box',
          height: 40,
          width: 320
        }

      } else {


        return {
          borderWidth: 1,
          borderColor: '#ddd',
          boxSizing: 'border-box',
          height: 40,
          width: 320
        }


      }
    }
  }

  return (
    <View>
      <Text style={basicStyles.sectionTitle}>{label}</Text>

      {(() => {
        if (multiple === 1) {
          return (

            <Hoverable>
              {({ hovered }) => (
                <View>
                  <textarea
                    value={value}
                    onChange={e => onChangeText(e.target.value)}
                    rows="10"
                    cols="60"
                    style={inputStatus(hovered, error, multiple) ? inputStatus(hovered, error, multiple) : {}}
                  ></textarea>
                  {type && <Text>{error[type] && error[type]}</Text>}

                </View>

              )}

            </Hoverable>
          )
        } else if (multiple === 2) {
          return (
            <Hoverable>
              {({ hovered }) => (
                <View>
                  <RNTextInput
                    onBlur={onBlur}
                    style={inputStatus(hovered, error, multiple) ? inputStatus(hovered, error, multiple) : {}}
                    numberOfLines={numberOfLines && numberOfLines}
                    onChangeText={onChangeText}
                    value={value}
                    placeholder={placeholder}
                    secureTextEntry={secureTextEntry}
                    keyboardType={keyboardType}
                    maxLength={maxLength}
                  />

                  {type && <Text>{error[type] && error[type]}</Text>}


                </View>

              )}
            </Hoverable>
          )
        } else if (multiple == 4) {
          return (
            <Hoverable>
              {({ hovered }) => (
                <View>
                  <input
                    type="date"
                    value={value}
                    onChange={e => onChangeText(e.target.value)}
                    style={inputStatus(hovered, error, multiple) ? inputStatus(hovered, error, multiple) : {}}
                  />
                  {type && <Text>{error[type] && error[type]}</Text>}


                </View>

              )}
            </Hoverable>
          )
        } else if (multiple === 6) {

          return (


            <Hoverable>
              {({ hovered }) => (
                <View>

                  <RNTextInput
                    style={inputStatus(hovered, error, multiple) ? inputStatus(hovered, error, multiple) : {}}
                    numberOfLines={numberOfLines && numberOfLines}
                    onChangeText={onChangeText}
                    value={value}
                    placeholder={placeholder}
                    secureTextEntry={secureTextEntry}
                    keyboardType={keyboardType}
                    maxLength={maxLength}
                  />
                  {type && <Text>{error[type] && error[type]}</Text>}

                </View>
              )}
            </Hoverable>


          )

        } else if (multiple == 7) {

          return (



            <Hoverable>
              {({ hovered }) => (
                <View>
                  <Autocomplete

                    inputContainerStyle={autoCompleteStyle(hovered, error)}

                    renderTextInput={() => (
                      <RNTextInput
                        onBlur={onBlur}
                        style={inputStatus(hovered, error, multiple) ? inputStatus(hovered, error, multiple) : {}}
                        numberOfLines={numberOfLines && numberOfLines}
                        onChangeText={onChangeText}
                        value={value}
                        placeholder={placeholder}
                        secureTextEntry={secureTextEntry}
                        keyboardType={keyboardType}
                        maxLength={maxLength}
                      />
                    )}



                    autoCorrect={false}
                    autoCapitalize="none"
                    data={filtered}
                    value={value}
                    onChangeText={onChangeText}
                    flatListProps={{
                      keyboardShouldPersistTaps: 'always',
                      keyExtractor: (item) => item.id,
                      renderItem: (({ item }) => (

                        <View
                          style={styles.itemTextContainer}
                        >
                          {code && <TouchableOpacity
                            onPress={() => code(item)}
                          >

                            <Hoverable>
                              {({ hovered }) => (
                                <View>
                                  {hovered ? <Text type='active'>
                                    {item}
                                  </Text> : <Text type='large'>
                                    {item}
                                  </Text>}

                                </View>

                              )}</Hoverable>
                          </TouchableOpacity>
                          }

                        </View>

                      ))
                    }}
                  />
                  {type && <Text>{error[type] && error[type]}</Text>}

                </View>

              )}
            </Hoverable>



          )


        } else if (multiple == 8) {

          return (
            <Hoverable>
              {({ hovered }) => (
                <View>

                  <RNTextInput
                    secureTextEntry
                    style={inputStatus(hovered, error, multiple) ? inputStatus(hovered, error, multiple) : {}}
                    numberOfLines={numberOfLines && numberOfLines}
                    onChangeText={onChangeText}
                    value={value}
                    placeholder={placeholder}
                    keyboardType={keyboardType}
                    maxLength={maxLength}
                  />
                  {type && <Text>{error[type] && error[type]}</Text>}

                </View>
              )}
            </Hoverable>
          )
        } else {
          return (
            <Hoverable>
              {({ hovered }) => (
                <RNTextInput
                  style={
                    hovered
                      ? {
                        borderWidth: 1,
                        borderColor: '#40A9FF',
                        paddingLeft: 10,
                        paddingTop: 14,
                        boxSizing: 'border-box',
                        height: 40,
                        width: '50%',
                        paddingBottom: 14,

                        boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
                        borderRadius: 2,
                      }
                      : {
                        width: '50%',
                        borderWidth: 1,
                        height: 40,
                        paddingTop: 14,
                        borderColor: '#DDDDDD',
                        paddingLeft: 10,
                        paddingBottom: 14,
                      }
                  }
                  numberOfLines={numberOfLines && numberOfLines}
                  onChangeText={onChangeText}
                  value={value}
                  placeholder={placeholder}
                  secureTextEntry={secureTextEntry}
                  keyboardType={keyboardType}
                  maxLength={maxLength}
                />
              )}
            </Hoverable>
          )
        }
      })()}
    </View>
  )
}

const styles = StyleSheet.create({
  formInput: {
    width: '100%',
    maxWidth: 310,
    // marginHorizontal: 32,
    marginBottom: 20,
  },
  inputImage: {
    width: '100%',
    minHeight: 46,
  },
  labelWrapper: {
    flexDirection: 'row',
    marginBottom: 1,
    position: 'absolute',
    top: 5,
    left: 10,
  },
  label: {
    fontSize: 8,
    color: lightGray,
    marginRight: 2,
  },
  labelRequired: {
    marginTop: -1,
    marginLeft: 2,
    fontSize: 8,
    color: '#cc0000',
  },
  input: {
    fontSize: 12,
    flex: 1,
    paddingHorizontal: 10,
    paddingTop: 16,
    textAlign: 'center',
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
  textArea: {
    fontSize: 12,
    flex: 1,
    paddingHorizontal: 10,
    paddingTop: 26,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
  validation: {
    color: '#CC0000',
    position: 'absolute',
    left: 0,
    top: -18,
  },
  itemTextContainer: {
    paddingLeft: 10,
    lineHeight: 14,
    borderColor: "#ddd",
    borderWidth: 1
  }
})
