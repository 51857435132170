import React, { useState } from 'react'
import {  View, TouchableOpacity } from 'react-native'
import { TextInput } from 'app/src/components/basics/text-input'
import { styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { Hoverable } from 'react-native-web-hover'
import { Text, Button } from 'app/src/components/basics'
import { Formik } from 'formik';
import * as Yup from "yup";
import {  app } from 'app/src/libs/firebase'
import { goToActivistSignin } from 'app/src/libs/screen-transition'
import { getAuth,  verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth'
import { useNavigation } from 'app/src/hooks/use-navigation'
import { goToActivistSignup, goToForgetPassword } from 'app/src/libs/screen-transition'


export const ResetPasswordScreen = () => {


    const [newPassword, setNewPassword] = useState<string>("")
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>("")


    const auth = getAuth(app)


    const navigation = useNavigation()


    const validation = () =>
        Yup.object().shape({
            newPassword: Yup.string().required("必須項目です").matches(/^[0-9a-zA-Z]+$/, '半額英数字で入力してください').min(8, '8文字以上入力してください'),
            confirmNewPassword: Yup.string().required("必須項目です").matches(/^[0-9a-zA-Z]+$/, '半額英数字で入力してください').min(8, '8文字以上入力してください'),

        });


    const save = async () => {




        const searchParams = new URLSearchParams(window.location.search)

        const oobCode = searchParams.get("oobCode")
        if (oobCode) {



            if (newPassword === confirmNewPassword) {


                verifyPasswordResetCode(auth, oobCode).then(() => {

                    confirmPasswordReset(auth, oobCode, newPassword).then(() => {

                        alert("パスワードを変更しました")
                        goToActivistSignin(navigation)
                    })
                        .catch((error) => {
                            console.log("error", error)
                        })


                })

            } else {

                alert("パスワードが一致しません")
            }





        }


    }



    return (
        <View>
            <TouchableOpacity onPress={() => goToActivistSignup(navigation)}>
                <View>
                    <Text style={basicStyles.goToActivistSignup}>初回登録の方はこちら　＞</Text>
                </View>
            </TouchableOpacity>



            <Formik
                initialValues={{
                    newPassword: newPassword,
                    confirmNewPassword: confirmNewPassword
                }}
                enableReinitialize={true}
                validationSchema={validation()}
                onSubmit={() => save()}
            >
                {({ setFieldValue, handleChange, handleBlur, handleSubmit, values, errors }) => (
                    <View style={basicStyles.entireContainer}>


                        <View style={basicStyles.passwordContainer}>

                            <Text style={basicStyles.cardTitle}>パスワードの再設定メールを送信</Text>




                            <View >

                                <View style={basicStyles.sectionContainer}>
                                    <TextInput
                                        onBlur={handleBlur('newPassword')}
                                        label={'新しいパスワード'}
                                        multiple={8}
                                        value={newPassword}
                                        type={"newPassword"}
                                        error={errors.newPassword ? errors : ""}


                                        onChangeText={async (e: any) => {
                                            setFieldValue("newPassword", e)
                                            setNewPassword(e)

                                        }}

                                    />
                                </View>

                                <View style={basicStyles.sectionContainer}>
                                    <TextInput
                                        onBlur={handleBlur('confirmNewPassword')}
                                        label={'新しいパスワード（確認）'}
                                        multiple={8}
                                        value={confirmNewPassword}
                                        type={"confirmNewPassword"}
                                        error={errors.confirmNewPassword ? errors : ""}


                                        onChangeText={async (e: any) => {
                                            setFieldValue("confirmNewPassword", e)
                                            setConfirmNewPassword(e)

                                        }}

                                    />


                                    <Hoverable>
                                        {({ hovered }) => (
                                            <View style={basicStyles.saveBtnContainer}>
                                                <Button style={basicStyles.saveBtn} buttonType={hovered ? 3 : 1} onPress={handleSubmit}>
                                                    保存する
                                                </Button>
                                            </View>
                                        )}
                                    </Hoverable>
                                </View>

                            </View>


                        </View>
                    </View>


                )}
            </Formik>

            <TouchableOpacity onPress={() => goToForgetPassword(navigation)}>


                <Text style={basicStyles.login}>
                    ログインに戻る場合はこちら

                </Text>

            </TouchableOpacity>
        </View>
    )


}



export default ResetPasswordScreen
