import React, { forwardRef, useCallback, useImperativeHandle } from 'react'
import { StyleSheet, View, Image, Dimensions, TouchableOpacity, ImageBackground } from 'react-native'
import { Text, Button } from 'app/src/components/basics'

import Dropzone from 'react-dropzone'
import { Hoverable, Pressable } from 'react-native-web-hover'
import { images } from 'app/src/libs/images'
import { Loader, styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { TextInput } from 'app/src/components/basics/text-input'
import styled from 'styled-components'
import { getRef, uploadStorage, uploadStringStorage, deleteStorage } from 'app/src/libs/firebase'
import { db } from 'app/src/libs/firebase'
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore'

import { pdfjs } from 'react-pdf'
import { ActivistHeaderImage } from './ ActivistHeaderImage'
import { getDownloadURL } from 'firebase/storage'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

import { Formik } from 'formik';
import * as Yup from "yup";
import imageCompression from 'browser-image-compression'


const { width, height } = Dimensions.get('window')

interface Props {
  whatWeDo: string
  setWhatWeDo: (whatWeDo: string) => void
  howWeDo: string
  setHowWeDo: (whatWeDo: string) => void
  activistId: string
  years: string[]
  setYears: (v: string[]) => void
  whatWeDoImage1: string
  setWhatWeDoImage1: (whatWeDoImage1: string) => void
  whatWeDoImage2: string
  setWhatWeDoImage2: (whatWeDoImage2: string) => void
  howWeDoImage1: string
  setHowWeDoImage1: (howWeDoImage1: string) => void
  howWeDoImage2: string
  setHowWeDoImage2: (howWeDoImage2: string) => void
  selectedYear: string
  setSelectedYear: (selectedYear: string) => void
  pdfs: string[]
  setPdfs: (pdfs: string[]) => void
  pdfImage: string
  setPdfImage: (pdfImage: string) => void
  howWeDoImages: string[]
  whatWeDoImages: string[]
  thumbnail: string
  isPdf: boolean
  setThumbnail: (t: string) => void
  setIsPdf: (v: boolean) => void
  setDoImages: (v: any) => void
  doImages: any



}




export const ActivistIntroduction = forwardRef((props: Props, ref) => {

  const validation = () =>
    Yup.object().shape({
      // email: Yup.string()
      //   .email("メールアドレスの形式で入力してください")
      //   .required("必須項目です"),
      whatWeDo: Yup.string()
        .required("必須項目です"),
      howWeDo: Yup.string()
        .required("必須項目です"),
    });




  useImperativeHandle(
    ref,
    () => ({
      save() {
        save()
      }
    }),
  )


  const saveBankInfo = async () => {
    const ref = doc(db, 'activists', props.activistId)

    await updateDoc(ref, {
      whatWeDo: props.whatWeDo,
      howWeDo: props.howWeDo,
    })

    alert('内容を保存しました')
  }

  const save = async () => {
    const ref = doc(db, 'activists', props.activistId)

    await updateDoc(ref, {
      whatWeDo: props.whatWeDo,
      howWeDo: props.howWeDo,
    })

  }


  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  const uploadPdf = async e => {

    props.setIsPdf(true)
    const file = e.target.files[0]

    const res = String(await toBase64(file))

    const pdfData = atob(res.split('base64,')[1])

    const loadingTask = pdfjs.getDocument({ data: pdfData })

    loadingTask.promise.then(pdf => {
      pdf.getPage(1).then(page => {
        // console.log("page", page);

        var scale = 1.5
        var viewport = page.getViewport({ scale: scale })

        var canvas:any = document.getElementById('canvas')
        var context = canvas?.getContext('2d')
        canvas.style.display = "none";

        canvas.height = viewport.height
        canvas.width = viewport.width


        var renderContext = {
          canvasContext: context,
          viewport: viewport,
        }
        var renderTask = page.render(renderContext)
        renderTask.promise.then(async function () {

          var data = canvas?.toDataURL('image/jpeg')

          const storageRef = getRef(`images/activists/${props.activistId}/annual/${props.selectedYear}.jpeg`)

          await uploadStringStorage(storageRef, data, 'data_url')

          const pdfRef = getRef(`images/activists/${props.activistId}/annual/${props.selectedYear}.pdf`)

          await uploadStringStorage(pdfRef, res, 'data_url')


          let ps = [...props.pdfs]



          if (ps.indexOf(props.selectedYear) == -1) {
            ps.push({ year: props.selectedYear, value: data })

            ps.sort(function (a, b) {
              if (a === b) { return 0; }
              return a < b ? -1 : 1;

            })

            const newArray = ps.sort((a, b) => ((a.year > b.year) ? -1 : 1));

            props.setPdfs(newArray)
          }


          const ref = doc(db, `activists`, props.activistId)

          await updateDoc(ref, {
            pdfYears: arrayUnion(String(props.selectedYear)),
          })

          props.setIsPdf(false)

        })
      })
    })
  }

  const deletePdf = async (v: string) => {
    let newPdfs = [...props.pdfs]

    // console.log('newPdfs', newPdfs)
    const storageImageRef = getRef(`images/activists/${props.activistId}/annual/${v}.jpeg`)
    // `images/activists/${props.activistId}/annual/${props.selectedYear}.pdf`
    await deleteStorage(storageImageRef)
    // let newPdfs = props.pdfs.concat()
    // props.setPdfs(newPdfs)

    const storagePdfRef = getRef(`images/activists/${props.activistId}/annual/${v}.pdf`)

    await deleteStorage(storagePdfRef)

    const ref = doc(db, `activists`, props.activistId)

    await updateDoc(ref, {
      pdfYears: arrayRemove(v),
    })

    for (let i = 0; i < newPdfs.length; i++) {
      const p = newPdfs[i]
      const year = p.year
      if (year == v) {
        newPdfs.splice(i, 1)
      }
    }
    // console.log('newPdfs', newPdfs)

    props.setPdfs(newPdfs)
  }

  const uploadFile = useCallback(async (type: "h" | "w", files, index: number) => {
    const file = files[0]
    let fileName = ''

    switch (index) {
      case 0:
        fileName = 'whatWeDo1'
        break
      case 1:
        fileName = 'whatWeDo2'
        break
      case 2:
        fileName = 'howWeDo1'
        break
      case 3:
        fileName = 'howWeDo2'
        break
    }

    const date = new Date()
    const timeStamp = date.getTime()
    const timeStampFileName = `${timeStamp}-${fileName}`

    try {
      const storageRef = getRef(`images/activists/${props.activistId}/${timeStampFileName}`)
      const compressFile = await getCompressImageFile(file)
      const result = await uploadStorage(storageRef, compressFile)
      const firebaseUrl = await getDownloadURL(result.ref)

      saveImage(type, index, firebaseUrl)
    } catch (e) {
      console.log('e', e)
    }
  }, [])


  const getCompressImageFile = async (file) => {
    const options = {
      maxSizeMB: 0.8, //最大ファイルサイズ
      maxWidthOrHeight: 600, //最大縦横値
    }
    return await imageCompression(file, options)
  }

  const saveImage = async (type: "h" | "w", index: number, firebaseUrl: string) => {
    const ref = doc(db, 'activists', props.activistId)

    let obj = props.doImages

    console.log("obj",obj)

    if(!obj){
      obj = {}
      obj["whatWeDoImages"] = []
      obj["howWeDoImages"] = []


    }


    if (type == "w" && index === 0) {
      obj["whatWeDoImages"][index] = firebaseUrl
      props.setWhatWeDoImage1(firebaseUrl)


    }

    if (type == "w" && index === 1) {

      obj["whatWeDoImages"][index] = firebaseUrl
      props.setWhatWeDoImage2(firebaseUrl)


    }

    if (type == "h" && index === 0) {
      obj["howWeDoImages"][index] = firebaseUrl
      props.setHowWeDoImage1(firebaseUrl)


    }

    if (type == "h" && index === 1) {

      obj["howWeDoImages"][index] = firebaseUrl
      props.setHowWeDoImage2(firebaseUrl)


    }






    await updateDoc(ref, {
      doImages: obj,
    })

    props.setDoImages(obj)




    // console.log("wWDI", wWDI)



    // await updateDoc(ref, {
    //   whatWeDoImages: wWDI,
    // })

    // await updateDoc(ref, {
    //   howWeDoImages: hWDI,
    // })

  }



  const selected: React.ChangeEventHandler<HTMLSelectElement> = ev => {
    console.log("ev.target.value", ev.target.value)
    props.setSelectedYear(ev.target.value)
  }



  return (
    <Formik
      initialValues={{
        whatWeDo: props.whatWeDo,
        howWeDo: props.howWeDo,
      }}
      enableReinitialize={true}
      validationSchema={validation()}
      onSubmit={values => saveBankInfo()}
    >
      {({ setFieldValue, handleChange, handleBlur, handleSubmit, values, errors }) => (

        <View style={basicStyles.myPageCard}>
          <View style={styles.cardTitleContainer}>
            <Text style={basicStyles.cardTitle}>団体紹介</Text>
            <View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View>
                  <View style={styles.imageContainer}>
                    <View>
                      <View style={basicStyles.sectionContainer}>
                        <Text style={basicStyles.sectionTitle}>What we do - 何をしているのか</Text>

                      </View>
                      <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <Hoverable>
                          {({ hovered }) => (
                            <View style={hovered ? basicStyles.imageOpacity : {}}>
                              {props.whatWeDoImage1 ? (
                                <Dropzone
                                  accept={['image/jpeg', 'image.jpg', 'image/png']}
                                  onDrop={acceptedFiles => uploadFile("w", acceptedFiles, 0)}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps()}
                                      style={{
                                        width: 250,
                                        height: 250,
                                        marginRight: 20,
                                      }}
                                    >
                                      <ImageBackground
                                        height={250}
                                        width={250}
                                        source={{
                                          uri: props.whatWeDoImage1,
                                        }}
                                        style={basicStyles.normalImage}
                                      ></ImageBackground>
                                      <input {...getInputProps()} />
                                    </div>
                                  )}
                                </Dropzone>
                              ) : (
                                <Dropzone
                                  accept={['image/jpeg', 'image.jpg', 'image/png']}
                                  onDrop={acceptedFiles => uploadFile("w", acceptedFiles, 0)}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps()}
                                      style={{
                                        width: 250,
                                        height: 250,
                                        backgroundColor: '#ddd',
                                        textAlign: 'center',
                                        marginRight: 20,
                                        lineHeight: "14px",
                                        display: "flex",
                                        alignItems: "center",


                                      }}
                                    >
                                      <View style={styles.emptyCotanier}>
                                        <Image source={images['iconCamera']} style={styles.logo} />
                                        <Text style={styles.uploadText}>
                                          アップロードしたい画像をここにドラッグ＆ドロップするか、クリックして画像を選択してください
                                        </Text>
                                      </View>


                                      <input {...getInputProps()} />
                                    </div>
                                  )}
                                </Dropzone>
                              )}
                            </View>
                          )}
                        </Hoverable>
                        <Hoverable>
                          {({ hovered }) => (
                            <View style={hovered ? basicStyles.imageOpacity : {}}>
                              {props.whatWeDoImage2 ? (
                                <Dropzone
                                  accept={['image/jpeg', 'image.jpg', 'image/png']}
                                  onDrop={acceptedFiles => uploadFile("w", acceptedFiles, 1)}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps()}
                                      style={{
                                        width: 250,
                                        height: 20,
                                      }}
                                    >
                                      <ImageBackground
                                        height={250}
                                        width={250}
                                        style={basicStyles.normalImage}
                                        source={{ uri: props.whatWeDoImage2 }}
                                      ></ImageBackground>
                                      <input {...getInputProps()} />
                                    </div>
                                  )}
                                </Dropzone>
                              ) : (
                                <Dropzone
                                  accept={['image/jpeg', 'image.jpg', 'image/png']}
                                  onDrop={acceptedFiles => uploadFile("w", acceptedFiles, 1)}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps()}
                                      style={{
                                        width: 250,
                                        height: 250,
                                        backgroundColor: '#ddd',
                                        textAlign: 'center',
                                        marginRight: 20,
                                        lineHeight: "14px",
                                        display: "flex",
                                        alignItems: "center",


                                      }}
                                    >
                                      <View style={styles.emptyCotanier}>
                                        <Image source={images['iconCamera']} style={styles.logo} />
                                        <Text style={styles.uploadText}>
                                          アップロードしたい画像をここにドラッグ＆ドロップするか、クリックして画像を選択してください
                                        </Text>
                                      </View>


                                      <input {...getInputProps()} />
                                    </div>
                                  )}
                                </Dropzone>
                              )}
                            </View>
                          )}
                        </Hoverable>
                      </View>
                    </View>
                  </View>
                  <TextInput
                    multiple={1}
                    value={props.whatWeDo}
                    type={"whatWeDo"}
                    error={errors.whatWeDo ? errors : ""}
                    // onChangeText={handleChange("whatWeDo")}


                    onChangeText={async (e: any) => {

                      props.setWhatWeDo(e)
                      setFieldValue("whatWeDo", e)


                    }}
                    onBlur={handleBlur('whatWeDo')}




                  />
                  <View>
                    <View>
                      <View>
                        <View style={basicStyles.sectionContainer}>
                          <Text style={basicStyles.sectionTitle}>How we do it - どうやってやっているか</Text>

                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <View>
                            <Hoverable>
                              {({ hovered }) => (
                                <View style={hovered ? basicStyles.imageOpacity : {}}>
                                  {props.howWeDoImage1 ? (
                                    <Dropzone
                                      accept={['image/jpeg', 'image.jpg', 'image/png']}
                                      onDrop={acceptedFiles => uploadFile("h", acceptedFiles, 0)}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div
                                          {...getRootProps()}
                                          style={{
                                            width: 250,
                                            height: 250,
                                            marginRight: 20,
                                          }}
                                        >
                                          <Image style={basicStyles.normalImage} height={50} width={50} source={{ uri: props.howWeDoImage1 }} />
                                          <input {...getInputProps()} />
                                        </div>
                                      )}
                                    </Dropzone>
                                  ) : (
                                    <Dropzone
                                      accept={['image/jpeg', 'image.jpg', 'image/png']}
                                      onDrop={acceptedFiles => uploadFile("h", acceptedFiles, 0)}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div
                                          {...getRootProps()}
                                          style={{
                                            width: 250,
                                            height: 250,
                                            backgroundColor: '#ddd',
                                            textAlign: 'center',
                                            marginRight: 20,
                                            lineHeight: "14px",
                                            display: "flex",
                                            alignItems: "center",


                                          }}
                                        >
                                          <View style={styles.emptyCotanier}>
                                            <Image source={images['iconCamera']} style={styles.logo} />
                                            <Text style={styles.uploadText}>
                                              アップロードしたい画像をここにドラッグ＆ドロップするか、クリックして画像を選択してください
                                            </Text>
                                          </View>


                                          <input {...getInputProps()} />
                                        </div>
                                      )}
                                    </Dropzone>
                                  )}
                                </View>
                              )}
                            </Hoverable>
                          </View>
                          <View>
                            <Hoverable>
                              {({ hovered }) => (
                                <View style={hovered ? basicStyles.imageOpacity : {}}>
                                  {props.howWeDoImage2 ? (
                                    <Dropzone
                                      accept={['image/jpeg', 'image.jpg', 'image/png']}
                                      onDrop={acceptedFiles => uploadFile("h", acceptedFiles, 1)}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div
                                          {...getRootProps()}
                                          style={{
                                            width: 250,
                                            height: 250,
                                            marginRight: 20,
                                          }}
                                        >
                                          <Image style={basicStyles.normalImage} height={50} width={50} source={{ uri: props.howWeDoImage2 }} />
                                          <input {...getInputProps()} />
                                        </div>
                                      )}
                                    </Dropzone>
                                  ) : (
                                    <Dropzone
                                      accept={['image/jpeg', 'image.jpg', 'image/png']}
                                      onDrop={acceptedFiles => uploadFile("h", acceptedFiles, 1)}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div
                                          {...getRootProps()}
                                          style={{
                                            width: 250,
                                            height: 250,
                                            backgroundColor: '#ddd',
                                            textAlign: 'center',
                                            marginRight: 20,
                                            lineHeight: "14px",
                                            display: "flex",
                                            alignItems: "center",


                                          }}
                                        >
                                          <View style={styles.emptyCotanier}>
                                            <Image source={images['iconCamera']} style={styles.logo} />
                                            <Text style={styles.uploadText}>
                                              アップロードしたい画像をここにドラッグ＆ドロップするか、クリックして画像を選択してください
                                            </Text>
                                          </View>


                                          <input {...getInputProps()} />
                                        </div>
                                      )}
                                    </Dropzone>
                                  )}
                                </View>
                              )}
                            </Hoverable>
                          </View>
                        </View>
                        <TextInput
                          multiple={1}
                          value={props.howWeDo}
                          type={"howWeDo"}
                          error={errors.howWeDo ? errors : ""}
                          // onChangeText={handleChange("howWeDo")}


                          onChangeText={async (e: any) => {

                            props.setHowWeDo(e)
                            setFieldValue("howWeDo", e)


                          }}
                          onBlur={handleBlur('howWeDo')}




                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View>
                  <View>
                    <View style={{ marginLeft: 110 }}>
                      <View>
                        <View>
                          <ActivistHeaderImage activistId={props.activistId} thumbnail={props.thumbnail} setThumbnail={props.setThumbnail} />

                          <View style={styles.pdfUploader}>
                            <Hoverable>
                              {({ hovered }) => (
                                <View>
                                  <Text style={basicStyles.sectionTitle}>年次報告用PDF</Text>
                                  <View style={basicStyles.pdfBtnContainer}>
                                    <View>
                                      <select
                                        name="pets"
                                        id="pet-select"
                                        onChange={selected}
                                        style={
                                          hovered
                                            ? {
                                              backgroundColor: 'white',
                                              borderWidth: 1,
                                              borderColor: '#40A9FF',
                                              height: 40,
                                              width: 130,
                                              appearance: 'none',
                                              marginRight: 55,
                                              backgroundImage:
                                                'url(https://firebasestorage.googleapis.com/v0/b/megu-prod.appspot.com/o/under_arrow.png?alt=media&token=1c839933-fee4-461f-83c3-1bb5ba09f822)',
                                              backgroundSize: 10,
                                              backgroundPosition: '110px',
                                              backgroundRepeat: 'no-repeat',
                                              paddingLeft: 10,
                                            }
                                            : {
                                              backgroundColor: 'white',
                                              borderWidth: 1,
                                              borderColor: '#ddd',
                                              height: 40,
                                              width: 130,
                                              appearance: 'none',
                                              marginRight: 55,
                                              backgroundImage:
                                                'url(https://firebasestorage.googleapis.com/v0/b/megu-prod.appspot.com/o/under_arrow.png?alt=media&token=1c839933-fee4-461f-83c3-1bb5ba09f822)',
                                              backgroundSize: 10,
                                              backgroundPosition: '110px',
                                              backgroundRepeat: 'no-repeat',
                                              paddingLeft: 10,
                                            }
                                        }
                                      >
                                        {props.years.map((value, i) => {
                                          return (
                                            <option
                                              key={i}
                                              value={value}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              {value}年度
                                            </option>
                                          )
                                        })}
                                      </select>
                                    </View>
                                    <View>
                                      {props.isPdf ? <View style={styles.loader}> <Loader /> </View> : <Hoverable>
                                        {({ hovered }) => (
                                          <View style={hovered ? styles.hovered : styles.notHovered}>
                                            <label>
                                              PDFをアップロード
                                              <input
                                                onChange={e => uploadPdf(e)}
                                                accept="application/pdf"
                                                type="file"
                                                id="file_upload"
                                                style={{ display: 'none' }}
                                              />
                                            </label>
                                          </View>
                                        )}
                                      </Hoverable>}

                                    </View>
                                  </View>



                                </View>
                              )}
                            </Hoverable>

                          </View>
                        </View>
                      </View>

                      <View>
                        {props.pdfs.map((pdf, i) => {
                          return (
                            <View key={i} style={styles.pdfContainer}>
                              <View>
                                <View style={styles.pdfContainer}>
                                  <Text style={basicStyles.yearText} type={'xlarge'}>
                                    {pdf.year}年度
                                  </Text>
                                  <View>
                                    <TouchableOpacity onPress={() => deletePdf(pdf.year)}>
                                      <DeleteText>削除</DeleteText>
                                    </TouchableOpacity>
                                  </View>
                                </View>
                                <Image style={basicStyles.thumbnail} resizeMode="contain" source={{ uri: pdf.value }} />
                              </View>
                            </View>
                          )
                        })}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Pressable style={basicStyles.saveBtn}>
            {({ hovered }) => (
              <Button buttonType={hovered ? 3 : 1} onPress={handleSubmit}>
                保存する
              </Button>
            )}
          </Pressable>
          <View>

            {props.isPdf ? <canvas id="canvas"></canvas>
              : <View />}


          </View>

        </View>

      )}
    </Formik>

  )
})

const DeleteText = styled.span`
  color: #acb6e5; //非対応のブラウザでの文字色を設定
  background: -webkit-linear-gradient(0deg, rgba(204, 0, 0, 1), rgba(176, 0, 32, 1)); //背景色にグラデーションを指定
  -webkit-background-clip: text; //テキストでくり抜く
  -webkit-text-fill-color: transparent; //くり抜いた部分は背景を表示

  &:hover {
    opacity: 0.5;
  }
`

const styles = StyleSheet.create({

  emptyCotanier: {
    margin: "auto",

  },
  container: {
    flexDirection: 'row',
    backgroundColor: '#F4F4F4',
    paddingBottom: height * 0.2,
  },
  saveBtn: {
    right: 0,
    position: 'absolute',
    bottom: 0,
  },
  bell: {
    height: width * 0.02,
    width: height * 0.03,
  },
  rightIcon: {
    flexDirection: 'row',
    marginEnd: width * 0.1,
  },
  mypageContent: {
    width: 1250,
    backgroundColor: 'white',
  },
  mypageItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  announceLogo: {
    width: width * 0.2,
  },
  headerItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerFourItems: {
    flexDirection: 'row',
  },
  selectedMenu: {
    backgroundColor: 'white',
    padding: 50,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notSelectedMenu: {
    padding: 50,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    position: 'absolute',
    left: -(width * 0.147),
    right: 0,
    top: 0,
    bottom: 0,
    height: 3,
    marginTop: 31,
    width: width / 10,
  },

  logout: {
    marginTop: height * 0.07,
  },
  backgroundImageContent: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  backgroundImage: {
    width: '110%',
    height: '100%',
  },
  logoContainer: {
    backgroundColor: '#DDDDDD',
    width: '30%',
    height: '30%',
  },
  logo: {
    width: 30,
    height: 30,
    margin: 'auto',
    marginBottom: 15,
  },
  deleteLogo: {
    width: 30,
    height: 15,
    fontSize: 12,
  },
  hovered: {
    borderColor: '#999999',
    width: 250,
    height: 50,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {

    width: 250,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',

  },
  notHovered: {
    borderColor: '#DDDDDD',
    width: 250,
    height: 50,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitleContainer: {
    // marginTop: 36,
    // marginBottom: 20,
  },
  uploadText: {
    color: '#666666',
    textAlign: 'center',
    width: 160,
    marginLeft: 'auto',
    marginRight: 'auto',
    lineHeight: "14px"
  },
  uploadContainer: {
    margin: 'auto',
    marginLeft: 40,
    width: 160,
  },
  optionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  pdfContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginTop: 26,
    justifyContent: 'space-between',
  },
  pdfUploader: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  underAnnounceLogo: {
    width: 270,
    color: '#999999',
  },
})
