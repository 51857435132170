import React, { useContext, useEffect } from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'

// contexts
import { LayoutContext, LayoutContextType } from 'app/src/contexts/layout'

// libs
import { goToTerms, goToPrivacy, goToCompany, goToContact, goToTransactions, goToGuideline } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { windowWidth } from 'app/src/constants/layout'
import { darkGray } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { LinearGradient } from 'expo-linear-gradient'



type Props = {}





export const Footer: React.FC<Props> = () => {


  const { isPc, width } = useContext<LayoutContextType>(LayoutContext)
  const navigation = useNavigation()

  useEffect(() => {








  }, [])


  return (

    <View

    >
      <LinearGradient
        // Button Linear Gradient
        colors={['rgba(244, 244, 244, 0.3)', '#E5E5E5']}
        // colors={['red', 'red']}
        style={styles.otherBackground}
      >
        <View
          style={styles.footerContent}

        >

          <View style={styles.leftContainer}>

            <TouchableOpacity onPress={() => goToTerms(navigation)}>
              <Text style={styles.leftText}>利用規約</Text>
            </TouchableOpacity>


            <TouchableOpacity onPress={() => goToPrivacy(navigation)}>
              <Text style={styles.leftText}>プライバシーポリシー</Text>

            </TouchableOpacity>



            <TouchableOpacity onPress={() => goToTransactions(navigation)}>
              <Text style={styles.leftText}>特定商取引法に基づく表記</Text>

            </TouchableOpacity>





            <TouchableOpacity onPress={() => goToCompany(navigation)}>
              <Text style={styles.leftText}>運営会社</Text>

            </TouchableOpacity>




            <TouchableOpacity onPress={() => goToContact(navigation)}>
              <Text style={styles.leftText}>お問い合わせ</Text>

            </TouchableOpacity>


            <TouchableOpacity onPress={() => goToGuideline(navigation)}>
              <Text style={styles.leftText}>よくある質問</Text>
            </TouchableOpacity>

          </View>
          <View>
            <Text style={styles.rightText}>
              Copyright by beero Inc. since 2020. All rights reserved.
            </Text>
          </View>




        </View>
      </LinearGradient>
    </View>
  )
}

const styles = StyleSheet.create({
  contentInner: {
    // width: 950,

    marginHorizontal: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    width: windowWidth,
  },
  leftText: {
    marginRight: 60
  },
  rightText: {
    // marginRight: 60,
    color: "#999999"
  },
  footerContent: {

    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: "100%"

  },
  leftContainer: {

    width: 150,
    display: "flex",
    flexDirection: 'row',
  },
  otherBackground: {

    paddingBottom: 10,
    paddingLeft: 180,
    paddingRight: 20,

    zIndex: 3,
    height: 44,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: 'row',
    // minWidth: 1440,
    minWidth: 1440,

    position: "fixed",
    bottom: 0,
    left: 0,

    // height: 500

  },
  links: {
    marginHorizontal: 'auto',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'row',

  },
  link: {
    marginBottom: 46,
    alignItems: 'center',
    width: windowWidth / 2,
  },
  linkText: {
    fontSize: 11,
    color: darkGray,
  },
  footerLogo: {
    marginTop: 13,
    marginBottom: 34,
    alignItems: 'center',
  },
  footerLogoImage: {
    width: 120,
    height: 50,
  },
  copyright: {
    textAlign: 'center',
    fontSize: 11,
    color: '#C4C4C4',
    marginBottom: 70,
  },
  line: {
    height: 4,
    width: windowWidth,
    backgroundColor: '#EBEBEB',
    marginBottom: 54,
  },
})
