

import React, { useState, useEffect } from 'react'

//libs
import { goToContact } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { isWeb } from 'app/src/constants/platform'
import { blue } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { ReportCard, styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { ActivistMenu } from '../../advanced/activist/menu'
import { windowWidth, windowHeight } from 'app/src/constants/layout'
import { images } from 'app/src/libs/images'
import { StyleSheet, View, ScrollView, Image, TouchableOpacity } from 'react-native'
import parse from "html-react-parser"




type ContainerProps = {}

type Props = {
    subject: string
    name: string
    email: string
    message: string
    setSubject: (subject: string) => void
    setName: (name: string) => void
    setEmail: (email: string) => void
    setMessage: (message: string) => void
    submitContact: () => void
}

export const GuideLineScreen: React.FC<ContainerProps> = () => {


    const [answer, setAnswer] = useState<number>(0)
    const [currentTexxtNum, setCurrentTextNum] = useState<number>(0)
    const [texts, setTexts] = useState<any[]>([])
    // <a style="color:#0470B9" href=""></a>

    const totalTexts: any[] = [
        {
            q: 'アカウント情報を更新したいのですがどうしたらいいですか？',
            a: 'こちらの「<a style="color:#0470B9;" href="/mypage">マイページ</a>」でアカウント情報を変更してください。'
        },

        {
            q: '仮登録メールが届きません。',
            a: 'メールアドレスが間違っていないか、受信設定に問題がないか等をご確認ください。'
        },
        {
            q: 'どうすればパスワードを変更できますか？',
            a: 'パスワードを変更されたい場合は「<a style="color:#0470B9;" href="/mypage">マイページ</a>」の「アカウント設定」でメールアドレスを入力した後、「パスワード変更メールを送る」を押してください。そうすると再設定メールが届きますのでそちらでパスワードを再設定してください。'
        },
        {
            q: 'メールでログインできません。',
            a: `メールでログインできない理由としては以下が考えられます。
            1新規会員登録を行なっていないかもしれません。会員登録をお済みでない方はこちらの画面より会員登録を行なってください。
            2メールアドレスまたはパスワードを間違っているかもしれません。パスワードがわからなくなってしまった場合はこちらから再設定ができます。`
        },
        {
            q: 'stripeのアカウントはどこで管理できますか？',
            a: 'stripeのアカウントの管理はstripeのページで行うことができます。stripeへは「<a style="color:#0470B9;" href="/mypage">マイページ</a>」の「支援金送金先」を押すと「stripeに行く」というボタンがあります。そちらを押すとstripeに行くことができます。'
        },
        {
            q: '支援金はどのようにしたら受け取ることができますか？',
            a: '他社サイトで発生した支援は銀行振込となります。一方で、meguのサービスサイト内で発生した支援金はstripeというサービスを通じての送金となります。したがって、予め銀行の口座情報のご入力及びにstripeのアカウントの作成をお願いします。'
        },
        {
            q: '支援金の受け取りはどのタイミングで行うことができますか？',
            a: '累積支援金が１万円を越えると、受け取り申請が可能になります。'
        },
        {
            q: '累積支援金が１万円を越えた場合、どこで受け取り申請を行えばいいですか？',
            a: '累積支援金が１万円を越えた場合、「<a style="color:#0470B9;" href="/donations">支援一覧</a>」ページの「出金を依頼する」というボタンを押してください。そうすると「出金依頼」ページに移ります。そこで、「出金を依頼する」ボタンを押してください。なお、出金に際しては口座情報のご入力及びにstripeアカウントの作成が必要となります。'
        },
        {
            q: '支援金はどの銀行口座で受け取ることができますか？',
            a: '日本の銀行口座であればいずれでも受け取ることができます。'
        },
        {
            q: 'Thank you messageとはなんですか？',
            a: '毎月御団体に支援を行なった方に送られる各団体からのお礼状のことを言います。団体様には毎月20日までにその月に御団体に支援された方へ送る用のThank You Messageと活動報告をご入稿していただく必要があります。'
        },
        {
            q: 'Thank you messageはいつ、どうやって送れらますか？',
            a: 'Thank you messageは毎月25日に自動で送信されます。'
        },
        {
            q: '活動報告はどのぐらいの頻度で書く必要がありますか？',
            a: '活動報告については最低でも月に１本ご入稿していただく必要があります。'
        },
        {
            q: '活動報告はどこで作成することができますか？',
            a: '左側のメニューバーの「活動報告」を押していただくと、「<a style="color:#0470B9;" href="/report">活動報告</a>」ページに遷移します。そうすると「新規作成」というボタンがありますので、そちらを押してください。そうすると、「活動報告の新規作成」というページになるので、そちらで活動報告を作成することができます。'
        },
        {
            q: '活動報告の下書きを作成したいのですが、どうすれば下書きを作成できますか？',
            a: '下書きを作成するためには「活動報告の新規作成」ページで「戻る」ボタンを押してください。そうすると、それまでに記入していただいた活動報告が下書きとして保存されます。'
        },
        {
            q: '編集した下書きを公開したいのですがどうしたらいいですか？',
            a: '下書きを公開する場合は、「公開する」をボタンを押してください。そうすると、活動報告が公開されます。また、「Thank you messageとして投稿する」のチェックボックスにチェックを入れるとThank You Messageの確認画面に遷移します。'
        },
        {
            q: '活動報告を編集したいのですがどうしたらいいですか？',
            a: '活動報告を編集したい場合は、活動報告の一覧ページで編集したい活動報告を選択してください。そうすると編集ページに移動しますのでそちらで編集してください。'
        },
        {
            q: '活動報告を削除したい場合はどうしたらいいいですか？',
            a: '活動報告を削除したい場合は削除したい活動報告を選択した後、「削除する」ボタンを押してください。'
        },
        {
            q: '支援していただいた方の情報はどこで確認することができるでしょうか？',
            a: '「<a style="color:#0470B9;" href="/donations">支援一覧</a>」ページの詳細を見るを押すと支援者の情報が確認できます。'
        },
        {
            q: '支援者に発行した領収書はどこで確認することができますか？',
            a: '支援者が領収書をダウンロードしている場合、「<a style="color:#0470B9;" href="/donations">支援一覧</a>」ページの支援者一覧のところに「発行済みの領収書を見る」というボタンが現れます。そうすると、発行された領収書を確認できます。'
        },
        {
            q: '支援者に返礼品を送りたいのですがどうしたらいいですか？',
            a: '弊社サービスにおいては返礼品の受け渡しサービスは対応していません'
        },
        {
            q: '「マイページ」の「団体紹介」のところのPDFには何をアップロードすればいいですか？',
            a: 'PDFは毎年の年次報告をアップロードしてください。'
        },
        {
            q: 'PDFは何年分アップロードできますか？',
            a: 'PDFをアップロードする際はその日から遡って５年分をアップロードすることができます。'
        }
    ]


    useEffect(() => {


        setTexts(totalTexts)
    }, [])



    const onChangeAnswer = (v: number) => {



        setAnswer(v)



    }

    const addItems = (v: number) => {

        if (totalTextsNum < v + texts.length) {

            let newTexts: any[] = []

            for (let i = 0; i < totalTexts.length; i++) {


                newTexts.push({
                    q: totalTexts[i].q,
                    a: totalTexts[i].a
                })

            }

            setTexts(newTexts)
            setCurrentTextNum(totalTexts.length)


        } else {


            let newTexts = [...texts]
            for (let i = 0; i < v; i++) {


                newTexts.push({
                    q: totalTexts[i].q,
                    a: totalTexts[i].a
                })

            }


            setTexts(newTexts)





        }



    }

    const totalTextsNum = totalTexts.length


    let width = ""
    let lineIconWidth: number | string = ""

    if (windowWidth <= 700) {

        width = "90%"

        lineIconWidth = "90%"






    } else {

        width = "50%"
        lineIconWidth = 355
    }

    return (

        <>



            <View >
                <View style={basicStyles.container}>
                    <View style={basicStyles.bodyContainer}>
                        <View>
                            <ActivistMenu />
                        </View>

                        <ScrollView style={styles.scrollContainer}>
                            <View style={styles.headerContent}>
                                <Text type={'xxlarge'} align={'center'}>
                                    よくある質問
                                </Text>
                            </View>


                            <View>

                                {texts.map((text, index) => (
                                    <View>

                                        <View style={styles.QAContinerStyle}>


                                            <TouchableOpacity onPress={() => onChangeAnswer(index + 1)} style={styles.QCardStyle}>
                                                <Text type='large' >{text.q}</Text>
                                            </TouchableOpacity>


                                        </View>

                                        {answer == index + 1 ? <View style={styles.QAContinerStyle}>
                                            <View style={{ paddingLeft: 20 }}>


                                                <Text type='normal'>
                                                    {parse(text.a)}


                                                </Text>


                                            </View>


                                        </View> : <span />}





                                    </View>


                                ))}

                                {texts.length == totalTextsNum ? <span /> : <View style={styles.moreButtonContainer}>

                                    <TouchableOpacity onPress={() => addItems(10)}>
                                        <Text style={styles.moreText}>もっと見る</Text>
                                    </TouchableOpacity>
                                </View>
                                }
                            </View>
                        </ScrollView>




                    </View>
                </View>

            </View>
        </>
    )
}




const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 48,
        flexDirection: 'column',


    },
    scrollContainer: {
        flex: 1,
        minWidth: windowWidth - 180,
        zIndex: -100,
        minHeight: "100vh",
        marginBottom: 50


    },
    headerContent: {
        marginTop: 20,
        marginBottom: 34,
        margin: 40

    },
    mainContent: {
        marginHorizontal: 28,
        fontSize: 14,
    },
    content: {
        flexDirection: 'row',
        paddingBottom: 25,
        display: "flex",

        justifyContent: "space-between"
    },
    title: {
        fontSize: 11,
        width: windowWidth / 2 - 135,
        textAlign: 'right',
        marginRight: 20,
    },
    text: {
        fontSize: 11,
        flex: 1,

        width: windowWidth / 2 - 90,

        textAlign: "left",
        // backgroundColor: "red"
        paddingRight: 30



    },
    linkText: {
        fontSize: 11,
        color: blue,
        borderBottomWidth: 1,
        borderBottomColor: blue,
    },
    textContent: {

        maxWidth: windowWidth - 200,
        paddingRight: 30

    },
    beeroLogo: {
        marginBottom: 24,
        width: 189,
        height: 65,
        margin: "auto"

    },
    headerImage: {
        width: windowWidth - 180,
        textAlign: "center",


    },
    QAContinerStyle: {
        marginBottom: 15,
        marginLeft: 20,

    },

    QCardStyle: {
        backgroundColor: "#FFFFFF",

        height: "100%",
        paddingTop: 31,
        paddingBottom: 33,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 1220


    },
    answerStyle: {
        marginTop: 15,
        marginBottom: 30,
        paddingLeft: 15,
        paddingRight: 15
    },
    moreButtonContainer: {
        paddingTop: 34

    },
    moreText: {

        textAlign: 'center',
        color: '#999999',
        marginBottom: 20,
    }
})

