import React, { useLayoutEffect, useRef } from 'react'
import { StyleSheet, View, Image, Dimensions } from 'react-native'
import styled from 'styled-components'

import { goToDonations, goToMypage, goToReportScreen } from 'app/src/libs/screen-transition'
import { useNavigation } from 'app/src/hooks/use-navigation'

import { images } from 'app/src/libs/images'

import { Text } from 'app/src/components/basics/text'
import { Footer } from '../footer'
import { Hoverable } from 'react-native-web-hover'


type ContainerProps = {
  pageTitle?: string
}

const { width, height } = Dimensions.get('window')

export const ActivistMenu = (props: ContainerProps) => {
  const navigation = useNavigation()

  const Wrapper = styled.div`
    width: 160px;
    min-height: 100vh;
    height:100%;
    position: relative;
    top: 0; // 上部 0px の位置に固定
    z-index:100;
    background-color:white
  `

  return (
    <Wrapper>
      <View style={styles.mebuBar}>
        <View >
          <Image source={images['logoBig']} style={styles.logo} resizeMode="contain" />
          <View style={styles.listContainer}>
            {props.pageTitle === 'donations' ? (
              <Text type={'xxlarge'} style={styles.activeMenuItem} onPress={() => goToDonations(navigation)}>
                支援一覧
              </Text>
            ) : (


              <Hoverable>
                {({ hovered }) => (

                  <View>


                    {hovered ? <Text type={'xxlarge'} style={styles.activeMenuItem} onPress={() => goToDonations(navigation)}>
                      支援一覧
                    </Text> : <Text type={'xxlarge'} style={styles.menuItem} onPress={() => goToDonations(navigation)}>
                      支援一覧
                    </Text>}
                  </View>

                )}
              </Hoverable>

            )}



            {props.pageTitle === 'report' ? (
              <Text style={styles.activeMenuItem} type={'xxlarge'} onPress={() => goToReportScreen(navigation)}>
                活動報告
              </Text>
            ) : (

              <Hoverable>
                {({ hovered }) => (

                  <View>
                    {hovered ? <Text style={styles.activeMenuItem} type={'xxlarge'} onPress={() => goToReportScreen(navigation)}>
                      活動報告
                    </Text> :
                      <Text style={styles.menuItem} type={'xxlarge'} onPress={() => goToReportScreen(navigation)}>
                        活動報告
                      </Text>}
                  </View>



                )}
              </Hoverable>


            )}

            {props.pageTitle === 'mypage' ? (
              <Text style={styles.activeMenuItem} type={'xxlarge'} onPress={() => goToMypage(navigation)}>
                マイページ
              </Text>
            ) : (


              <Hoverable>
                {({ hovered }) => (

                  <View>
                    {hovered ? <Text style={styles.activeMenuItem} type={'xxlarge'} onPress={() => goToMypage(navigation)}>
                      マイページ
                    </Text> : <Text style={styles.menuItem} type={'xxlarge'} onPress={() => goToMypage(navigation)}>
                      マイページ
                    </Text>}
                  </View>



                )}
              </Hoverable>


            )}

          </View>
        </View>
      </View>
      <Footer />

    </Wrapper >
  )
}

const styles = StyleSheet.create({
  menu: {
    width: 160,
    paddingTop: 75,
    flex: 1,
    alignItems: 'center',
    marginRight: 20,
    backgroundColor: 'white',
    zIndex: 12
  },
  mebuBar: { position: "relative", zIndex: 10, height: "100%", backgroundColor: "white" },
  logo: {
    height: 68,
    width: 50,
    marginTop: 75,
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto',
    objectFit: 'contain',
  },
  listContainer: {
    marginTop: 50,
  },
  menuItem: {
    marginBottom: 10,
    textAlign: 'center',
    width: 160,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: 14,
    color: '#000000',
    paddingTop: 14,
    paddingBottom: 14,
  },
  activeMenuItem: {
    marginBottom: 10,
    textAlign: 'center',
    backgroundColor: '#F4F4F4',
    width: 160,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: 14,
    color: '#000000',
    paddingTop: 14,
    paddingBottom: 14,
  },
})
