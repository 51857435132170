import React, { useCallback } from 'react'
import { StyleSheet, Dimensions, View, ImageBackground, Image } from 'react-native'
import { useDropzone } from 'react-dropzone'
import { Text } from 'app/src/components/basics'
import { images } from 'app/src/libs/images'
import styled from 'styled-components'
import { getDownloadURL, getStorage, uploadBytes } from 'firebase/storage'
import { getCompressImageFile, getDownload, getRef, uploadStorage } from 'app/src/libs/firebase'
import { styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { windowWidth } from 'app/src/constants/layout'


const { width, height } = Dimensions.get('window')

type Props = {
  value?: string
  activistId?: string
  setValue?: (v: string) => void
  hover?: boolean
  error: any
}

export const ThankYouImageFunc = (props: Props) => {
  const onDrop = useCallback(async files => {
    const date = new Date()
    const fileName = `${date.getTime()}` + 'thankYouMessage'
    const compressFile = await getCompressImageFile(files[0])
    const storageRef = getRef(`images/activists/${props.activistId}/thankYouMessage/${fileName}`)
    const uploadTask = await uploadStorage(storageRef, compressFile)
    const url = await getDownload(uploadTask.ref)
    props.setValue?.(url)







  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg,image.jpg, image/png' })

  return (
    <View>
      {(() => {
        if (props.value) {
          return (
            <View>
              <div {...getRootProps()} style={thankYouMessageImageContainer(props.value, props.hover)}>
                <input {...getInputProps()} />

                <Image source={{ uri: props.value }} style={basicStyles.thankYouMessageImage} />
              </div>


              <Text style={styles.noImageText}></Text>
            </View>
          )
        } else {
          return (
            <View>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div style={normalThankYouMessageImageContainer(props.hover)}>
                  <View style={innerImageContainer()}>
                    <View style={styles.emptyCotanier}>
                      <ImageBackground source={images['iconCamera']} style={styles.image} />
                      <Text style={styles.imageText}>活動報告用の画像をアップロードしてください</Text>
                    </View>
                  </View>

                </div>
              </div>
            </View>
          )
        }
      })()}
      <View style={basicStyles.thankYouMessageImageAnnotation}>
        {props.error}

        <Text type="annotation">※活動報告用の画像をアップロードしてください </Text>
        <Text type="annotation">（推奨：JPEG、PNG、250px x 250px 以上）</Text>
      </View>
    </View>
  )
}

export const thankYouMessageImageContainer = (value: string, hover?: boolean) => {
  if (hover) {
    return {
      width: 416,
      height: 234,
      opacity: 0.5,
      cursor: 'pointer',


    }
  } else {
    return {
      width: 416,
      height: 234,
      cursor: 'pointer',

    }
  }
}

export const innerImageContainer = () => {
  return {
    width: 160,
    fontSize: 12,
    color: '#666666',
    margin: 'auto',
    display: "flex",
    alignItems: "center",
    height: "100%"

  }
}

export const normalThankYouMessageImageContainer = (hover?: boolean) => {
  if (hover) {
    return {
      cursor: 'pointer',
      width: '416px',
      height: '234px',
      backgroundColor: '#DDDDDD',
      alignItems: 'center',
      opacity: 0.5,
    }
  } else {
    return {
      cursor: 'pointer',
      width: '416px',
      height: '234px',
      backgroundColor: '#DDDDDD',
      alignItems: 'center',
    }
  }
}

export const styles = StyleSheet.create({
  emptyCotanier: {
    margin: "auto",

  },
  normalThankYouMessageImageContainer: {
    cursor: 'pointer',
    width: 416,
    height: 234,
    backgroundColor: '#DDDDDD',
    alignItems: 'center',
  },
  amountText: {
    fontWeight: 'bold',
    fontSize: 24,
    marginLeft: 12,
    marginRight: 12,
  },
  selectedMenu: {
    width: 136,
    height: 52,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  reportCardText: { maxWidth: 320, marginBottom: 20 },
  thumbnail: {
    height: 110,
  },
  myPageHovered: {
    color: 'white',
    fontWeight: 'bold',
  },
  myPageNotHovered: {
    color: '#999999',
    fontWeight: 'bold',
  },
  textUnderLine: {
    color: '#999999',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  normalText: {
    color: '#999999',
    fontWeight: 'bold',
  },
  basicInfoPageCard: {
    minWidth: 1220,
    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: 'white',
    marginBottom: 100,
    height: "90%"
  },
  myPageCard: {
    minWidth: 1220,

    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: 'white',
    paddingTop: 36,
    paddingBottom: 100,
    position:"relative"
  },

  loadCard: {
    minHeight: 400,
    minWidth: 1220,
    backgroundColor: "white",
    height: "100%"
  },

  image: {
    width: 50,
    height: 50,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 15,
  },
  container: {
    flexDirection: 'row',
    flex: 1,
    zIndex: 5
  },

  scrollContainer: {
    flex: 1,
    minWidth: windowWidth - 180,
    zIndex: -100,
    marginBottom: 20
  },
  bodyContainer: {
    flexDirection: 'row',
    paddingRight: 20,
  },


  card: {
    backgroundColor: 'white',
    paddingTop: 36,
    paddingLeft: 32,
    paddingRight: 50,
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  cardBody: {
    flexDirection: 'row',
  },

  thankYouMessageTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',

  },

  explanation: {
    marginTop: 14,
    fontSize: 12,
    lineHeight: 14,

  },

  skipConfirm: {
    color: "#0470B9",
    fontSize: 16

  },

  sectionTitle: {

    fontSize: 14,
    marginRight: 5,
    marginBottom: 6

  },
  pdfBtnContainer: {

    display: "flex",
    flexDirection: 'row',
    alignItems: "center"

  },
  sectionContainer: {
    marginTop: 20,
    maxWidth: 320,

  },
  titleTextField: {
    borderWidth: 1,
    height: 40,
    borderColor: '#DDDDDD',
  },
  outlineTextField: {
    height: 160,
    borderWidth: 1,
    borderColor: '#DDDDDD',
  },
  thankYouMessageTextArea: {
    height: 160,
    borderWidth: 1,
    borderColor: '#DDDDDD',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 6,
    minWidth: 560
  },
  saveThankYouMessageButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  authorTextField: {
    width: '50%',
    borderWidth: 1,
    height: 40,
    borderColor: '#DDDDDD',
  },
  saveAuthorContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageContainer: {
    paddingTop: 20,
    marginLeft: 50,
  },

  checkbox: {
    alignSelf: 'center',
    marginRight: 11,
  },
  hoveredCheckbox: {
    alignSelf: 'center',
    marginRight: 11,
    borderColor: '#40A9FF',
    boxSizing: 'border-box',
  },
  notHoveredCheckbox: {
    alignSelf: 'center',
    marginRight: 11,
    borderColor: 'red',
    boxSizing: 'border-box',
  },
  btnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 34,
    marginBottom: 38,
  },
  bodyTextArea: {
    height: 160,
    borderWidth: 1,
    borderColor: '#DDDDDD',
  },
  saveAuthorBtnContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,

  },

  cardContainer: {
    marginLeft: 20,
    marginBottom: 50,
    minWidth: 1240,
  },
  imageText: {
    color: '#999',
    width: 160,
    fontSize: 12,
    lineHeight: "14px",

  },
  noImageText: {
    color: '#999',
    width: 240,
    fontSize: 12,
  },
  imageOpacity: {
    opacity: 0.5,

  },
  normalImage: {
    width: 240,
    height: 240,
  },
  coverImage: {
    width: 370,
    height: 260,
  },
  annotationContainer: {

    marginTop: 9,

    textAlign: "center"
  },

  thankYouMessageImage: {
    width: 416,
    height: 234,
    backgroundSize: "cover"

  },
  thankYouMessageImageAnnotation: {
    marginTop: 17,
    width: 416,
    textAlign: "left"
  },
  emptyImage: {
    width: 240,
    height: 240,
    backgroundColor: '#ddd',
    textAlign: 'center',
    marginRight: 20,
  },
  introductionButton: {
    marginLeft: 'auto',
    marginBottom: 20,
  },
  saveBtn: {
    marginLeft: 'auto',
    marginBottom: 20,
  },
  deleteBtnContainer: {
    // marginTop: 30,
  },
  deleteText: {
    color: 'rgba(204, 0, 0, 1)',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  yearText: {
    paddingRight: 50,
  },
  notKakuinContainer: {
    width: 240,
    height: 240,
    backgroundColor: '#DDDDDD',
  },

  outerImageText: {
    color: '#666666',
    width: 260,
    fontSize: 12,
    marginTop: 15,
  },
  rightBottomButtonContainer: {
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  submitButton: {
    marginLeft: 40,
  },
  cardThumbnail: {
    width: 320,
    height: 180,
    marginBottom: 20,
  },
  passwordContainer: {
    marginLeft: 'auto',
    marginRight: "auto",
    paddingTop: 15,

  },


  passwordSaveBtn: {
    marginTop: 20
  },
  saveBtnContainer: {
    marginBottom: 20,
    margin: "auto",
    marginTop: 20
  },
  goToActivistSignup: {
    color: '#0470B9',
    textAlign: 'right',
    marginTop: 33,
    marginRight: 40,
  },
  login: {

    fontSize: 12,
    color: "#0470B9",
    textAlign: 'center',
  },
  entireContainer: {
    backgroundColor: 'white',
    width: 400,
    margin: 'auto',
    marginBottom: 20
  },
})

export const FlexBox = styled.div`
  display: inline-block;

  flex-wrap: wrap;
  background-color: red;
`

export const Box = styled.div`
  width: 320px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  text-align: center;
  padding: 10% 0;
  cursor: pointer;
  background-color: white;

  &:after {
    display: block;
    content: '';
    width: 320px;
  }
`

export const ReportCard = styled.div`
  width: 320px;
  cursor: pointer;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;

  background-color: white;

  vertical-align: top;
`

export const Loader = styled.div`
display:flex;
justify-content:center;
margin:auto;
border: 2px solid #f3f3f3;
border-top: 2px solid #0470B9;
border-radius: 50%;
width: 20px;
height: 20px;


animation: spin 0.9s linear infinite;
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

`


