import React, { useEffect, useState } from 'react'
import {
  StyleSheet,
  View,
  Dimensions,

  TextInput as RNTextInput,
} from 'react-native'
import { goToBasicInfo, goToHome, goToReportScreen, goToSignupFinish } from 'app/src/libs/screen-transition'
import { useNavigation } from 'app/src/hooks/use-navigation'
import { fetchActivistInfo, fetchReport, isPreSendingResult } from 'app/src/libs/firebase'
import { ActivistMenu } from 'app/src/components/advanced/activist/menu'
import { ActivistHeaderMenu } from 'app/src/components/advanced/activist/headerMenu'
import { styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { ReportContainer } from 'app/src/components/advanced/activist/report-container'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from 'app/src/libs/firebase'
import { ThankYouContainer } from 'app/src/components/advanced/activist/thankYouMessage'
type ContainerProps = {}

const { width, height } = Dimensions.get('window')

export const ReportEditScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()

  const [thankYouMessageImage, setThankYouMessageImage] = useState<string>('')
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [outline, setOutline] = useState<string>('')
  const [thankYouMessage, setThankYouMessage] = useState<string>(`この度は当NPO法人に、ご支援金を賜りまして誠に有難うございました。{username}様の温かいご支援に、あらためて心より厚く御礼申し上げます。
  当団体の△△の事業において益々前進できますよう、お志を使わせていただく所存でございます。
  今後とも当財団の事業へのお力添えをどうぞよろしくお願いいたします。`)
  const [author, setAuthor] = useState<string>('')
  const [isAuthor, setIsAuthor] = useState<boolean>(false)
  const [isThankYouMessage, setIsThankYouMessage] = useState<boolean>(false)

  const [activistId, setActivistId] = useState<string>('')
  const [activistInfo, setActivistInfo] = useState<any>()
  const [reportId, setReportId] = useState<string>('')
  const [imageUrl, setImageUrl] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPreSending, setIsPreSending] = useState<boolean>(false)
  const [isDraft, setIsDraft] = useState<boolean>(true)
  const [isPostThankYouMessage, setIsPostThankYouMessage] = useState<boolean>(false)
  const [label, setLabel] = useState<string>("edit")
  const [isSent, setIsSent] = useState<boolean>(false)



  async function _fetchActivistInfo(activistId: string) {
    const activistInfo = await fetchActivistInfo(activistId)
    if (activistInfo) {
      if (activistInfo?.isSetUp) {
        const result = await isPreSendingResult(activistId)
        setIsPreSending(result)

        setActivistInfo(activistInfo)
        setImageUrl(activistInfo.imageUrl)
        if (activistInfo.thankYouMessage) {
          setThankYouMessage(activistInfo.thankYouMessage)
          setIsThankYouMessage(true)

        }
        if (activistInfo.author) {
          setAuthor(activistInfo.author)
          setIsAuthor(true)

        }
      } else {
        goToBasicInfo(navigation)
      }
    }
  }

  async function _fetchReport(activistId: string, reportId: string) {
    const data = await fetchReport(activistId, reportId)


    setTitle(data?.title)
    setBody(data?.body)
    setOutline(data?.outline)
    setThankYouMessage(data?.thankYouMessage)
    setAuthor(data?.author)
    setThankYouMessageImage(data?.thankYouMessageImage)
    setIsDraft(data?.isDraft)
    setIsSent(data?.isSent)
  }

  useEffect(() => {
    const setup = async () => {
      await onAuthStateChanged(auth, async activist => {
        if (activist) {
            await _fetchActivistInfo(activist.uid)
            const reportId = localStorage.getItem('reportId')
            const selectedActivistId = localStorage.getItem("selectedActivistId")
            const id = selectedActivistId?selectedActivistId:activist.uid
            if (reportId) {
              await _fetchReport(id, reportId)

              setReportId(reportId)

              setActivistId(id)
              setIsLoading(false)
            } else {
              goToReportScreen(navigation)
            }

        } else {
          goToHome(navigation)
        }
      })
    }
    setup()
  }, [])

  return (
    <View>
      <View>
        <View style={basicStyles.container}>
          <View style={basicStyles.bodyContainer}>
            <ActivistMenu />
            <View style={basicStyles.scrollContainer}>
              <ActivistHeaderMenu activistId={activistId} imageUrl={imageUrl} title={'活動報告'} />
              {label != "thank" ? <ReportContainer
                label={label}
                title={title}
                setTitle={setTitle}
                body={body}
                setBody={setBody}
                thankYouMessageImage={thankYouMessageImage}
                activistId={activistId}
                setThankYouMessageImage={setThankYouMessageImage}
                isDraft={false}
                setLabel={setLabel}
                reportId={reportId}
                setReportId={setReportId}
                isPostThankYouMessage={isPostThankYouMessage}
                setIsPostThankYouMessage={setIsPostThankYouMessage}
                isSent={isSent}
              /> : <ThankYouContainer
                label={label}
                thankYouMessage={thankYouMessage}
                setThankYouMessage={setThankYouMessage}
                isThankYouMessage={isThankYouMessage}
                setIsThankYouMessage={setIsThankYouMessage}
                author={author}
                setAuthor={setAuthor}
                isAuthor={isAuthor}
                setIsAuthor={setIsAuthor}
                activistId={activistId}
                isPreSending={isPreSending}
                reportId={reportId}
                isDraft={false}
                setLabel={setLabel}
                isPostThankYouMessage={isPostThankYouMessage}
                setIsPostThankYouMessage={setIsPostThankYouMessage}
                title={title}
                body={body}

              />}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  checkbox: {
    alignSelf: 'center',
    marginRight: 11,
  },
  hovered: {
    borderColor: '#999999',
    width: 180,
    height: 30,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
  },
  notHovered: {
    borderColor: '#DDDDDD',
    width: 180,
    height: 30,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    width: width * 0.77,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: 40,
    marginBottom: 35,
  },
  card: {
    backgroundColor: 'white',
    width: width * 0.85,

    height: 1000,
  },
  cardBody: {
    flexDirection: 'row',
  },
  titleTextField: {
    borderWidth: 1,
    height: 40,
    marginLeft: 34,
    borderColor: '#DDDDDD',
  },

  forSupporter: {
    fontSize: 11,
    fontWeight: 'bold',
    textAlign: 'center',
  },

  image: {
    width: 50,
    height: 50,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  imageText: {
    color: '#999',
    width: 250,
    fontSize: 12,
  },
})
