import React, { useEffect, useState, useCallback } from 'react'
import { StyleSheet, View, Dimensions } from 'react-native'

import { goToBasicInfo, goToHome, goToSignupFinish } from 'app/src/libs/screen-transition'
import { useNavigation } from 'app/src/hooks/use-navigation'
import {  fetchActivistInfo, isPreSendingResult } from 'app/src/libs/firebase'
import { ActivistMenu } from 'app/src/components/advanced/activist/menu'
import { ActivistHeaderMenu } from 'app/src/components/advanced/activist/headerMenu'
import { styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { ReportContainer } from 'app/src/components/advanced/activist/report-container'
import { onAuthStateChanged } from 'firebase/auth'
import { getRef, auth, uploadStorage, getDownload } from 'app/src/libs/firebase'
import { getDownloadURL } from 'firebase/storage'
import { ThankYouContainer } from 'app/src/components/advanced/activist/thankYouMessage'

type ContainerProps = {}

const { width, height } = Dimensions.get('window')

export const ReportCreateScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()

  const [thankYouMessageImage, setThankYouMessageImage] = useState<string>('')
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [thankYouMessage, setThankYouMessage] = useState<string>(`この度は当NPO法人に、ご支援金を賜りまして誠に有難うございました。{username}様の温かいご支援に、あらためて心より厚く御礼申し上げます。
  当団体の△△の事業において益々前進できますよう、お志を使わせていただく所存でございます。
  今後とも当財団の事業へのお力添えをどうぞよろしくお願いいたします。`)
  const [author, setAuthor] = useState<string>('')
  const [isAuthor, setIsAuthor] = useState<boolean>(false)
  const [isThankYouMessage, setIsThankYouMessage] = useState<boolean>(false)

  const [activistId, setActivistId] = useState<string>('')
  const [activistInfo, setActivistInfo] = useState<any>()
  const [imageUrl, setImageUrl] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPreSending, setIsPreSending] = useState<boolean>(false)
  const [isPostThankYouMessage, setIsPostThankYouMessage] = useState<boolean>(false)
  const [label, setLabel] = useState<string>("create")
  const [reportId, setReportId] = useState<string>("")
  const [isSent, setIsSent] = useState<boolean>(false)

  async function _fetchActivistInfo(activistId: string) {
    const activistInfo = await fetchActivistInfo(activistId)
    setActivistInfo(activistInfo)
    if (activistInfo) {
      if (activistInfo?.isSetUp) {
        const result = await isPreSendingResult(activistId)
        setIsPreSending(result)
        setActivistInfo(activistInfo)
        setImageUrl(activistInfo.imageUrl)
        if (activistInfo.thankYouMessage) {

          setThankYouMessage(activistInfo.thankYouMessage)
          setIsThankYouMessage(true)
        }
        if (activistInfo.author) {
          setAuthor(activistInfo.author)
          setIsAuthor(true)
        }
      } else {
        goToBasicInfo(navigation)
      }
    }
  }

  const onDrop = useCallback(async file => {
    const date = new Date()
    const imageType = file[0].type.split('/')[1]
    const fileName = file[0].name.replace(imageType, 'jpeg')
    const timeStamp = date.getTime()
    const timeStampFileName = `${timeStamp}-${fileName}`

    const ref = getRef(`images/activists/${activistId}/thankYouMessage/${timeStampFileName}`)

    const url = await getDownloadURL(ref)


    uploadStorage(ref, file).then(uploadTask => {
      getDownload(uploadTask.ref).then(async (data) => {
        setThankYouMessageImage(url)
      })
    })
  }, [])


  useEffect(() => {
    const setup = async () => {
      await onAuthStateChanged(auth, async activist => {
        if (activist) {
            await _fetchActivistInfo(activist.uid)
            const selectedActivistId = localStorage.getItem("selectedActivistId")
            const id = selectedActivistId?selectedActivistId:activist.uid
            setActivistId(id)
            setIsLoading(false)

        } else {
          goToHome(navigation)
        }
      })
    }
    setup()
  }, [])

  return (
    <View>
      <View>
        <View style={basicStyles.container}>
          <View style={basicStyles.bodyContainer}>
            <ActivistMenu />

            <View style={basicStyles.scrollContainer}>
              <ActivistHeaderMenu activistId={activistId} imageUrl={imageUrl} title={'活動報告'} />
              {label != "thank" ? <ReportContainer
                label={label}
                title={title}
                setTitle={setTitle}
                body={body}
                setBody={setBody}
                thankYouMessageImage={thankYouMessageImage}
                activistId={activistId}
                setThankYouMessageImage={setThankYouMessageImage}
                isDraft={false}
                setLabel={setLabel}
                reportId={reportId}
                setReportId={setReportId}
                isPostThankYouMessage={isPostThankYouMessage}
                setIsPostThankYouMessage={setIsPostThankYouMessage}
                isSent={false}
              /> : <ThankYouContainer
                label={label}
                thankYouMessage={thankYouMessage}
                setThankYouMessage={setThankYouMessage}
                isThankYouMessage={isThankYouMessage}
                setIsThankYouMessage={setIsThankYouMessage}
                author={author}
                setAuthor={setAuthor}
                isAuthor={isAuthor}
                setIsAuthor={setIsAuthor}
                activistId={activistId}
                isPreSending={isPreSending}
                reportId={reportId}
                isDraft={false}
                setLabel={setLabel}
                isPostThankYouMessage={isPostThankYouMessage}
                setIsPostThankYouMessage={setIsPostThankYouMessage}
                title={title}
                body={body}

              />}

            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  checkbox: {
    alignSelf: 'center',
    marginRight: 11,
  },
  container: {
    flexDirection: 'row',
    height: height,
  },
  hovered: {
    borderColor: '#999999',
    width: 180,
    height: 30,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  notHovered: {
    borderColor: '#DDDDDD',
    width: 180,
    height: 30,
    borderWidth: 2,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    width: width * 0.77,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: 40,
    marginBottom: 35,
  },
  card: {
    backgroundColor: 'white',
    width: width * 0.85,
    height: 1000,
  },
  cardBody: {
    flexDirection: 'row',
  },
  titleTextField: {
    borderWidth: 1,
    height: 40,
    marginLeft: 34,
    borderColor: '#DDDDDD',
  },
  bodyTextArea: {
    height: 160,
    borderWidth: 1,
    marginLeft: 34,
    borderColor: '#DDDDDD',
  },
  outlineTextField: {
    height: 160,
    borderWidth: 1,
    marginLeft: 34,
    borderColor: '#DDDDDD',
  },
  thankYouMessageTextArea: {
    height: 160,
    borderWidth: 1,
    marginLeft: 34,
    borderColor: '#DDDDDD',
  },
  authorTextField: {
    width: '50%',
    borderWidth: 1,
    marginLeft: 34,
    height: 40,
    borderColor: '#DDDDDD',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 6,
  },
  forSupporter: {
    fontSize: 11,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  saveThankYouMessageButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  saveAuthorContainer: {
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 40,
  },
  saveAuthorBtnContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,
    paddingLeft: 20,
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  btnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: '5%',
  },
  cardTitle: {
    marginTop: 36,
    marginLeft: 30,
    fontWeight: 'bold',
    fontSize: 14,
  },
  sectionTitle: {
    marginTop: 22,
    marginLeft: 34,
  },
  authorContainer: {
    marginLeft: 34,
  },
  imageContainer: {
    paddingTop: 20,
    marginLeft: 50,
  },
  imageText: {
    color: '#999',
    width: 250,
    fontSize: 12,
  },
})
