import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Dimensions, ImageBackground, TouchableOpacity } from 'react-native'
import { Text, Button } from 'app/src/components/basics'
import { goToBasicInfo, goToHome, goToReportCreateScreen, goToReportEditScreen, goToSignupFinish } from 'app/src/libs/screen-transition'
import { useNavigation } from 'app/src/hooks/use-navigation'
import { fetchActivistInfo } from 'app/src/libs/firebase'
import { Pressable } from 'react-native-web-hover'
import { ActivistMenu } from 'app/src/components/advanced/activist/menu'
import { ShareList } from 'app/src/components/advanced/share-list'
import { ActivistHeaderMenu } from 'app/src/components/advanced/activist/headerMenu'
import { ReportCard, styles as basicStyles } from 'app/src/components/advanced/activist/basic'
import { onAuthStateChanged } from 'firebase/auth'
import { db, auth } from 'app/src/libs/firebase'
import { collection, query, orderBy, getDocs } from 'firebase/firestore'
import { Loader } from 'app/src/components/basics/loader'
import styled from "styled-components"


const { width, height } = Dimensions.get('window')


const StyledContainer = styled.div`

flex-wrap: wrap;
width:${width - 180}px;
min-width:1220px;
zIndex: -100:
`

type ContainerProps = {}


function POP(props) {
  return (
    <div>
      {props.isPopup ? (
        <div
          style={{
            position: 'fixed',
            zIndex: 100,
            left: '25%',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
            height: '15%',
            display: 'block',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            background: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <div
            style={{
              zIndex: 100,
              padding: '1em',
              color: 'white',
            }}
          >
            <p>活動報告の入稿を受付ました。25日の21時に送信されます。</p>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export const ActivistReportScreen: React.FC<ContainerProps> = () => {
  const [reports, setReports] = useState<any>([])
  const navigation = useNavigation()
  const [isPopup, setIsPopup] = useState<boolean>(false)
  const [activistId, setActivistId] = useState<string>('')
  const [activistInfo, setActivistInfo] = useState<any>()
  const [imageUrl, setImageUrl] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const alertIsPosted = () => {
    setIsPopup(false)
    localStorage.removeItem('isPosted')
  }

  async function _fetchActivistInfo(activistId: string) {
    const activistInfo = await fetchActivistInfo(activistId)
    if (activistInfo?.isSetUp) {
      setActivistInfo(activistInfo)
    } else {
      goToBasicInfo(navigation)
    }
  }

  useEffect(() => {
    const setup = async () => {
      await onAuthStateChanged(auth, async activist => {
        if (activist) {
            const result = localStorage.getItem('isPosted')
            const selectedActivistId = localStorage.getItem("selectedActivistId")
            const id = selectedActivistId?selectedActivistId:activist.uid
            _fetchActivistInfo(id)
            setActivistId(id)

            const activistInfo = await fetchActivistInfo(id)

            setImageUrl(activistInfo?.imageUrl)

            const reportsRef = collection(db, 'activists', id, 'reports')
            const q = query(reportsRef, orderBy('createdAt', 'desc'))

            const docs = await getDocs(q).then(data => {
              return data.docs
            })

            let list: any = []
            for (let i = 0; i < docs.length; i++) {
              let data = docs[i].data()
              data['id'] = docs[i].id
              list.push(data)
            }

            if (result) {
              setIsPopup(true)
              setTimeout(alertIsPosted, 3000)
            }

            setReports(list)

        } else {
          goToHome(navigation)
        }
        setIsLoading(false)
      })
    }

    setup()
  }, [])

  const goEdit = id => {
    localStorage.setItem('reportId', id)

    goToReportEditScreen(navigation)
  }

  return (
    <View>
      <View>
        <View style={basicStyles.container}>
          <View style={basicStyles.bodyContainer}>
            <View>
              <ActivistMenu pageTitle={'report'} />
            </View>
            <View
              style={basicStyles.scrollContainer}
            >
              <ActivistHeaderMenu activistId={activistId} imageUrl={imageUrl} title={'活動報告'} />

              <POP isPopup={isPopup} />

              <View>
                <View style={styles.newReportContainer}>
                  <View style={styles.buttonPosition}>
                    <Pressable>
                      {data => {
                        if (data.hovered) {
                          return (
                            <Button buttonType={3} onPress={() => goToReportCreateScreen(navigation)}>
                              新規作成
                            </Button>
                          )
                        } else if (data.pressable) {
                          return (
                            <Button buttonType={15} onPress={() => goToReportCreateScreen(navigation)}>
                              新規作成
                            </Button>
                          )
                        } else {
                          return <Button onPress={() => goToReportCreateScreen(navigation)}>新規作成</Button>
                        }
                      }}
                    </Pressable>
                  </View>
                  <View style={styles.alertContainer}>
                    <Text style={styles.alertText} type={'alert'}>活動報告はThank you messageと共に毎月25日に発送されます。
                      20日までに入稿を完了してください。</Text>
                  </View>
                </View>
                <View>

                  <StyledContainer>
                    {isLoading ?
                      <Loader />
                      : <View>
                        {reports.length ? (
                          <View style={styles.reportContainer}>
                            {reports.map(element => {
                              const date = new Date(element.createdAt)

                              return (
                                <View style={styles.reportBigStyle}>
                                  <ReportCard>
                                    <View>
                                      <TouchableOpacity onPress={() => goEdit(element.id)}>
                                        <Text type={'xlarge'} style={styles.reportTitle}>
                                          {element.title.length < 31 ? element.title : element.title.slice(0, 30) + '...'}
                                        </Text>
                                        <Text style={styles.createdAt}>
                                          {date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()}
                                        </Text>

                                        <View>
                                          <ImageBackground
                                            source={element.thankYouMessageImage}
                                            style={basicStyles.cardThumbnail}
                                            resizeMode="cover"
                                          >
                                            {element.isDraft && <View
                                              style={{
                                                backgroundColor: '#CC0000',
                                                width: 72,
                                                marginLeft: 'auto',
                                                top: 0,
                                              }}
                                            >
                                              <Text style={styles.draftFlag}>下書き</Text>
                                            </View>}
                                          </ImageBackground>

                                          <View style={basicStyles.reportCardText}>
                                            {element.body.length < 90 ? (
                                              <Text>{element.body}</Text>
                                            ) : (
                                              <Text>
                                                {element.body.slice(0, 90)}
                                                <Text style={styles.moreBody}>...もっと読む</Text>
                                              </Text>
                                            )}
                                          </View>

                                          <View>
                                            <ShareList />
                                          </View>
                                        </View>
                                      </TouchableOpacity>
                                    </View>
                                  </ReportCard>
                                </View>
                              )
                            })}
                          </View>
                        ) : (
                          <View style={styles.nonReportContainer}>
                            <View style={styles.nonReportTextContainer}>
                              <Text>まだ活動報告はありません</Text>
                            </View>
                          </View>
                        )}
                      </View>}


                  </StyledContainer>
                </View>
              </View>
            </View>
          </View>
        </View>

      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 26,
    marginBottom: 20,
    // margin: 'auto',

    textAlign: 'center',
    zIndex: 1,

    justifyContent: 'flex-start',
  },
  nonReportContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 300,
    marginBottom: 20,
    textAlign: 'center',
    width: width - 180,

    justifyContent: 'center',
  },

  nonReportTextContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },

  donationHistoryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  donationTitleStyle: {
    marginTop: 40,
  },
  container: {
    flexDirection: 'row',
  },
  newReportContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 30,
    alignItems: 'center',

    zIndex: 0

  },
  alertText: {


    lineHeight: 18,


  },
  alertContainer: {
    width: 428,

  },
  moreBody: {
    opacity: 0.5,
  },
  reportTitle: {
    marginTop: 30,
  },
  createdAt: {
    color: '#999999',
    marginBottom: 10,
  },
  draftFlag: {
    textAlign: 'center',
    color: 'white',
    right: 0,
    marginRight: 0,
  },
  textContainer: {
    marginRight: 170,
  },
  buttonPosition: {
    marginRight: 41
  },
  reportBigStyle: {
    marginLeft: 20,
  },
  depth: {

    position: "relative",
    zIndex: -1,


  }
})
