import React, { useState, useContext } from 'react'
import { StyleSheet, View, ScrollView, Image, TouchableOpacity, TextInput } from 'react-native'

// contexts
import { LayoutContext, LayoutContextType } from 'app/src/contexts/layout'

// libs
import { images } from 'app/src/libs/images'
import { firebaseAuthError } from 'app/src/libs/firebase'
import { goToDonations, goToActivistSignup, goToForgetPassword } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'
import { useParams } from 'app/src/hooks/use-params'

// constants
import { white, darkGray } from 'app/src/constants/colors'
import { windowWidth, windowHeight } from 'app/src/constants/layout'

// components
import { Text, Button } from 'app/src/components/basics'
import { Footer } from 'app/src/components/advanced/footer'
import { auth } from 'app/src/libs/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'

type ContainerProps = {}

type Props = {
  navigation: any
  isPc: boolean
  signin: () => void
  setEmail: (string) => void
  setPassword: (string) => void
}

export const ActivistSigninScreen: React.FC<ContainerProps> = () => {
  const { isPc } = useContext<LayoutContextType>(LayoutContext)
  const navigation = useNavigation()
  const params = useParams()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const _signinValidate = () => {
    if (!email || !password) {
      alert('未入力の項目があります。')
      return false
    }
    return true
  }

  const _signin = async () => {
    if (await _signinValidate()) {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          goToDonations(navigation)
        })
        .catch(async err => {
          alert(firebaseAuthError(err))
        })
    }
  }

  return <UI navigation={navigation} isPc={isPc} setEmail={setEmail} setPassword={setPassword} signin={_signin} />
}

const UI: React.FC<Props> = ({ navigation, isPc, setEmail, setPassword, signin }) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => goToActivistSignup(navigation)}>
        <View>
          <Text style={styles.goToActivistSignup}>初回登録の方はこちら　＞</Text>
        </View>
      </TouchableOpacity>
      <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
        <View>
          <Image source={images['iconMeguBig']} style={styles.iconMeguBig} />
        </View>

        <Text type={'xlarge'} style={[styles.pageName, isPc && { color: darkGray }]}>
          支援先様専用ログインページ
        </Text>

        <View style={styles.textInputContainer}>
          <Text style={styles.sectionText}>メールアドレス</Text>

          <View style={styles.textInputView}>
            <TextInput
              style={[styles.textInput, isPc && { color: darkGray }]}
              onChange={v => setEmail(v.nativeEvent.text)}
              placeholder={'メールアドレス'}
              placeholderTextColor={isPc ? darkGray : white}
            />
          </View>
          <Text style={styles.sectionText}>パスワード</Text>
          <View style={styles.textInputView}>
            <TextInput
              style={[styles.textInput, isPc && { color: darkGray }]}
              onChange={v => setPassword(v.nativeEvent.text)}
              secureTextEntry
              placeholder={'パスワード'}
              placeholderTextColor={isPc ? darkGray : white}
            />
          </View>
          <View style={styles.signinButton}>
            <Button buttonType={4} onPress={() => signin()}>
              ログイン
            </Button>
          </View>
        </View>

        <TouchableOpacity onPress={() => goToForgetPassword(navigation)}>


          <Text style={styles.login}>
            ログインできない方はこちら

          </Text>

        </TouchableOpacity>


      </ScrollView>
      <Footer />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  login: {

    fontSize: 12,
    color: "#0470B9",
    textAlign: 'center',
  },
  termCheck: {
    textAlign: 'right',
  },
  scrollContainer: {
    flex: 1,
    marginBottom: 50,
    minHeight: "90vh"
  },
  scrollContents: {
    justifyContent: 'center',
  },
  bgSignin: {
    width: '100%',
    height: windowHeight,
    position: 'relative',
  },
  earthSigninImage: {
    width: windowWidth,
    height: windowWidth * 0.7,
  },
  pageName: {
    fontSize: 16,
    color: white,
    marginTop: 82,
    marginBottom: 40,
    textAlign: 'center',
  },
  textInputContainer: {
    marginHorizontal: 'auto',
  },
  textInputView: {
    backgroundColor: 'rgba(221, 221, 221, 0.4)',
    width: 325,
    height: 48,
    marginBottom: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    color: white,
    paddingLeft: 17,
    width: 325,
    height: 48,
    zIndex: 10,
  },
  signinButton: {
    marginTop: 50,
    marginBottom: 36,
    width: 260,
    height: 50,
    fontWeight: 'bold',
    marginHorizontal: 'auto',
  },
  link: {
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  goToActivistSignup: {
    color: '#0470B9',
    textAlign: 'right',
    marginTop: 33,
    marginRight: 40,
  },
  iconMeguBig: {
    width: 120,
    height: 160,
    resizeMode: 'contain',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  sectionText: {
    marginBottom: 6,
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})
