import React, { useEffect, useLayoutEffect, useState, useCallback } from 'react'
import { StyleSheet, View, Image, Dimensions, TouchableOpacity, ImageBackground } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { Text, Button } from 'app/src/components/basics'
import { Hoverable } from 'react-native-web-hover'
import { styles as basicStyles, ThankYouImageFunc } from 'app/src/components/advanced/activist/basic'

type Props = {
  selectedMenu: number
  text: string
  selected: (v: number) => void
}

export const NotSelectedMenu: React.FC<Props> = props => {
  return (
    <Hoverable>
      {({ hovered }) => (
        <TouchableOpacity onPress={() => props.selected(props.selectedMenu)}>
          <LinearGradient
            style={basicStyles.selectedMenu}
            colors={hovered ? ['#2CA4DE', '#96C843'] : ['transparent', 'transparent']}
            start={[0.0, 0.0]}
            end={[1.0, 0]}
          >
            <Text type={'large'} style={hovered ? basicStyles.myPageHovered : basicStyles.myPageNotHovered}>
              {props.text}
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      )}
    </Hoverable>
  )
}
