import React, { useState } from 'react'
import { StyleSheet, View, ScrollView, Image, TouchableOpacity, TextInput, Platform } from 'react-native'

// libs
import { images } from 'app/src/libs/images'
import { createActivist, firebaseAuthError, sendSignUpMail } from 'app/src/libs/firebase'
import { goToActivistSignin, goToSignupFinish, goToTerms } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { windowWidth, windowHeight } from 'app/src/constants/layout'

// components
import { Text, Button } from 'app/src/components/basics'
import { Footer } from 'app/src/components/advanced/footer'
import { auth } from 'app/src/libs/firebase'
import { createUserWithEmailAndPassword } from 'firebase/auth'

type ContainerProps = {}

type Props = {
  navigation: any
  setEmail: (string) => void
  setPassword: (string) => void
  setPasswordConfirm: (string) => void
  signup: () => void
  isChecked: boolean
  setIsChecked: (boolean) => void
}

export const ActivistSignupScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const _signup = async () => {
    if (isChecked) {
      if (await _signupValidate()) {
        createUserWithEmailAndPassword(auth, email, password)
          .then(async user => {
            const currentUser = auth.currentUser

            //遷移前がアクション詳細画面のとき

            if (user) {
              //遷移前がマイページのとき

              const result = await _createUser(currentUser)

              if(result){

                _sendEmailFromMypage()


              }

            }
          })
          .catch(async err => {
            console.log(err)
            alert(firebaseAuthError(err))
          })
      }
    } else {
      alert('利用規約を確認してからアカウント登録を行なってください')
    }
  }

  const _signupValidate = async () => {
    if (!email || !password || !passwordConfirm) {
      alert('未入力の項目があります。')
      return false
    }

    if (password !== passwordConfirm) {
      alert('パスワードが一致していません。')
      return false
    }

    return true
  }

  const _sendEmailFromMypage = () => {
    const url = window.location.protocol + '//' + window.location.host


    sendSignUpMail(email, url)
      .then((res: any) => {
        goToSignupFinish(navigation)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _createUser = async currentUser => {


    const os = Platform.OS
    const email = currentUser.email
    const activistId = currentUser.uid

    const data = await createActivist(os,email,activistId)

    return data.success



  }

  return (
    <UI
      navigation={navigation}
      setEmail={setEmail}
      setPassword={setPassword}
      setPasswordConfirm={setPasswordConfirm}
      signup={_signup}
      isChecked={isChecked}
      setIsChecked={setIsChecked}
    />
  )
}

const UI: React.FC<Props & ContainerProps> = ({
  navigation,
  setEmail,
  setPassword,
  setPasswordConfirm,
  signup,
  setIsChecked,
  isChecked,
}) => {
  return (
    <View >
      <TouchableOpacity onPress={() => goToActivistSignin(navigation)}>
        <View>
          <Text style={styles.goToActivistSignup}>既に会員の方はこちら　＞</Text>
        </View>
      </TouchableOpacity>
      <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
        <Image source={images['iconMeguBig']} style={styles.iconMeguBig} />
        <Text type={'xlarge'} style={styles.pageName}>
          支援先様専用登録ページ
        </Text>
        <View style={styles.textInputContainer}>
          <Text style={styles.sectionText}>メールアドレス</Text>

          <View style={styles.textInputView}>
            <TextInput
              style={styles.textInput}
              onChange={v => setEmail(v.nativeEvent.text)}
              placeholder={'メールアドレス'}
              placeholderTextColor={'#999999'}
            />
          </View>
          <Text style={styles.sectionText}>パスワード</Text>

          <View style={styles.textInputView}>
            <TextInput
              style={styles.textInput}
              onChange={v => setPassword(v.nativeEvent.text)}
              secureTextEntry
              placeholder={'パスワード'}
              placeholderTextColor={'#999999'}
            />
          </View>
          <Text style={styles.sectionText}>パスワード確認</Text>

          <View style={styles.textInputView}>
            <TextInput
              style={styles.textInput}
              onChange={v => setPasswordConfirm(v.nativeEvent.text)}
              secureTextEntry
              placeholder={'パスワード確認'}
              placeholderTextColor={'#999999'}
            />
          </View>
          <View style={styles.checkContainer}>
            <View style={styles.termCheck}>
              <input type="checkbox" onChange={() => setIsChecked(!isChecked)} id="scales" name="scales" />
            </View>
            <View>
              <Text>
                <TouchableOpacity style={styles.termLink} onPress={() => goToTerms(navigation)}>
                  利用規約
                </TouchableOpacity>
                に同意しました
              </Text>
            </View>
          </View>
          <View style={styles.signinButton}>
            <Button buttonType={4} onPress={() => signup()}>
              登録する
            </Button>
          </View>
        </View>
      </ScrollView>
      <Footer />
    </View>
  )
}

const styles = StyleSheet.create({
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollContainer: {
    flex: 1,
    marginBottom: 50

  },
  scrollContents: {
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  bgSignin: {
    width: '100%',
    height: windowHeight,
    position: 'relative',
  },
  earthSigninImage: {
    width: windowWidth,
    height: windowWidth * 0.7,
  },
  pageName: {
    fontSize: 16,
    marginTop: 82,
    marginBottom: 40,
    textAlign: 'center',
  },
  textInputContainer: {
    marginHorizontal: 'auto',
  },
  textInputView: {
    backgroundColor: 'rgba(221, 221, 221, 0.4)',
    width: 325,
    height: 48,
    marginBottom: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    color: 'black',
    paddingLeft: 17,
    width: 325,
    height: 48,
    zIndex: 10,
  },
  signinButton: {
    marginTop: 50,
    marginBottom: 36,
    width: 260,
    height: 50,
    marginHorizontal: 'auto',
  },
  linkBtn: {
    zIndex: 10,
  },
  link: {
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  logo: {
    height: 80,
    width: 50,
  },
  goToActivistSignup: {
    color: '#0470B9',
    textAlign: 'right',
    marginTop: 33,
    marginRight: 40,
  },

  termLink: {
    textAlign: 'right',
    color: '#0470B9',
  },
  termCheck: {
    textAlign: 'right',
  },
  iconMeguBig: {
    width: 120,
    height: 160,
    resizeMode: 'contain',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  sectionText: {
    marginBottom: 6,
  },
})
