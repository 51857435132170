import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { BasicLayout } from 'app/src/components/layouts/basic'
import { ScrollToTop } from 'app/src/components/apps/scroll-to-top'
import { TermsScreen } from 'app/src/components/pages/others/terms'
import { HowToScreen } from 'app/src/components/pages/others/how-to'
import { PrivacyScreen } from 'app/src/components/pages/others/privacy'
import { QaScreen } from 'app/src/components/pages/others/qa'
import { CompanyScreen } from 'app/src/components/pages/others/company'
import { ContactScreen } from 'app/src/components/pages/others/contact'
import { TransactionsScreen } from 'app/src/components/pages/others/transactions'
import { DepositScreen } from '../pages/activist/deposit'

import { DonatedScreen } from '../pages/activist/donations/index'

import { ActivistbasicInfoScreen } from '../pages/activist/basicInfo'
import { ActivistReportScreen } from '../pages/activist/report/'
import { ActivistSignupScreen } from '../pages/activist/signup/index'
import { ActivistSignUpFinishScreen } from '../pages/activist/signup/finish'

import { GuideLineScreen } from 'app/src/components/pages/others/guideline'

import { ActivistSigninScreen } from '../pages/activist/signin/index'
import { ActivistMypageScreen } from '../pages/activist/mypage'
import { ActivistConnectScreen } from '../pages/activist/connect'
import { ReportCreateScreen } from '../pages/activist/report/create'
import { ReportConfirmScreen } from '../pages/activist/report/confirm'
import { ReportEditScreen } from '../pages/activist/report/edit'
import { ForgetPasswordScreen } from '../pages/activist/forget-password'
import { ForgetPasswordSentScreen } from '../pages/activist/forget-password/sent'
import ResetPasswordScreen from '../pages/activist/reset-password'



export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <BasicLayout>
        <ScrollToTop />
        <Switch>
          <Route exact path="/terms" component={TermsScreen} />
          <Route exact path="/guideline" component={GuideLineScreen} />

          <Route exact path="/how-to" component={HowToScreen} />
          <Route exact path="/privacy" component={PrivacyScreen} />
          <Route exact path="/qa" component={QaScreen} />
          <Route exact path="/company" component={CompanyScreen} />
          <Route exact path="/contact" component={ContactScreen} />
          <Route exact path="/transactions" component={TransactionsScreen} />

          <Route exact path="/mypage" component={ActivistMypageScreen} />
          <Route exact path="/report/create" component={ReportCreateScreen} />
          <Route exact path="/report/confirm" component={ReportConfirmScreen} />
          <Route exact path="/report" component={ActivistReportScreen} />
          <Route exact path="/report/edit" component={ReportEditScreen} />
          <Route exact path="/connect" component={ActivistConnectScreen} />
          <Route exact path="/deposit" component={DepositScreen} />
          <Route exact path="/signup" component={ActivistSignupScreen} />
          <Route exact path="/signup/finish" component={ActivistSignUpFinishScreen} />

          <Route exact path="/" component={ActivistSigninScreen} />
          <Route exact path="/donations" component={DonatedScreen} />
          <Route exact path="/basicinfo" component={ActivistbasicInfoScreen} />
          <Route exact path="/forget-password" component={ForgetPasswordScreen} />
          <Route exact path="/forget-password/sent" component={ForgetPasswordSentScreen} />
          <Route exact path="/reset-password" component={ResetPasswordScreen} />



        </Switch>
      </BasicLayout>
    </BrowserRouter>
  )
}
