import React, { useCallback } from 'react'
import { StyleSheet, View, Image, Dimensions } from 'react-native'
import { useDropzone } from 'react-dropzone'
import { images } from 'app/src/libs/images'
import { Hoverable, Pressable } from 'react-native-web-hover'
import { styles as basicStyles, ThankYouImageFunc } from 'app/src/components/advanced/activist/basic'
import { getRef, uploadStorage, getDownload } from 'app/src/libs/firebase'
import { Text } from 'app/src/components/basics'
import Dropzone from 'react-dropzone'
import { db } from 'app/src/libs/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { getDownloadURL } from 'firebase/storage'
import imageCompression from 'browser-image-compression'


interface Props {
  activistId: string
  thumbnail: string

  setThumbnail: (v: string) => void
}

export const ActivistHeaderImage: React.FC<Props> = props => {
  const uploadFile = useCallback(async files => {
    const file = files[0]
    const compressFile = await getCompressImageFile(file)
    const objectURL = URL.createObjectURL(compressFile);
    props.setThumbnail(objectURL)
    const date = new Date()
    const timeStamp = date.getTime()
    const timeStampFileName = `${timeStamp}-${'thumbnail'}`
    const storageRef = getRef(`images/activists/${props.activistId}/${timeStampFileName}`)

    const ref = doc(db, 'activists', props.activistId)

    const uploadTask = await uploadStorage(storageRef, compressFile)
    const url = await getDownload(uploadTask.ref)

    await updateDoc(ref, {
      thumbnail: url,
    })

  }, [])

  const getCompressImageFile = async (file) => {
    const options = {
      maxSizeMB: 0.8, //最大ファイルサイズ
      maxWidthOrHeight: 600, //最大縦横値
    }
    return await imageCompression(file, options)
  }

  const onDrop = useCallback(
    async files => {
      const date = new Date()
      const timeStamp = date.getTime()
      const timeStampFileName = `${timeStamp}-${props.activistId}.jpeg`


      const storageRef = getRef(`activists/${props.activistId}/${timeStampFileName}`)

      const url = await getDownloadURL(storageRef)


      const file = files[0]

      await uploadStorage(storageRef, file)

      props.setThumbnail(url)
    },
    [props.activistId],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg,image.jpg, image/png' })

  return (
    <View>
      <View style={basicStyles.sectionContainer}>
        <Text style={basicStyles.sectionTitle}>ヘッダー画像</Text>

      </View>

      <Hoverable>
        {({ hovered }) => (
          <View style={hovered ? basicStyles.imageOpacity : {}}>
            {props.thumbnail ? (
              <Dropzone accept={['image/jpeg', 'image.jpg', 'image/png']} onDrop={acceptedFiles => uploadFile(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      width: 370,
                      height: 260,
                      marginRight: 20,
                    }}
                  >
                    <Image style={basicStyles.coverImage} height={50} width={50} source={{ uri: props.thumbnail }} />
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
            ) : (
              <Dropzone accept={['image/jpeg', 'image.jpg', 'image/png']} onDrop={acceptedFiles => uploadFile(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      width: 370,
                      height: 260,
                      backgroundColor: '#ddd',
                      textAlign: 'center',
                      marginRight: 20,
                      lineHeight: "14px",
                      display: "flex",
                      alignItems: "center",

                    }}
                  >
                    <View style={styles.emptyCotanier}>

                      <Image source={images['iconCamera']} style={styles.logo} />

                      <Text style={styles.uploadText}>
                        アップロードしたい画像をここにドラッグ＆ドロップするか、クリックして画像を選択してください
                      </Text>
                    </View>



                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
            )}
          </View>
        )}
      </Hoverable>
      <View style={basicStyles.annotationContainer}>
        <View style={styles.description}>


          <View >
            <Text type="annotation">※ヘッダー用の画像をアップロードしてください </Text>
          </View>
          <View>
            <Text type="annotation">（推奨：JPEG、PNG、370px x 260px 以上）</Text>
          </View>
        </View>

      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  emptyCotanier: {
    margin: "auto",

  },
  description: {
    width: 370,
    marginBottom: 20
  },
  logo: {
    width: 50,
    height: 50,
    margin: 'auto',
    marginBottom: 15,
  },

  uploadText: {
    color: '#666666',
    textAlign: 'center',
    width: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
    lineHeight: 14

  },
})
