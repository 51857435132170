import React, { useCallback } from 'react'
import { StyleSheet, View, Image, Dimensions } from 'react-native'
import { useDropzone } from 'react-dropzone'
import { images } from 'app/src/libs/images'
import { Hoverable, Pressable } from 'react-native-web-hover'
import { styles as basicStyles, ThankYouImageFunc } from 'app/src/components/advanced/activist/basic'
import { getRef, uploadStorage, getDownload, uploadCloudiary } from 'app/src/libs/firebase'
import { Text } from 'app/src/components/basics'
import { db } from 'app/src/libs/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import imageCompression from 'browser-image-compression'
import { getDownloadURL } from 'firebase/storage'


interface Props {
  imageUrl: string
  activistId: string
  setImageUrl: (logo: string) => void
}

const { width, height } = Dimensions.get('window')


const getCompressImageFile = async (file) => {
  const options = {
    maxSizeMB: 0.8, //最大ファイルサイズ
    maxWidthOrHeight: 600, //最大縦横値
  }
  return await imageCompression(file, options)
}

export const ActivistIcon: React.FC<Props> = props => {
  const onDrop = useCallback(
    async files => {
      const file = files[0]

      const compressFile = await getCompressImageFile(file)
      const objectURL = URL.createObjectURL(compressFile);



      const date = new Date()
      const timeStamp = date.getTime()
      const timeStampFileName = `${timeStamp}-${'icon'}`

      const storageRef = getRef(`images/activists/${props.activistId}/${timeStampFileName}`)

      await uploadStorage(storageRef, compressFile)

      const ref = doc(db, 'activists', props.activistId)

      const url = await getDownloadURL(storageRef)

      props.setImageUrl(url)



      await updateDoc(ref, {
        imageUrl: url,
      })

    },
    [props.activistId],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg,image.jpg, image/png' })

  return (
    <View>
      <View style={basicStyles.sectionContainer}>
        <Text style={basicStyles.sectionTitle}>ロゴ設定</Text>

      </View>

      <Hoverable>
        {({ hovered }) => (
          <View style={hovered ? basicStyles.imageOpacity : {}}>
            {props.imageUrl ? (
              <div
                {...getRootProps()}
                style={{
                  width: 250,
                  height: 250,
                }}
              >
                <Image height={50} width={50} source={{ uri: props.imageUrl }} style={styles.logoContainer} />
                <input {...getInputProps()} />
              </div>
            ) : (
              <div
                {...getRootProps()}
                style={{
                  width: 250,
                  height: 250,
                  backgroundColor: '#DDDDDD',
                  borderRadius: '50%',
                  textAlign: 'center',
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p></p>
                ) : (
                  <div>
                    <Image source={images['iconCamera']} style={styles.logo} />
                    <div
                      style={{
                        color: '#666666',
                        fontSize: 12,
                        width: '90%',
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        lineHeight: "14px"


                      }}
                    >
                      アップロードしたい画像をここにドラッグ＆ドロップするか、クリックして画像を選択してください
                    </div>
                  </div>
                )}
              </div>
            )}
          </View>
        )}
      </Hoverable>
      <View style={basicStyles.annotationContainer}>
        <Text type="annotation">※ロゴをアップロードしてください</Text>

        <Text type="annotation">（推奨：JPEG、PNG、250px x 250px 以上）</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  logo: {
    width: 50,
    height: 50,
    margin: 'auto',
    marginTop: 120,
    marginBottom: 15,
  },
  logoContainer2: {
    width: 250,
    height: 250,
    backgroundColor: '#DDDDDD',
  },
  logoContainer: {
    width: 250,
    height: 250,
  },
  imageText: {
    color: '#666666',
  },
  announceLogo: {
    width: 280,
    marginTop: 10,
    textAlign: 'center',
    color: '#999999',
  },
})


